import './child_profile_page.css';
import { useNavigate, useParams } from "react-router-dom";
// import Header1 from "../Header1";
import { imageUrl } from "../../services/api";
import { URLS } from "../../constants";
import Cardcomponent from "./Cardcomponent";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { decodeToken } from "../../utilities/CustomFunctions";
import { makePostRequest, makeGetRequest } from "../../services/api";
import { Multiselect } from "multiselect-react-dropdown";
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import { CategoryOptions } from '../SavedResources.WebAppMate/SaveResourceList';
import { statusData, viewResult } from './Child_profile_page';
// /*
//      * Childprofileupdateview1 function is used to request to shared child
//      * to share the resources
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 10-5-2023
//      * /
function Childprofileupdateview1() {
  const navigate = useNavigate();
  const parmas = useParams();
  const [resourcelength, setresourcelength] = useState();
  const [id, setId] = useState("");
  let userData = decodeToken();
  let useractive = localStorage.getItem("useractive")
  let uimage = imageUrl;
  const [spin, setSpin] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [childImage, setChildImage] = useState("")
  const [resourceData, setresourcesData] = useState([]);
  const [limit, setlimit] = useState(9)
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [category, setcategory] = useState("");
  const [status, setstatus] = useState("");
  const [keyword, setkeyword] = useState("");
  const [gradeRange, setGradeRange] = useState([]);
  const [pageshow, setpageshow] = useState(true);
  const [pageNum, setpageNum] = useState(1);
  const [remaining, setRemaining] = useState(true);
  const [permissionClose, setpermissionClose] = useState(true);

  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in")
    }
    // get all grade listr
    makeGetRequest(URLS.usergetallgradelevel, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.error = () => { };
    document.body.classList.add('childProfileUpdateView')
    return () => {
      document.body.classList.remove('childProfileUpdateView')
    }
    // eslint-disable-next-line
  }, [])

  // get child profile 
  const getchildprofile = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let data = {
      childId: id,
    };
    makePostRequest(URLS.getonechild, null, data, null)
      .then((response) => {
        setFirstName(response.data.firstName)
        setLastName(response.data.lastName)
        setChildImage(uimage + response.data.photo)
      })
  }
  const handleGrade = (selectedList) => {
    const arr = selectedList?.map((val) => val.id) || [];
    setGradeRange(arr);
  };
  // get child save resource
  const getresources = async () => {
    try {
      setSpin(true);
      const data = {
        user_id: userData.userAuth.id,
        child_id: id,
        storing_type: category,
        grade: gradeRange,
        status: status,
        searchKey: keyword,
        page_number: pageNum,
        item_per_page: parseInt(limit)
      };
      const response = await makePostRequest(URLS.getchildresource, null, data);
      if (response.data.view_profile === false) {
        setpageshow(false);
      } else {
        getchildprofile();
        setpageshow(true);
      }
      const saveSet = new Set(response?.data?.save?.map((val) => val.resource_id));
      const updatedResourceData = response?.data?.resourceData.map((resource) => ({
        ...resource,
        save: saveSet.has(resource.resource_id) ? "yes" : "no"
      })) || [];
      setresourcesData(pageNum === 1 ? updatedResourceData : [...resourceData, ...updatedResourceData]);
      setresourcelength(response?.data?.resourceData?.length || 0);
      setRemaining(response?.data?.childResourcePercentage?.remaining_count !== 0);
      setSpin(false);
    } catch (error) {
      console.error("Error fetching resources:", error);
      setSpin(false);
    }
  };

  const searchData = () => {
    if (pageNum === 1) {
      getresources()
    } else {
      setpageNum(1)
    }
  }
  // useEffects calls
  useEffect(() => {
    setId(parmas.id);
  }, [parmas]);

  useEffect(() => {
    useractive === "" && navigate("/sign-in");
    id && getresources();
    // eslint-disable-next-line
  }, [id, pageNum]);

  useEffect(() => {
    id && getresources()
    // eslint-disable-next-line
  }, [limit])

  return (<>
    {spin &&
      <div className="sharepopupOuter backgroundOpacity1">
        <FourDotsLoader />
      </div>
    }
    {pageshow ?
      <div className="col-lg-10">
        <div className="image-div-center">
          <img src={childImage} alt="childImage" />
          <h3 className='text-capitalize'>{firstName}{' '}{lastName}</h3>
        </div>
        <div className="searchres-div11">
          <div className="search-exp-sec-div1">
            <h3>Refine search results</h3>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={CategoryOptions}
                  onSelect={(e) => {
                    e?.forEach((val, i) => {
                      setcategory(val.value);
                    });
                  }}
                  displayValue="name"
                  placeholder="Category"
                  showArrow
                  showCheckbox
                  selectedValues={category ? [CategoryOptions.find(item => item.value === category)] : []}
                /></div>
            </div>
            <div className={`childprofilegradeRange ${(gradeRange.length > 2) ? "showgrademultiple" : "showgradedata"}`}>
              <div className="form-select">
                <Multiselect
                  options={gradeLevelData}
                  onSelect={handleGrade}
                  onRemove={handleGrade}
                  showCheckbox
                  displayValue="name"
                  placeholder="Grade Range"
                  showArrow={true}
                  selectedValues={gradeRange && gradeLevelData.filter((grade) => gradeRange.includes(grade.id))}
                />
              </div> </div>
            <div className="saveResourceListgradeRange">
              <div className="form-select">
                <Multiselect
                  options={statusData}
                  onSelect={(e) => {
                    e?.map((val, i) => setstatus(val.value));
                  }}
                  onRemove={() => setstatus("")}
                  displayValue="name"
                  placeholder="Status"
                  showArrow={true}
                  showCheckbox
                  selectedValues={status ? [statusData.find(item => item.value === status)] : []}
                /></div></div>
            <div className="input-btn-con">
              <input type="text" placeholder="Keyword Search" onChange={(e) => setkeyword(e.target.value)} />
            </div>
            <button onClick={searchData}><i className="fa fa-search iconsearch" /></button>
          </div>
        </div>
        <div className="saveResourceListgradeRange">
          <div className="form-select">
            <Multiselect
              options={viewResult}
              onSelect={(e) => {
                e?.map((val, i) => setlimit(val.value));
              }}
              displayValue="name"
              showArrow={true}
              showCheckbox
              selectedValues={limit ? [viewResult.find(item => parseInt(item.value) === parseInt(limit))] : []}
            /></div></div>
        <div className="card-container-handle profileCardContainer">
          <div className="row">
            {resourceData.length > 0 ? resourceData.map((temp) =>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <Cardcomponent
                  res_saved_id={temp.id}
                  resource_id={temp.tbl_user_resource.id}
                  res_title={temp.tbl_user_resource.resource_title}
                  res_desc={temp.tbl_user_resource.description}
                  res_fund={temp.tbl_user_resource.public_funding}
                  res_type={temp.tbl_user_resource.resource_type}
                  res_piller={temp.tbl_user_resource.tbl_pillar_resource_mappings}
                  res_rating={temp.tbl_user_resource.rating}
                  res_review={temp.tbl_user_resource.total_reviews}
                  res_viewable={temp.viewable_by_my_friends}
                  res_len={resourcelength}
                  childId={id} getresources={getresources}
                  removeflag="false"
                  shared_by={temp.shared_by_user}
                  save={temp.save}
                  resource_thumbnail_image={temp.tbl_user_resource.resource_thumbnail_image}
                  imageLink={temp.tbl_user_resource.imageLink}
                />
              </div>
            )
              : <div className='text-center'>No Data Found</div>}
          </div>
        </div>
        <div className="show-more my-3" onclick={window.scrollTo({ bottom: 0, behavior: 'smooth' })}>
          {remaining && <p className="text-center" onClick={() => setpageNum(pageNum + 1)}>Show more</p>}
        </div>
      </div>
      : <div className="col-lg-10 text-center">
        {permissionClose && <div className="sharepopupOuter backgroundOpacity">
          <div className="pop-up-container">
            <button className="close-btn" onClick={() => setpermissionClose(false)}>
              X
            </button>
            <h3> Request Permission </h3>
            <div className="input-popup1 my-4">
              <h4 className="pop-h1">You do not have permission to view this student's portfolio.
                Would you like to request permission? </h4>
            </div>
            <div className="save-cancel-btn request-Permission-btn">
              <div className="savee">
                <button>Yes</button>
              </div>
              <div className="cancel-butn">
                <button >No</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>}
  </>)
}
export default Childprofileupdateview1;