
import Strengthicon from "../../assets/Images/Strengthicon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Hearticon from "../../assets/Images/Hearticon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
// handle learning piller
export const Learningpillar = (pillar_id, i) => {
    switch (pillar_id) {
        case "Mind":
            return (
                <span key={i} style={{ color: "#F19357" }}>
                    <img className="img-fluid" src={Mindicon} alt="mind" loading="lazy" /> Mind
                </span>
            );
        case "Heart":
            return (
                <span key={i} style={{ color: "#265472" }}>
                    <img className="img-fluid" src={Hearticon} alt="heart" loading="lazy" /> Heart
                </span>
            );
        case "Strength":
            return (
                <span key={i} style={{ color: "#AAC0CE" }}>
                    <img className="img-fluid" src={Strengthicon} alt="strength" loading="lazy" /> Strength
                </span>
            );
        case "Soul":
            return (
                <span key={i} style={{ color: "#F0B285" }}>
                    <img className="img-fluid" src={Sounicon} alt="soul" loading="lazy" /> Soul
                </span>
            );
        default:
            return null;
    }
};