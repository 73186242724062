import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getFriendList } from "../../store/reducers/userReducer";
import { style } from "../../utilities/CustomFunctions"
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

/**
 * create components is used to delete child profile
 * @author krishna dobariya <mailto:krishna.webappmate@gmail.com>
 */

const Removepopup = (props) => {
  let user_id = decodeToken().userAuth.id;
  const [spin, setSpin] = useState(false)
  let dispatch = useDispatch();

  // this function is used for delete child 
  const handleYes = (id, status) => {
    setSpin(true)
    const payload = {
      id: id,
      status: status,
      last_updated: moment().format(),
    };
    makePostRequestForAll(URLS.acceptOrRejectRequestFriend, payload)
      .then((res) => {
        setSpin(false)
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
          makePostRequestForAll(URLS.newFriendRequest, { user_id: user_id })
            .then((res) => {
              dispatch(getFriendList(res.data))
            })
          props.handlefun()
        }
        props.handleClose();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this friend ?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={() => handleYes(props.id, "unfriend")}>
              Yes
            </button>{" "}
            <button className="child-cancel" onClick={props.handleClose}>
              No
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Removepopup;
