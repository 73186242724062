import React from 'react';
import "../Search.WebAppMate/reportpopup.css";

function LocationPopUp(props) {
    return (
        <>
            <div className="sharepopupOuter backgroundOpacity">
                <div className="pop-up-container">
                    <div className="paragraph">
                        <p>We apologize, but it seems that you have blocked your location settings.In order to set a new location, please unblock or enable location access for our app/website.</p>
                    </div>
                    <div className="save-cancel-btn mt-3">
                        <div className="savee">
                            <button onClick={() => props.handleResourceSaveClose()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LocationPopUp;