import React from "react";
import "../Search.WebAppMate/reportpopup.css";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";

/**
 * create components is used for friend list
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function Permissionpopup(props) {
  //  this function is use for chnage status of premission
  const statusOfPermision = () => {
    const payload = {
      shared_child_id: props?.userId,
      child_parent_id: props?.perantId,
      status: "requested",
    };
    makePostRequest(URLS.requestToShareChildProfile, null, payload, null)
      .then((res) => {
        console.log("res...",res)
        toastifyMessage(res?.message, "success");
        props?.data()
      })
      .catch((error) => {
        toastifyMessage("something went wrong", "error");
      });
  };

  return (
    <>
      <div className="sharepopupOuter backgroundOpacity">
        <div className="pop-up-container">
          <button className="close-btn" onClick={props.data}>
            X
          </button>
          <h3> Request Permission </h3>
          <div className="input-popup1 my-4">
            <h4 className="pop-h1">Are you sure you would like to request permission to view this student's portfolio?</h4>
          </div>
          <div className="save-cancel-btn">
            <div className="savee">
              <button onClick={statusOfPermision}>Yes</button>
            </div>&nbsp;&nbsp;&nbsp;
            <div className="cancel-butn">
              <button onClick={props.data}>No</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Permissionpopup;
