import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ ...props }) => {
  return (
    <Helmet>
      <title>{props.title ? `${props.title} | ` : null} MatchED</title>
      <meta name={props.name || ""} content={props.content || ""} />
      <meta name="education and homeschool product" content="education and homeschool product" />
    </Helmet>
  );
};
export default Head;
