// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"); */

.stars-gray {
  position: relative;
  display: inline-block;
}

.stars-yellow {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;

}

.stars-gray::before {
  content: "\\f005 \\f005 \\f005 \\f005 \\f005";
  font-family: 'FontAwesome';
  font-weight:400;
  color: #ccc;
}

.stars-yellow::before {
  content: "\\f005 \\f005 \\f005 \\f005 \\f005";
  font-family: 'FontAwesome';
  font-weight:400;
  color: #F19357;

}`, "",{"version":3,"sources":["webpack://./src/Components/Search.WebAppMate/rating.css"],"names":[],"mappings":"AAAA,+FAA+F;;AAE/F;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,mBAAmB;EACnB,gBAAgB;EAChB,QAAQ;;AAEV;;AAEA;EACE,wCAAwC;EACxC,0BAA0B;EAC1B,eAAe;EACf,WAAW;AACb;;AAEA;EACE,wCAAwC;EACxC,0BAA0B;EAC1B,eAAe;EACf,cAAc;;AAEhB","sourcesContent":["/* @import url(\"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css\"); */\n\n.stars-gray {\n  position: relative;\n  display: inline-block;\n}\n\n.stars-yellow {\n  position: absolute;\n  top: 0;\n  left: 0;\n  white-space: nowrap;\n  overflow: hidden;\n  width: 0;\n\n}\n\n.stars-gray::before {\n  content: \"\\f005 \\f005 \\f005 \\f005 \\f005\";\n  font-family: 'FontAwesome';\n  font-weight:400;\n  color: #ccc;\n}\n\n.stars-yellow::before {\n  content: \"\\f005 \\f005 \\f005 \\f005 \\f005\";\n  font-family: 'FontAwesome';\n  font-weight:400;\n  color: #F19357;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
