import React, { useEffect, useState } from "react";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import GroupSetting from "./GroupSetting";
/**
 * AddMember component is used to add users to a chat group.
 * It provides functionality to select users and add them to the group.
 * It also allows viewing and removing members from the group.
 *
 * @author Krishna Dabariya <krishna.webappmate@gmail.com>
 */

const AddMember = (props) => {

  const payLoad = decodeToken().userAuth.id;
  const [filteredUser, setFilteredUser] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [spin, setSpin] = useState(true)
  const [openSetting, setopenSetting] = useState(false);
  const [datagrp, setDatagrp] = useState("")
  const [groupName, setGroupName] = useState("")
  const [user, setUser] = useState([]);

  // Effect to update group data and name
  useEffect(() => {
    setDatagrp(props?.grpData)
    setGroupName(props?.groupName)
  }, [props]);

  // Function to add selected users to the group
  const createMultiple = () => {
    if (selectedData.length <= 0) {
      toastifyMessage("Please select at least one member.", "error")
    } else {
      // If the group ID is present, update group members
      if (props.id) {
        const data = {
          "id": props.id,
          "userList": selectedData,
          "group_name": props.groupName ? props?.groupName : props?.detail?.name,
          "name_status": props?.detail?.name_Status ? props?.detail?.name_Status : "no"
        }
        makePostRequestForAll(URLS.updateMultiplechatUser, data)
          .then((res) => {
            if (res.code === 200) {
              toastifyMessage(res.message, "success");
              setSelectedData([])
              props?.onClose();
              if (props?.pageStatus === "singleChat") {
                props?.callback(res?.data.id, { name: res?.data?.group_name, image: res?.data?.image, admin: res?.data?.is_admin, pagestatus: "Addpage" }, 1)
              }
              props?.funCall()
            } else if (res.code === 400) {
              toastifyMessage(res.message, "warn");
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
      // If group ID is not present, add users to a new group
      else {
        const data = {
          "userList": selectedData,
          "group_name": "",
        }
        makePostRequestForAll(URLS.addMultiplechatUser, data)
          .then((res) => {
            if (res.code === 201) {
              toastifyMessage(res.message, "success");
              props?.onClose()
              if (props?.user) {
                props?.callback(res?.data.id, {
                  name: res?.data?.group_name, image: res?.data?.image, admin: true,
                  callback: props?.st === "friend" || props.st === "memberlist" ? "callback" : ""
                }, 1)
              } else {
                props?.funCall()
              }
            } else if (res.code === 400) {
              toastifyMessage(res.message, "warn");
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  };

  // Function to fetch user list
  const UserList = () => {
    if (props.id) {
      !searchItem && setSpin(true)
      makePostRequestForAll(URLS.getChatGroupMember, {
        "chat_multiple_id": props.id,
        "page_number": page
      })
        .then((response) => {
          makePostRequestForAll(URLS.getChatMemberListToAddApi, {
            user_id: payLoad,
            item_per_page: 10,
            page_number: page,
            search: searchItem,
          }).then((res) => {
            const updatedArr1 = [...response?.data?.memberList, ...user].map(item => ({ ...item, check: true }));
            const uniqueIds = new Set(updatedArr1.map(item => item.id));
            if (page === 1) {
              const filteredArr2 = res?.data?.filter(item => !uniqueIds.has(item.id));
              setSelectedItems(updatedArr1)
              setFilteredUser(filteredArr2);
              setSpin(false)
            }
            else {
              const filteredArr2 = res?.data?.filter(item => !uniqueIds.has(item.id));
              setSelectedItems(updatedArr1)
              setFilteredUser([...filteredUser, ...filteredArr2]);
              setSpin(false)
            }
            const idsArray = [...uniqueIds];
            setSelectedData(idsArray)
          });
        })
        .catch((error) => {
          toastifyMessage("something went wrong.", "error")
        });
    } else {
      !searchItem && setSpin(true)
      makePostRequestForAll(URLS.getChatMemberListToAddApi, {
        user_id: payLoad,
        item_per_page: 10,
        page_number: page,
        search: searchItem,
      }).then((res) => {
        console.log("res", res)
        if (page === 1) {
          setFilteredUser(res?.data);
          setSpin(false)
        }
        else {
          setFilteredUser([...filteredUser, ...res?.data]);
          setSpin(false)
        }
      });
    }
  }

  // Fetch user list on component mount or when page/search changes
  useEffect(() => {
    UserList();
    // eslint-disable-next-line
  }, [page, searchItem]);

  // Function to delete a group member
  const deleteMeber = (id) => {
    const data = {
      "chat_multiple_id": props?.id,
      "user_id": id
    };
    makePostRequestForAll(URLS.DeleteUserGroup, data)
      .then((res) => {
        if (res.code === 200) {
          console.log("res", res)
          if (props.pageStatus) {
            UserList();
          }
        } else {
          toastifyMessage(res.message, "warn");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Function to handle user selection
  const handleInput = (id) => {
    setSelectedData((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    })
    const selectedItem = selectedItems.find((user) => user.id === id);
    if (selectedItem) {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.id !== id)
      );
      setFilteredUser((prevFilteredUser) => [selectedItem, ...prevFilteredUser]);
      setUser((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.id !== id)
      );
    } else {
      const itemToSelect = filteredUser.find((user) => user.id === id);
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemToSelect]);
      setUser((prevSelectedItems) => [...prevSelectedItems, itemToSelect]);
      setFilteredUser((prevFilteredUser) =>
        prevFilteredUser.filter((item) => item.id !== id)
      );

    }
  };

  return (
    <div>
      <div className="viewMemberchatpopup sharepopupOuter">
        <div className="chatboxview chatboxviewNew">
          {spin ? <FourDotsLoader /> :
            <>
              {(props.id && props?.pageStatus) || props?.pageStatus ? "" : <><i class="fa fa-arrow-left" aria-hidden="true" onClick={() => { setopenSetting(true); }}></i></>}
              <button className="close-btn" onClick={props.onClose}>
                <i className="fa fa-close closecs"></i>
              </button>
              {(filteredUser.length > 0 || selectedItems.length > 0) ?
                <>
                  {props.id && props?.pageStatus ? <h2 className="add-title">View Members</h2> :
                    <div>
                      <h2 className="add-title goback">Select the friends you would like to add to this group.</h2></div>}
                  <div className="form-group my-3">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="search"
                      onChange={(e) => { setSearchItem(e.target.value); setPage(1) }}
                      value={searchItem}
                    />
                  </div>
                </> : ""}
              <div id="chatsearchListmember" className="search-list list-searches"  >
                {selectedItems.map((val, index) => (
                  <div className="user-search py-1 border-bottom cursor-pointer" key={index} >
                    <div className="boublechedcksign">
                      <i className="fa fa-check" aria-hidden="true"></i>
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div  >
                      <h2 onClick={() => handleInput(val?.id)}>{val.firstName} {val.lastName}</h2>
                      {props.id && props?.pageStatus && val?.check === true
                        && val?.is_admin === true ? "" : <>{val?.is_admin === false ?

                          <button className="delete-massages" style={{ marginLeft: "300px" }}
                            onClick={() => { deleteMeber(val?.id); }}>
                            Remove
                          </button> :
                          <button className="delete-massages" style={{ marginLeft: "300px" }}
                            onClick={() => { handleInput(val?.id) }}
                          >
                            Remove
                          </button>}
                      </>
                      }
                    </div>
                    <div className="py-2">
                    </div>
                  </div>
                ))}
                {filteredUser.length > 0 && (
                  filteredUser?.map((val, index) => {
                    return (
                      <>
                        <div className="user-search py-1 border-bottom cursor-pointer"
                          key={index}>
                          <div >
                            <h2>{val?.firstName}</h2>
                            <p>{val?.email}</p>
                            <button className="delete-massages" style={{ marginLeft: "300px" }}
                              onClick={() => {
                                handleInput(val?.id)
                              }}
                            >
                              Add
                            </button>
                          </div>
                          <div className="py-2">
                          </div>
                        </div>
                      </>
                    );
                  })
                )
                }
                {(filteredUser.length <= 0 && selectedItems <= 0) && < div className="search-list-popup"><h1>You currently have not added any friends. Please go to "My Friends" to add friends to your profile.</h1></div>}
              </div>
              {(filteredUser.length > 0 || selectedItems.length > 0) ?
                <div className="grouplist text-center mt-3">
                  <button className="add-group"
                    onClick={() => { createMultiple(); }}>Confirm</button>
                </div> : ""}
            </>}
        </div>
      </div>
      {
        openSetting && <GroupSetting open={openSetting} groupName={groupName}
          onClose={() => {
            setopenSetting(false);
          }}
          userMapId={props?.id} detail={datagrp}
          callback={() => { }}
        />
      }
    </div>
  );
};
export default AddMember;
