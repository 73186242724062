import { useState, useEffect } from "react";
import "../ShareInspiration.WebAppMate/sharepostpopup.css";
import vectorr from "../../assets/Images/vectorr.png";
import image2 from "../../assets/Images/image2.png";
import image3 from "../../assets/Images/image3.png";
import image4 from "../../assets/Images/image4.png";
import parent from "../../assets/Images/parent.png";
import vic1 from "../../assets/Images/vic1.png";
import stargroup from "../../assets/Images/stargroup.png";
import { makeGetRequest } from "../../services/api";
import { URLS } from "../../constants";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
/**
 * Save Resources List page to show all my saved resources list
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 30-10-2023
 */
function ResourcePopup(props) {
    let navigate = useNavigate();
    const [resourceData, setResourceData] = useState([]);
    const [shareResource, setShareResource] = useState("group");
    const [shareResourceSelectedData, setShareResourceSelectedData] =
        useState({ value: "group", label: "As Group" });
    const [selectedResource, setSelectedResource] = useState({
        name: "",
        id: "",
    });
    const [active, setActive] = useState(false);
    const shareResourceOptions = [
        { value: "individual", label: "As Individual" },
        { value: "group", label: "As Group" },
    ];
    const shareResourceMember = [
        { value: "individual", label: "As Individual" },
    ]

    useEffect(() => {
        props?.type === "Admin" ?
            setShareResourceSelectedData({ value: "group", label: "As Group" }) :
            setShareResourceSelectedData({ value: "individual", label: "As Individual" })

    }, [props?.type])
    //api call to get resources type
    const getAllResources = () => {
        makeGetRequest(URLS.getAllResources, null, null, null)
            .then((res) => {
                if (res.code === 200) {
                    // console.log(res, "response");
                    setResourceData(res.data);
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        getAllResources();
    }, []);
    //pass selected resource type nd share resource in redux action
    const handleSelectedResource = (name, id) => {
        setSelectedResource({ ...selectedResource, name: name, id: id });
    };
    //redirect to save page
    const redirectToSaveResource = () => {
        navigate("/serve", {
            state: {
                id: selectedResource.id,
                name: selectedResource.name,
                shareResource: shareResourceSelectedData,
                group_id: shareResource === "group" && props?.id,
                label: props.label,
                type: props.type,
                value: props.value,
            }
        });
    };
    useEffect(() => {
        if (selectedResource.id && selectedResource.name) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [selectedResource]);
    const handleShareResource = (shareResourceVal) => {
        setShareResourceSelectedData(shareResourceVal);
        setShareResource(shareResourceVal.value);
    };

    return (
        <>
            <div className="sharepopupOuter">
                <div className="post-popup-container">
                    <button onClick={props.data} className="close-btn"> X</button>
                    <h1>Create a Resource</h1>
                    <div className="post-scroll-div square scrollbar-dusty-grass square thin">
                        <div className="post-inner-div">
                            <div className="imgpopupshara">
                                <img className="img-fluid" src={props.userImg} alt="user" loading="lazy" />
                            </div>
                            <div className="titlewithselectbox">
                                <h2>{props.fullName}</h2>
                            </div>
                            <div className="publicprivatepost">
                                <Select
                                    options={props?.type === "Admin" ? shareResourceOptions : shareResourceMember}
                                    selectMultiple={false}
                                    onChange={handleShareResource}
                                    value={shareResourceSelectedData}
                                />
                            </div>
                            <h3>What do you want share with the community?</h3>
                        </div>
                        <div className="service-container">
                            <div className="service-inner-container">
                                {resourceData.length > 0 &&
                                    resourceData.map((val, i) => {
                                        return (
                                            <>
                                                {val.id === 2 && (
                                                    <div
                                                        className={`post-image post ${active === true && selectedResource.id === 2
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={vectorr} alt="Curriculum Content" loading="lazy" />
                                                        <div className="tooltip">Curriculum Content</div>
                                                    </div>
                                                )}
                                                {val.id === 7 && (
                                                    <div
                                                        className={`post-image1 post ${active === true && selectedResource.id === 7
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={image2} alt="Facilities" loading="lazy" />
                                                        <div className="tooltip">{val.name}</div>
                                                    </div>
                                                )}
                                                {val.id === 6 && (
                                                    <div
                                                        className={`post-image2 post ${active === true && selectedResource.id === 6
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={image3} alt="Funding" loading="lazy" />
                                                        <div className="tooltip">{val.name}</div>
                                                    </div>
                                                )}
                                                {val.id === 1 && (
                                                    <div
                                                        className={`post-image3 post  ${active === true && selectedResource.id === 1
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={image4} alt="Organization" loading="lazy" />
                                                        <div className="tooltip">{val.name}</div>
                                                    </div>
                                                )}
                                                {val.id === 5 && (
                                                    <div
                                                        className={`post-image4 post  ${active === true && selectedResource.id === 5
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={parent} alt=" Teacher Parents Support" loading="lazy" />
                                                        <div className="tooltip">
                                                            {val.name}
                                                        </div>
                                                    </div>
                                                )}
                                                {val.id === 4 && (
                                                    <div
                                                        className={`post-image5 post ${active === true && selectedResource.id === 4
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={vic1} alt="Student Support" loading="lazy" />
                                                        <div className="tooltip">{val.name}</div>
                                                    </div>
                                                )}
                                                {val.id === 3 && (
                                                    <div
                                                        className={`post-image6 post ${active === true && selectedResource.id === 3
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                        onClick={() => {
                                                            handleSelectedResource(val.name, val.id);
                                                        }}
                                                    >
                                                        <img className="img-fluid cursor-pointer" src={stargroup} alt="Experiences" loading="lazy" />
                                                        <div className="tooltip">{val.name}</div>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                            </div>
                            <h3>Select a resource, service or event from your saved items.</h3>
                        </div>
                    </div>
                    <div className="post-button text-center">
                        <button onClick={redirectToSaveResource}>Post</button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ResourcePopup;
