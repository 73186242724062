
import {
  InsertDriveFile,
  PictureAsPdf,
  Description,
  InsertChart,
  Slideshow,
} from "@mui/icons-material";
import GifBoxIcon from "@mui/icons-material/GifBox";

export const getFileIcon = (extension) => {
  switch (extension) {
    case "txt":
      return <Description className="extension" />;
    case "pdf":
      return <PictureAsPdf className="extension" />;
    case "doc":
    case "docx":
      return <Description className="extension" />;
    case "xls":
    case "xlsx":
      return <InsertChart className="extension" />;
    case "ppt":
    case "pptx":
      return <Slideshow className="extension" />;
    case "gif":
      return <GifBoxIcon className="extension" />;
    default:
      return <InsertDriveFile className="extension" />;
  }
};

