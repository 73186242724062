import React from "react";
import { useEffect } from "react";
import Story from './Story';
import Decision from './Decision';
import OurWhy from './Ourwhy';
import Work from './Work';
import Banner from './Banner';
import Head from "../Layout.WebAppMate/head/Head";
import Explore from "./Explore"
// const Explore = lazy(()=> import("./Explore"))
const Home = () => {
useEffect(() => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Add smooth scrolling animation
    });
    document.body.classList.add("homepage");
    return () => {
      document.body.classList.remove("homepage");
    };
  }, []);
return (<>
    <Head title="Home"
      content="MatchED will be a parent-empowering, educator-empowering, faith-embracing, matching platform.We will apply the same technology-enabled matching platform architecture that has transformed multiple other industries to the field of education to turn the white noise of too many options and opportunities into low-friction and high-value matches."
      name="MtachEd community search,save,share,serve" ></Head>
    <Banner />
    <Work />
    <OurWhy />
    <Explore />
    <Story />
    <Decision />
  </>);
}
export default Home;