/**
     * Subscription page for buy monthy and yearly basis subscription
     * @author krishna dobariya <krishna@webappmate.com>
     * created on: 01-01-2023
     */
import './subscription.css';
import { useEffect, useState } from 'react';
import { makeGetRequest } from '../../services/api';
import { URLS } from '../../constants';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SubscriptionMobileView from './SubscriptionMobileView';

const SubScriptionView = () => {
    const [community, setCommunity] = useState([])
    const [vendor, setVendor] = useState([])
    const [group, setGroup] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [descText, setDescText] = useState({})
    const [selectedType, setSelectedType] = useState({ community: "monthly", vendor: "monthly", group: "monthly" })
     const [savedata, setsavedata] = useState({
        save:true,
        search:true,
    })

    // Get all Subscription api
    useEffect(() => {
        const getSubscription = async () => {
            setIsLoading(true)
            await makeGetRequest(URLS.getSubscriptionPlanList, null, null)
                .then((response) => {
                    let community = response?.data?.find(v => v?.id === 1)
                    setCommunity(community)
                    let vendor = response?.data?.find(v => v?.id === 2)
                    setVendor(vendor)
                    let group = response?.data?.find(v => v?.id === 3)
                    setGroup(group)
                    setIsLoading(false)
                })
                await makeGetRequest(URLS.getSystemSetting, null, null)
                .then((response) => {
                    let data = response?.data?.filter(v => v?.setting_key === "Group_Standard_Member" || v?.setting_key === "Group_Basic_Member" || v?.setting_key === "Group_Permium_Member")
                    let obj = {
                        Group_Basic_Member: data?.find(v => v?.setting_key === "Group_Basic_Member")?.setting_value,
                        Group_Standard_Member: data?.find(v => v?.setting_key === "Group_Standard_Member")?.setting_value,
                        Group_Permium_Member: data?.find(v => v?.setting_key === "Group_Permium_Member")?.setting_value
                    }
                    setDescText(obj)
                    setIsLoading(false)
                })
        }
        getSubscription()
    }, [])

    useEffect(() => {
        document.body.classList.add("subscription");
        return () => {
            document.body.classList.remove("subscription");
        };
    }, []);

    return (
        <>
            {
                isLoading ? <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div> : <>
                    <section className='subscriptionHideMobile'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className='col-12 text-center'>
                                    <h2 className='chooseyourTitle'>View subscription
                                        {/* <small>Buy Now</small> */}
                                    </h2>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={()=> window.scrollTo(0, 100)} >MatchED Community Membership</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={()=> window.scrollTo(0, 2100)} >Vendor 'Serve' Membership</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={()=> window.scrollTo(0, 2700)} >Group Membership</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="community-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container">
                            <div className="row align-items-end">
                                <div className='col-12 col-lg-6 text-start'>
                                    <h5 className='mb-3 text-capitalize'>MatchED Community Membership</h5>
                                    <div className='d-flex align-items-center mb-3'>
                                        <p>Monthly</p>
                                        <label className="toggle-switch ">
                                            <input type="checkbox" checked={selectedType?.community !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, community: selectedType?.community === "monthly" ? "yearly" : "monthly" })} />
                                            <span className="switch" />
                                        </label>
                                        <p className='ms-3 '>Yearly</p>
                                    </div>
                                </div>
                                <div className='col-6 col-lg-3 text-center'>
                                    <h5 className='mb-3'>Standard</h5>
                                    <div className='subscription-label blue-bg'>Free</div>
                                </div>
                                <div className='col-6 col-lg-3 text-center'>
                                    <h5 className='mb-3'>Premium</h5>
                                    <div className='subscription-label orange-bg'>${
                                        community?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.community))?.price ?? 0
                                    }{selectedType?.community !== "monthly" ? "/yr" : "/mo"}</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="accordion my-accordion content-block" id="subscription-accordion">
                                        {community?.tbl_subscription_plan_subheadings?.map(v => (
                                            <div className="accordion-item white-bg content-block" key={v?.title}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button onClick={() => {
                                                        (v?.title === "Saves" ?
                                                            (setsavedata({ search: true, save: !savedata.save })) : setsavedata({ search: true, save: true }));
                                                        (v?.title === "Searches" &&
                                                            setsavedata({ save: true, search: !savedata.search }))
                                                    }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}`} aria-expanded="false" aria-controls={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}>
                                                        {v?.title}
                                                    </button>
                                                </h2>

									        {(v?.title === "Saves" && savedata.save) ?
                                                    <div className="subscriptiondataInfo">
                                                        <div className="row">

                                                            <div className='col-sm-6 col-md-4 col-lg-6 text-start'>
                                                                &nbsp;
                                                            </div>
                                                            <div className='col-3 text-center'>
                                                                <strong>1 GB Data </strong>
                                                            </div>
                                                            <div className='col-3 text-center'>
                                                                <strong> 10 GB Data  </strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : (v?.title === "Searches" && savedata.search) ? (
                                                        <div className="subscriptiondataInfo">
                                                            <div className="row">

                                                                <div className='col-sm-6 col-md-4 col-lg-6 text-start'>
                                                                    &nbsp;
                                                                </div>
                                                                <div className='col-3 text-center'>
                                                                    <strong>15 Searches</strong>
                                                                </div>
                                                                <div className='col-3 text-center'>
                                                                    <strong> Unlimited Searches</strong>
                                                                </div>
                                                            </div></div>
                                                    ) :
                                                        (" ")
                                                }
                                                <div id={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#subscription-accordion">
                                                    <div className="accordion-body">
                                                        <ul className='content-list'>
                                                            {v?.tbl_subscription_checklist_masters?.map(p => (
                                                                <li key={p?.heading}>
                                                                    <div className="row">
                                                                        <div className='col-6 text-start'>
                                                                            <p className=''>{p?.heading}
                                                                                {p?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                                    <i className='fa fa-exclamation-circle'></i>
                                                                                </a>}
                                                                            </p>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 2 && t?.status === "active") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }} ></i> : <i className="fa fa-times-circle-o"></i>}

                                                                        </div>
                                                                        <div className='col-3'>
                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 1 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 47 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="serve-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container my-accordion">

						 <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Explore Vendor 'Serve' Membership
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">


                            <div className="row align-items-end">
                                <div className='col-12 col-lg-6 text-start'>
                                    <h5 className='text-capitalize'>Vendor 'Serve' Membership</h5>
                                    <h6 className='mb-3 groupsindividuals'>for both Individuals and Groups</h6>

                                    <div className='d-flex align-items-center mb-3'>
                                        <p>Monthly</p>
                                        <label className="toggle-switch ">
                                            <input type="checkbox" checked={selectedType?.vendor !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, vendor: selectedType?.vendor === "monthly" ? "yearly" : "monthly" })} />
                                            <span className="switch" />
                                        </label>
                                        <p className='ms-3 '>Yearly</p>
                                    </div>
                                </div>
                                <div className='col-6 col-lg-3 text-center'>
                                    <h5 className='mb-3'>Basic</h5>
                                    <div className='subscription-label blue-bg'>Free</div>
                                </div>
                                <div className='col-6 col-lg-3 text-center'>
                                    <h5 className='mb-3'>Premium</h5>
                                    <div className='subscription-label orange-bg'>${
                                        vendor?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.vendor))?.price
                                    }{selectedType?.vendor !== "monthly" ? "/yr" : "/mo"}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-12 p-3 white-bg content-block'>
                                    <ul className='content-list'>
                                        {vendor?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((v, i) => (
                                            <ul className='content-list' key={i}>
                                                <li key={v?.heading}>
                                                    <div className="row">
                                                        <div className='col-6 text-start'>
                                                            <p className=''>{v?.heading}
                                                                {v?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={v?.tooltip}>
                                                                    <i className='fa fa-exclamation-circle'></i>
                                                                </a>}
                                                            </p>
                                                        </div>
                                                        <div className='col-3'>
                                                            {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 50 && t?.status === "active") ?
                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                        </div>
                                                        <div className='col-3'>
                                                            {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 49 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 48 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            </div>
                            </div>
                        </div>
                        </div>
                    </section>
                    <section id="group-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container my-accordion">

						 <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Explore Group Membership
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">


                            <div className="row align-items-end">
                                <div className='col-xl-6 col-lg-5 text-start'>
                                    <h5 className='mb-3 text-capitalize membershipGroup'>Group Membership</h5>
                                    <div className='d-flex align-items-center mb-3'>
                                        <p>Monthly</p>
                                        <label className="toggle-switch ">
                                            <input type="checkbox" checked={selectedType?.group !== "monthly" ? true : false} onChange={(e) => setSelectedType({ ...selectedType, group: selectedType?.group === "monthly" ? "yearly" : "monthly" })} />
                                            <span className="switch" />
                                        </label>
                                        <p className='ms-3 '>Yearly</p>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                                    <h5 className='mb-2'>Basic</h5>
                                    <h6>{descText?.Group_Basic_Member}</h6>
                                    <div className='subscription-label blue-bg'>Free</div>
                                </div>
                                <div className='col-6 col-sm-4 col-md-4 col-lg-2 text-center'>
                                    <h5 className='mb-2'>Standard</h5>
                                    <h6>{descText?.Group_Standard_Member}</h6>
                                    <div className='subscription-label gray-bg'>${
                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "standard_price"))?.price
                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>
                                </div>
                                <div className='col-sm-4 col-md-4 col-lg-2 text-center'>
                                    <h5 className='mb-2'>Premium</h5>
                                    <h6>{descText?.Group_Permium_Member}</h6>
                                    <div className='subscription-label orange-bg'>${
                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "premium_price"))?.price
                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-12 p-3 white-bg content-block'>
                                    <ul className='content-list'>
                                        {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                            <ul className='content-list' key={i}>
                                                <li key={p?.heading}>
                                                    <div className="row">
                                                        <div className='col-xl-6 col-lg-5 text-start'>
                                                            <p className=''>{p?.heading}
                                                                {p?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                    <i className='fa fa-exclamation-circle'></i>
                                                                </a>}
                                                            </p>
                                                        </div>
                                                        <div className='col-2'>
                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 51 && t?.status === "active") ?
                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                        </div>
                                                        <div className='col-2'>
                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 52 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 53 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                        </div>
                                                        <div className='col-2'>
                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 58 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 59 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                    <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* ))} */}
                                            </ul>
                                            // ))
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            </div>
                            </div>

                        </div>
                        </div>

                    </section>
                    <SubscriptionMobileView />
                </>
            }

        </>
    )
}
export default SubScriptionView;