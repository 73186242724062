import React, { useEffect, useState } from "react";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

/**
 * AddMember component is used to add users to a chat.
 * It allows selecting users from a list and adding them to the chat.
 * It also provides a search functionality to filter users.
 * 
 * @author Krishna Dabariya <krishna.webappmate@gmail.com>
 */

const AddMember = (props) => {
  const payLoad = decodeToken().userAuth.id;
  const [filteredUser, setFilteredUser] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [page, setPage] = useState(1);
  const [spin, setSpin] = useState(true);

  // Function to add a user to the chat
  const AddUser = (id) => {
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
          makePostRequestForAll(URLS.getChatWithGroupUser, {
            user_id: payLoad,
            group_id: "",
          }).then((res) => {
            props.data(res.data);
          });
        } else if (res.code === 400) {
          toastifyMessage(res.message, "warn");
        }
      })
      .catch((e) => {
        console.error("Error:", e);
      });
  };

  // Fetch user list on component mount or when page/search changes
  useEffect(() => {
    setSpin(true);
    makePostRequestForAll(URLS.getChatMemberListToAddApi, {
      user_id: payLoad,
      item_per_page: 10,
      page_number: page,
      search: searchItem,
    }).then((res) => {
      if (page === 1) {
        setSpin(false);
        setFilteredUser(res?.data);
      } else {
        setSpin(false);
        setFilteredUser((prevFilteredUser) => [...prevFilteredUser, ...res?.data]);
      }
    });
  }, [page, searchItem, payLoad]);

  return (
    <div>
      <div className="sharepopupOuter">
        <div className="chatboxview chatboxviewNew">
          {spin ? <FourDotsLoader /> :
            <>
              <button className="close-btn" onClick={props.handleCloseButton}>
                <i className="fa fa-close closecs"></i>
              </button>
              {filteredUser.length > 0 ?
                <>
                  <h1 className="add-title">Add Members</h1>
                  <div className="form-group my-3">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="search"
                      onChange={(e) => { setSearchItem(e?.target?.value); setPage(1); }}
                      value={searchItem}
                    />
                  </div>
                </> : ""}
              <div className="search-list list-searches">
                {filteredUser.length > 0 ? (
                  filteredUser?.map((val, index) => {
                    return (
                      <div className="user-search py-2 border-bottom" key={index}>
                        <div>
                          <h2>{val?.firstName}</h2>
                          <p>{val?.email}</p>
                        </div>
                        <div className="py-2">
                          <button onClick={() => AddUser(val.id)}>Add</button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="search-list-popup">
                    <h1>You currently have not added any friends. Please go to "My Friends" to add friends to your profile.</h1>
                  </div>
                )}
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AddMember;
