/**
     * Subscription page for buy monthy and yearly basis subscription
     * @author Gaurang Ghadiya <ghadiya.gaurang@webappmate.com>
     * created on: 22-05-2023
     */
import './subscription.css';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { makeGetRequest, makePostRequest } from '../../services/api';
import { URLS } from '../../constants';
import { decodeToken, style, toastifyMessage } from '../../utilities/CustomFunctions';
import { Box, Modal, Typography } from '@mui/material';
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import SubscriptionMobile from './SubscriptionMobile';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Subscription = () => {
    const navigate = useNavigate()
    const [community, setCommunity] = useState([])
    const [vendor, setVendor] = useState([])
    const [group, setGroup] = useState([])
    const [subscriptionList, setSubscriptionList] = useState([])
    const [userBuyData, setUserBuyData] = useState([])
    const [descText, setDescText] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [upcoming, setUpcoming] = useState([])
    const [activated, setactivated] = useState([])
    const [savedata, setsavedata] = useState({
        save: true,
        search: true,
    })
    const [selectedType, setSelectedType] = useState({ community: "monthly", vendor: "monthly", group: "monthly" })
    let user_id = decodeToken().userAuth.id;


    // Get all Subscription api


    useEffect(() => {
        const getSubscription = async () => {
            setIsLoading(true)
            await makeGetRequest(URLS.getSubscriptionPlanList, null, null)
                .then((response) => {
                    let community = response?.data?.find(v => v?.id === 1)
                    setCommunity(community)
                    let vendor = response?.data?.find(v => v?.id === 2)
                    setVendor(vendor)
                    let group = response?.data?.find(v => v?.id === 3)
                    setGroup(group)
                    setIsLoading(false)
                })
            await makePostRequest(URLS.getUserSubscription, null, {}, null)
                .then((response) => {
                    setUserBuyData(response?.data?.map(v => v?.current_plan_id))
                    let upcome = response?.data?.filter(v => parseInt(v?.subscription_active) === 1)
                    let activ = response?.data?.filter(v => parseInt(v?.subscription_active) === 0)
                    setUpcoming(upcome?.map(v => v?.current_plan_id))
                    setactivated(activ?.map(v => v?.current_plan_id))
                    setIsLoading(false)
                }).catch(e => {
                    setIsLoading(false)
                })
            await makeGetRequest(URLS.getSystemSetting, null, null)
                .then((response) => {
                    let data = response?.data?.filter(v => v?.setting_key === "Group_Standard_Member" || v?.setting_key === "Group_Basic_Member" || v?.setting_key === "Group_Permium_Member" || v?.setting_key === "free_searches_count_monthly")
                    let obj = {
                        Group_Basic_Member: data?.find(v => v?.setting_key === "Group_Basic_Member")?.setting_value,
                        Group_Standard_Member: data?.find(v => v?.setting_key === "Group_Standard_Member")?.setting_value,
                        Group_Permium_Member: data?.find(v => v?.setting_key === "Group_Permium_Member")?.setting_value,
                        Group_Searches: data?.find(v => v?.setting_key === "free_searches_count_monthly")?.setting_value
                    }
                    setDescText(obj)
                    setIsLoading(false)
                })
        }
        getSubscription()
        // eslint-disable-next-line
    }, [])
    // Sekect any subscription
    const selectBtn = (id, type) => {
        let newData = subscriptionList?.filter(v => v?.membership_type !== type)
        setSubscriptionList([...newData, { current_plan_id: id, membership_type: type }])
    }

    // Buy any subscription
    const onBuyNow = async () => {
        const body = {
            user_id: user_id,
            subscription_list: subscriptionList
        }
        const upgrade = {
            subscription_list: subscriptionList
        }
        if (userBuyData?.length > 0) {
            makePostRequest(URLS.subscriptionConfirmUpgrade, null, upgrade, null).then((response) => {
                let data = response?.data?.subscription_list?.filter(v => v?.future === true)
                data?.map(v =>
                    toastifyMessage(v?.message, "success")
                )
                if (response?.code === 400) {
                    setSubscriptionList([])
                    toastifyMessage(response?.message, "success");
                } else if (subscriptionList.length <= 0) {
                    toastifyMessage("Please select plan.", "error")
                }
                else {
                    navigate("/purchase-page", { state: { ...response?.data, isUpgrade: true } });
                }
            }).catch(e => console.log(e))
        } else {
            makePostRequest(URLS.subscriptionConfirm, null, body, null).then((response) => {
                if (subscriptionList.length <= 0) {
                    toastifyMessage("Please select plan.", "error")
                }
                else {
                    navigate("/purchase-page", { state: response?.data });
                }
            }).catch(e => console.log(e))
        }
    }
    // without select any subscription , click on skip button to skip
    const Skip = () => {
        setOpen(true)
    }
    const confirmSkip = () => {
        makePostRequest(URLS.freeSubscription, null, {}, null).then(async (response) => {
            await makeGetRequest(URLS.token, null, null).then((re) => {
                toastifyMessage(response?.message, "success");
                localStorage.setItem("auth-token", re?.data?.authorization);
                setOpen(false)
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            });

        }).catch(e => console.log(e))
    }

    const handleClickScroll = () => {
        const community = document.getElementById('community-member');
        if (community) {
            // 👇 Will scroll smoothly to the top of the next section
            community.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo(0, 100);
        }
    };

    useEffect(() => {
        document.body.classList.add("subscription");
        return () => {
            document.body.classList.remove("subscription");
        };
    }, []);

    return (
        <>
            {
                isLoading ? <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div> : <>
                    <section className='subscriptionHideMobile'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className='col-12 text-center'>
                                    <h2 className='chooseyourTitle'>Choose your subscription
                                        <small>Buy Now</small>
                                    </h2>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={handleClickScroll} >MatchED Community Membership</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={() => window.scrollTo(0, 650)} >Vendor 'Serve' Membership</button>
                                </div>
                                <div className='col-auto'>
                                    <button className='tabbing' onClick={() => window.scrollTo(0, 970)} >Group Membership</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="community-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container">
                            <div className="row align-items-end">
                                <div className='subs-community'>
                                    <div className='col-12 col-lg-6 text-start'>
                                        <h5 className='mb-3 text-capitalize'>MatchED Community Membership</h5>
                                        <div className='d-flex align-items-center mb-3'>
                                            <p>Monthly</p>
                                            <label className="toggle-switch ">
                                                <input type="checkbox" checked={selectedType?.community !== "monthly" ? true : false} onChange={(e) => setSelectedType({
                                                    ...selectedType, community: selectedType?.community === "monthly" ? "yearly" : "monthly",
                                                    group: selectedType?.community === "monthly" ? "yearly" : "monthly",
                                                    vendor: selectedType?.community === "monthly" ? "yearly" : "monthly"
                                                })} />
                                                <span className="switch" />
                                            </label>
                                            <p className='ms-3 '>Yearly</p>
                                        </div>
                                    </div>

                                    <div className='col-6 col-lg-3 col-md-3 text-center'>
                                        <h5 className='mb-3'>Standard</h5>
                                        <div className='subscription-label blue-bg'>Free</div>
                                    </div>
                                    <div className='col-6 col-lg-3 col-md-3 text-center'>
                                        <h5 className='mb-3'>Premium</h5>
                                        <div className='subscription-label orange-bg'>${
                                            community?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.community))?.price ?? 0
                                        }{selectedType?.community !== "monthly" ? "/yr" : "/mo"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="accordion my-accordion content-block" id="subscription-accordion">
                                        {community?.tbl_subscription_plan_subheadings?.map(v => (
                                            <div className="accordion-item white-bg content-block" key={v?.title}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button onClick={() => {
                                                        (v?.title === "Saves" ?
                                                            (setsavedata({ search: true, save: !savedata.save })) : setsavedata({ search: true, save: true }));
                                                        (v?.title === "Searches" &&
                                                            setsavedata({ save: true, search: !savedata.search }))
                                                    }} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}`} aria-expanded="false" aria-controls={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")}>
                                                        {v?.title}
                                                    </button>
                                                </h2>
                                                {(v?.title === "Saves" && savedata.save) ?
                                                    <div className="subscriptiondataInfo">
                                                        <div className="row">
                                                            <div className='col-sm-6 col-md-4 col-lg-6 text-start'>
                                                                &nbsp;
                                                            </div>
                                                            <div className='col-3 text-center'>
                                                                <strong>1 GB Data </strong>
                                                            </div>
                                                            <div className='col-3 text-center'>
                                                                <strong> 10 GB Data  </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : (v?.title === "Searches" && savedata.search) ? (
                                                        <div className="subscriptiondataInfo">
                                                            <div className="row">

                                                                <div className='col-sm-6 col-md-4 col-lg-6 text-start'>
                                                                    &nbsp;
                                                                </div>
                                                                <div className='col-3 text-center'>
                                                                    <strong>{descText.Group_Searches} Searches</strong>
                                                                </div>
                                                                <div className='col-3 text-center'>
                                                                    <strong> Unlimited Searches</strong>
                                                                </div>
                                                            </div></div>
                                                    ) :
                                                        (" ")
                                                }
                                                <div id={v?.title?.replaceAll(" ", "")?.replaceAll("&", "")} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#subscription-accordion">
                                                    <div className="accordion-body">
                                                        <ul className='content-list'>
                                                            {v?.tbl_subscription_checklist_masters?.map(p => (
                                                                <li key={p?.heading}>
                                                                    <div className="row">
                                                                        <div className='col-6 text-start'>
                                                                            <p className=''>{p?.heading}
                                                                                {p?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                                    <div className='facustomeI'>
                                                                                        i
                                                                                    </div>
                                                                                </a>}
                                                                            </p>
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 2 && t?.status === "active") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }} ></i> : <i className="fa fa-times-circle-o"></i>}
                                                                        </div>
                                                                        <div className='col-3'>
                                                                            {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 1 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 47 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-6'>
                                </div>
                                <div className='subs-community'>
                                    <div className='col-6 col-lg-3 col-md-3 text-center'>

                                        {activated?.includes(1) || activated?.includes(47) ?
                                            <div className={`subscription-button blue-bg courser-point ${subscriptionList?.find(v => v?.current_plan_id === 2) && "selecetedBorder "}`} >
                                                Can't Subscribe  </div> :
                                            <div className={`subscription-button blue-bg courser-point ${subscriptionList?.find(v => v?.current_plan_id === 2) && "selecetedBorder "}`} onClick={() => selectBtn(2, 1)}>
                                                {(userBuyData?.includes(2) && activated?.includes(2)) ? "Currently Subscribed"
                                                    : (userBuyData?.includes(2) && upcoming?.includes(2)) ? "Upcoming"
                                                        : subscriptionList?.find(v => v?.current_plan_id === 2) ? "Selected"
                                                            : "Select"}</div>
                                        }
                                    </div>
                                    <div className='col-6 col-lg-3 col-md-3 text-center'>
                                        <div className={` subscription-button orange-bg courser-point
                             ${(selectedType?.community === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 47)) && "selecetedBorder "}
                             ${(selectedType?.community === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 1)) && "selecetedBorder "}
                             ${(selectedType?.community === "yearly" && userBuyData?.includes(47)) && "pointer-none"}
                            ${(selectedType?.community === "monthly" && userBuyData?.includes(1)) && "pointer-none"}
                            ${(selectedType?.community === "monthly" && activated?.includes(47)) && "pointer-none"}
                             ` }
                                            onClick={() => selectBtn(selectedType?.community === "yearly" ? 47 : 1, 1)}>
                                            {selectedType?.community === "yearly" ?
                                                (selectedType?.community === "yearly" && userBuyData?.includes(47) && activated?.includes(47)) ? "Currently Subscribed" :
                                                    (selectedType?.community === "yearly" && userBuyData?.includes(47) && upcoming?.includes(47)) ? "Upcoming" :
                                                        (selectedType?.community === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 47)) ? "Selected" : "Select"
                                                : <>
                                                    {(selectedType?.community === "monthly" && userBuyData?.includes(1) && activated?.includes(1)) ? "Currently Subscribed"
                                                        : (selectedType?.community === "monthly" && userBuyData?.includes(1) && upcoming?.includes(1)) ? "Upcoming" : (selectedType?.community === "monthly" && activated?.includes(47)) ? "Can't Subscribe" : (selectedType?.community === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 1)) ?
                                                            "Selected" : "Select"}
                                                </>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="serve-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container  my-accordion">
                            <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Explore Vendor 'Serve' Membership
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row align-items-end">
                                            <div className='subs-community'>
                                                <div className='col-12 col-lg-6 text-start'>
                                                    <h5 className='text-capitalize'>Vendor 'Serve' Membership</h5>
                                                    <h6 className='mb-3 groupsindividuals'>for both Individuals and Groups</h6>
                                                </div>

                                                <div className='col-6 col-lg-3 col-md-3 text-center'>
                                                    <h5 className='mb-3'>Basic</h5>
                                                    <div className='subscription-label blue-bg'>${vendor?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.vendor))?.price.toFixed(2)
                                                    }{selectedType?.vendor !== "monthly" ? "/yr" : "/mo"}</div>
                                                </div>
                                                <div className='col-6 col-lg-3 col-md-3 text-center'>
                                                    <h5 className='mb-3'>Premium</h5>
                                                    <div className='subscription-label orange-bg'>${
                                                        vendor?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.vendor))?.price.toFixed(2)
                                                    }{selectedType?.vendor !== "monthly" ? "/yr" : "/mo"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-12 p-3 white-bg content-block '>
                                                {vendor?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((v, i) => (
                                                    <ul className='content-list' key={i}>
                                                        <li key={v?.heading}>
                                                            <div className="row">
                                                                <div className='col-6 text-start'>
                                                                    <p className=''>{v?.heading}
                                                                        {v?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={v?.tooltip}>

                                                                        </a>}
                                                                    </p>
                                                                </div>
                                                                <div className='col-3'>
                                                                    {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 50 && t?.status === "active") ?
                                                                        <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                </div>
                                                                <div className='col-3'>
                                                                    {v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 49 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                        <i className="fa fa-check-circle" style={{ color: "green" }}></i> : v?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 48 && t?.status === "active" && selectedType?.community === "yearly") ? <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-lg-6'>
                                            </div>
                                            <div className='subs-community'>
                                                <div className='col-6 col-lg-3 col-md-3 text-center'>
                                                    {activated?.includes(48) || activated?.includes(49) ?
                                                        <div className="subscription-button blue-bg ">Can't Subscribe </div> :
                                                        <div className={`subscription-button blue-bg courser-point ${subscriptionList?.find(v => v?.current_plan_id === 50) && "selecetedBorder "}`} onClick={() => selectBtn(50, 2)}>
                                                            {(userBuyData?.includes(50) && activated?.includes(50)) ? "Currently Subscribed" :
                                                                (userBuyData?.includes(50) && upcoming?.includes(50)) ? "Upcoming" :
                                                                    subscriptionList?.find(v => v?.current_plan_id === 50) ? "Selected" : "Select"}</div>
                                                    }
                                                </div>
                                                <div className='col-6 col-lg-3 col-md-3 text-center'>
                                                    <div className={`subscription-button orange-bg courser-point
                            ${(selectedType?.vendor === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 48)) && "selecetedBorder "}
                            ${(selectedType?.vendor === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 49)) && "selecetedBorder "}
                            ${(selectedType?.vendor === "yearly" && userBuyData?.includes(48)) && "pointer-none"}
                            ${(selectedType?.vendor === "monthly" && userBuyData?.includes(49)) && "pointer-none"}
                            `} onClick={() => selectBtn(selectedType?.vendor === "yearly" ? 48 : 49, 2)}>


                                                        {selectedType?.group === "yearly" ?
                                                            (selectedType?.vendor === "yearly" && userBuyData?.includes(48) && activated?.includes(48)) ? "Currently Subscribed" :
                                                                (selectedType?.vendor === "yearly" && userBuyData?.includes(48) && upcoming?.includes(48)) ? "Upcoming" :
                                                                    (selectedType?.vendor === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 48)) ? "Selected"
                                                                        : "Select"
                                                            : <>
                                                                {(selectedType?.vendor === "monthly" && userBuyData?.includes(49) && activated?.includes(49)) ? "Currently Subscribed"
                                                                    : (selectedType?.vendor === "monthly" && userBuyData?.includes(49) && upcoming?.includes(49)) ? "Upcoming" :
                                                                        (selectedType?.group === "monthly" && activated?.includes(48)) ? "Can't Subscribe " :
                                                                            (selectedType?.vendor === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 49)) ?
                                                                                "Selected" : "Select"}
                                                            </>

                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="group-member" className='subscription-tab subscriptionHideMobile'>
                        <div className="container  my-accordion">
                            <div className="col-12 white-bg content-block accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Explore Group Membership
                                    </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse p-3 pt-0 collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="row align-items-end">
                                            <div className='subs-community'>
                                                <div className='col-xl-6 col-lg-5 text-start'>
                                                    <h5 className='mb-3 text-capitalize membershipGroup'>Group Membership</h5>
                                                </div>

                                                <div className='col-6 col-sm-4 col-md-3 col-lg-2 text-center'>
                                                    <h5 className='mb-2'>Basic</h5>
                                                    <h6>{descText?.Group_Basic_Member}</h6>
                                                    <div className='subscription-label lebel-basstanpre blue-bg'>Free</div>
                                                </div>
                                                <div className='col-6 col-sm-4 col-md-3 col-lg-2 text-center'>
                                                    <h5 className='mb-2'>Standard</h5>
                                                    <h6>{descText?.Group_Standard_Member}</h6>
                                                    <div className='subscription-label lebel-basstanpre gray-bg'>${
                                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "standard_price"))?.price.toFixed(2)
                                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>
                                                </div>
                                                <div className='col-sm-4 col-md-3 col-lg-2 text-center'>
                                                    <h5 className='mb-2'>Premium</h5>
                                                    <h6>{descText?.Group_Permium_Member}</h6>
                                                    <div className='subscription-label lebel-basstanpre orange-bg'>${
                                                        group?.tbl_subscription_plans?.find(v => (v?.payment_frequency_type === selectedType?.group && v?.price_type === "premium_price"))?.price.toFixed(2)
                                                    }{selectedType?.group !== "monthly" ? "/yr" : "/mo"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-12 p-3 white-bg content-block'>
                                                {group?.tbl_subscription_plan_subheadings?.[0]?.tbl_subscription_checklist_masters?.map((p, i) => (
                                                    <ul className='content-list' key={i}>
                                                        <li key={p?.heading}>
                                                            <div className="row">
                                                                <div className='col-xl-6 col-lg-5 text-start'>
                                                                    <p className=''>{p?.heading}
                                                                        {p?.tooltip && <a href="#!" referrerPolicy="no-referrer" data-tooltip data-title={p?.tooltip}>
                                                                            <div className='facustomeI'>
                                                                                i
                                                                            </div>
                                                                        </a>}
                                                                    </p>
                                                                </div>
                                                                <div className='subs-community'>
                                                                    <div className='col-2 col-md-3 col-lg-2'>
                                                                        {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 51 && t?.status === "active") ?
                                                                            <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                    </div>
                                                                    <div className='col-2 col-md-3 col-lg-2'>
                                                                        {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 52 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                            <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 53 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                    </div>
                                                                    <div className='col-2 col-md-3 col-lg-2'>
                                                                        {p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 58 && t?.status === "active" && selectedType?.community === "monthly") ?
                                                                            <i className="fa fa-check-circle" style={{ color: "green" }}></i> : p?.tbl_subscription_checklist_mappings?.find(t => t?.plan_id === 59 && t?.status === "active" && selectedType?.community === "yearly") ?
                                                                                <i className="fa fa-check-circle" style={{ color: "green" }}></i> : <i className="fa fa-times-circle-o"></i>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="row lastthreebuttons">
                                            <div className='col-xl-6 col-lg-6 col-3 hidden-xs'>
                                            </div>
                                            <div className='col-3 col-lg-2 col-xl-2 text-center'>
                                                {activated?.includes(52) || activated?.includes(53) ||
                                                    activated?.includes(58) || activated?.includes(59) ?
                                                    <div className="subscription-button blue-bg ">Can't Subscribe</div> :
                                                    <div className={`subscription-button blue-bg courser-point ${subscriptionList?.find(v => v?.current_plan_id === 51) && "selecetedBorder "}`} onClick={() => selectBtn(51, 3)}>
                                                        {(userBuyData?.includes(51) && activated?.includes(51)) ? "Currently Subscribed" :
                                                            (userBuyData?.includes(51) && upcoming?.includes(51)) ? "Upcoming"
                                                                : subscriptionList?.find(v => v?.current_plan_id === 51) ? "Selected" : "Select"}</div>
                                                }
                                            </div>

                                            <div className='col-3 col-lg-2 col-xl-2 text-center'>
                                                <div className={`subscription-button orange-bg courser-point
                                        ${(selectedType?.group === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 53)) && "selecetedBorder "}
                                        ${(selectedType?.group === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 52)) && "selecetedBorder "}
                                        ${(selectedType?.group === "yearly" && userBuyData?.includes(53)) && "pointer-none"}
                                        ${(selectedType?.group === "monthly" && userBuyData?.includes(52)) && "pointer-none"}
                                         ${(selectedType?.group === "monthly" && (activated?.includes(53) || activated?.includes(59))) && "pointer-none"}
                                                                                  ${(selectedType?.group === "monthly" && activated?.includes(58)) && "pointer-none"}
                                        `} onClick={() => selectBtn(selectedType?.group === "yearly" ? 53 : 52, 3)}>
                                                    {selectedType?.group === "yearly" ? <>
                                                        {(selectedType?.group === "yearly" && activated?.includes(59)) ? "Can't Subscribe" : <>       {
                                                            (selectedType?.group === "yearly" && userBuyData?.includes(53) && activated?.includes(53)) ? "Currently Subscribed" :
                                                                (selectedType?.group === "yearly" && userBuyData?.includes(53) && upcoming?.includes(53)) ? "Upcoming" :
                                                                    (selectedType?.group === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 53)) ? "Selected" : "Select"}
                                                        </>
                                                        }
                                                    </>
                                                        : <>
                                                            {(selectedType?.group === "monthly" && userBuyData?.includes(52) && activated?.includes(52)) ? "Currently Subscribed"
                                                                : (selectedType?.group === "monthly" && userBuyData?.includes(52) && upcoming?.includes(52)) ? "Upcoming" :
                                                                    (selectedType?.group === "monthly" && (activated?.includes(53) || activated?.includes(59) ||
                                                                        activated?.includes(58))) ? "Can't Subscribe" :
                                                                        (selectedType?.group === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 52)) ?
                                                                            "Selected" : "Select"}
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                            <div className='col-3 col-lg-2 col-xl-2 text-center'>
                                                <div className={`subscription-button orange-bg courser-point
                                        ${(selectedType?.group === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 59)) && "selecetedBorder "}
                                        ${(selectedType?.group === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 58)) && "selecetedBorder "}
                                        ${(selectedType?.group === "yearly" && userBuyData?.includes(59)) && "pointer-none"}
                                        ${(selectedType?.group === "monthly" && userBuyData?.includes(58)) && "pointer-none"}
                                        ${(selectedType?.community === "monthly" && (activated?.includes(59) || activated?.includes(53))) && "pointer-none"}
                                        ${(selectedType?.group === "yearly" && activated?.includes(59)) && "pointer-none"}
                                       `} onClick={() => selectBtn(selectedType?.group === "yearly" ? 59 : 58, 3)}>
                                                    {selectedType?.group === "yearly" ?
                                                        (selectedType?.group === "yearly" && userBuyData?.includes(59) && activated?.includes(59)) ? "Currently Subscribed" :
                                                            (selectedType?.group === "yearly" && userBuyData?.includes(59) && upcoming?.includes(59)) ? "Upcoming"
                                                                : (selectedType?.group === "yearly" && subscriptionList?.find(v => v?.current_plan_id === 59)) ? "Selected"
                                                                    : "Select"
                                                        : <>
                                                            {(selectedType?.group === "monthly" && userBuyData?.includes(58) && activated?.includes(58)) ? "Currently Subscribed"
                                                                : (selectedType?.group === "monthly" && userBuyData?.includes(58) && upcoming?.includes(58)) ? "Upcoming" : (selectedType?.group === "monthly" && (activated?.includes(59) || activated?.includes(53))) ? "Can't Subscribe" :
                                                                    (selectedType?.group === "monthly" && subscriptionList?.find(v => v?.current_plan_id === 58)) ?
                                                                        "Selected" : "Select"}
                                                        </>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 d-flex justify-content-center'>
                            {userBuyData?.length === 0 && <div className='btn1 d-flex justify-content-center align-items-center  rounded-5 ' onClick={Skip} style={{ width: "25%", border: " 1px solid #D9D9D9", background: "transparent", color: "black", cursor: "pointer" }} >Skip</div>}
                            <div className='btn1 d-flex justify-content-center align-items-center  rounded-5' onClick={onBuyNow} style={{ width: "25%", background: "#265472", marginLeft: "10px", cursor: "pointer" }} >Buy Now</div>
                        </div>
                    </section>
                    <SubscriptionMobile />
                </>
            }
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
                    >
                        Dear, Do you want to skip subscription process. If yes then we will allocate you all free type of subscriptions automatically.
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button className="child-delete" onClick={() => setOpen(false)}>
                            No
                        </button>{" "}
                        <button className="child-delete" onClick={() => confirmSkip()}>
                            Yes
                        </button>{" "}
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}
export default Subscription;