import React from "react";
import './story-css.css';
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import { useEffect, useState } from "react";
const Story = () => {
	const [homeVideo, setHomeVideo] = useState([]);
// this function use for get video
	useEffect(() => {
		const data = {
			"id": "6"
		}
		makePostRequest(URLS.GetHomeVideo, null, data, null)
			.then((res) => {
				if (res !== undefined && res.code === 200) {
					setHomeVideo(res.data)
				}
			}).catch((error) => {
				console.log("error::", error);
			})
	}, [])
	return (
		<div className="ourStory">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						{homeVideo.length > 0 && homeVideo.map((val, index) => {
							return <>
								<video width="100%" height="450px" controls controlsList="nodownload">
									<source
										src={val.content}
										type={`video/${val.image_type}`}
										loading="lazy" >
									</source>
								</video>
							</>
						})
						}
					</div>
					<div className="col-lg-6">
						<div className="storyInnerBox">
							<h5>Meet the founders</h5>
							<h4>Our Story</h4>
							<div className="storyInnerBoxparainfo">
								<p>
									Greetings! We're glad you're here learning a bit more about MatchED!</p>
								<p>As a homeschooling family of 14 years for our three boys, and being in the field of education for over 20 years, we have seen the need to make it easier to find and share educational
									resources that align with a family’s values and faith, while supporting each child’s unique learning styles and passions.</p>

								<p>Andrew’s background as a homeschool dad, school choice advocate and career educator has given him over two decades of experience in building multiple organizations to expand educational opportunities for children in many learning environments. Carlee
									has led her family and community by homeschooling, mentoring others, and supporting local homeschool communities.</p>

								<p>In all these endeavors, we have repeatedly seen the importance of matching educational resources to children, sharing resources with friends and networks, and building community by facilitating event organization and creating channels for communication. . This led to the founding of MatchED. We are hopeful MatchED will be useful to you and will help ignite the passion for learning in all the children you serve! Please send us your feedback on how we can improve this tool...we love to hear your stories of success as well as how we can better serve our community.
									Blessings to you and to all the children you serve!
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Story;