import React from "react";
import './yourgroups4.css';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Multiselect from 'multiselect-react-dropdown';
import CryptoJS from 'crypto-js';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toastifyMessage, style, decodeToken, getTimeDifference } from "../../utilities/CustomFunctions";
import Joingrouppopup from "./Joingrouppopup";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;

const SubgroupList = (props) => {
    const userId = decodeToken().userAuth.id;
    let useractive = localStorage.getItem("useractive");
    const [joinGroupPopUp, setJoinGroupPopUp] = useState(false);
    const navigate = useNavigate();
    const [spin, setSpin] = useState(false);
    const [subGroupList, setSubGroupList] = useState([])
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState(true);
    const [joinType, setJoinType] = useState("")
    const [open, setOpen] = useState(false)
    const [isAdminLogin, setIsAdminlogin] = useState();
    const [removeId, setRemoveId] = useState();

    // this function use for get subgroup list
    const getSubGroupList = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "searchKey": "",
            "page_number": page,
            "item_per_page": 9,
            "joinType": joinType,
            user_id: userId
        }
        makePostRequest(URLS.subGroupList, null, data, null)
            .then((res) => {
                setSpin(false)
                setIsAdminlogin(res.data.isAdmin)
                if (page === 1) {
                    setSubGroupList(res?.data?.groupMemberList)
                } else {
                    setSubGroupList([...subGroupList, ...res?.data?.groupMemberList]);
                }
                if (res.data.remaining_count === 0) {
                    setStatus(false)
                } else {
                    setStatus(true)
                }
            }).catch((error) => {
                setSpin(false)
                console.log("getSubGroupList:::", error)
            })
    }

    // remove group from sub group
    const removeGroup = () => {
        let data = {
            id: removeId
        }
        console.log("payload", data)
        makePostRequest(URLS.removeGroupSubGroup, null, data, null)
            .then((res) => {
                setSpin(false)
                console.log("respone", res.data)
                toastifyMessage(res.message, "success")
                setOpen(false)
                getSubGroupList(props.id);

            }).catch((error) => {
                setSpin(false)
                console.log("getSubGroupList:::", error)
            })
    }

    useEffect(() => {
        getSubGroupList(props.id);
        // eslint-disable-next-line 
    }, [page, joinType]);

    useEffect(() => {
        if (useractive === "") {
            navigate("/sign-in");
        }
        getSubGroupList(props.id)
        document.body.classList.add("yourgroup");
        return
        // eslint-disable-next-line 
    }, []);

    return (<>
        {
            spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
        }
        {
            props?.admin &&
            <button className="invite_button mt-3"
                onClick={() => {
                    setJoinGroupPopUp(true)
                }}
            >Connect Group</button>
        }
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="text-center"
                    style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                >
                    Do you want to remove this group?
                </Typography>
                <Typography
                    id="modal-modal-description"
                    sx={{ mt: 3 }}
                    className="d-flex justify-content-center gap-4"
                >
                    <button className="child-delete" onClick={removeGroup} >
                        Yes
                    </button>{" "}
                    <button className="child-cancel" onClick={() => setOpen(false)}>
                        No
                    </button>
                </Typography>
            </Box>
        </Modal>
        <div className="inviteGpopupinnersub mt-3">
            <div className="newgroupmemberselectbox float-start">
                <div className='share-multiple-form-select'>
                    <Multiselect
                        options={[
                            { name: "Main Group", value: "" },
                            { name: "Sub Group", value: "join" },
                        ]}
                        onSelect={(selectedList) => {
                            const selectedValue = selectedList?.length > 0 ? selectedList[0].value : "";
                            setJoinType(selectedValue);
                        }}
                        selectedValues={[{ name: "Main Group", value: "" }]}
                        displayValue="name"
                        placeholder="Group"
                        singleSelect
                    />
                </div>
                <div className="facustomeI">
                    i
                    <div className="tootlippillerinfo">
                        <p>Selecting "Main Group" displays the group that your group is a subgroup of. Selecting "Sub Group" displays groups that are subgroups of your group.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="member-outer-main row">
            {
                subGroupList?.length > 0 ? subGroupList?.map((val, index) => {
                    return <>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-12 ">
                            <div className="member-inner-div">
                                <div className="member-inner-flex">
                                    <div className="imgcirclee">
                                        <img className="img-fluid" src={val?.tbl_group?.image} alt="group" loading="lazy" />
                                    </div>
                                </div>
                                <h6 className="text-capitalize" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val?.tbl_group?.id?.toString(), secretKey).toString())}`)}>{val?.tbl_group?.group_name}</h6>
                                <p className="text-center">Joined {getTimeDifference(val?.last_updated_date
                                )}</p>
                                <div className="member-button text-center">
                                    <button className="view-profile" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val?.tbl_group?.id?.toString(), secretKey).toString())}`)}>View</button>
                                    {isAdminLogin === true ? <button className="remove-from-group" onClick={() => { setOpen(true); setRemoveId(val.id) }}>Remove Group</button> : ""}
                                </div>
                            </div>
                        </div>
                    </>
                }) : <h1 className="text-center my-5">Data Not Found</h1>
            }
            <div className="show-more">
                {status && <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p>}
            </div>
            {joinGroupPopUp === true ? (
                <Joingrouppopup datar={() => setJoinGroupPopUp(!joinGroupPopUp)} pageStatus="subgroupList" value={props.id} />
            ) : null
            }
        </div>
    </>);

}

export default SubgroupList;