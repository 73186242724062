import React, { useEffect, useState, useRef } from 'react'
import Select from "react-select";
import { makeGetRequest, makePostRequest, makePostRequestForAll } from '../../services/api';
import { URLS } from '../../constants';
import { decodeToken, toastifyMessage } from '../../utilities/CustomFunctions';
import FourDotsLoader from './FourDoted';
import Publishpopup from '../SavedResources.WebAppMate/Publishpopup';

/**
 * create components is used for share resource
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 12-06-2023
 */
/**
* @Kajal <kajal.webappmate@gmail.com>
 * Done all validations on 13-11-2023
 */
const ShareRecoursePopUp = (props) => {
    const payLoad = decodeToken().userAuth.id;
    const [shareResourceData, setShareResourceData] = useState(null);
    const [shareData, setShareData] = useState("");
    const [spin, setSpin] = useState(false)
    const [groupList, setGroupList] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [groupId, setGroupId] = useState([]);
    const [shareFriend, setShareFriends] = useState([]);
    const [friendData, setFriendData] = useState([])
    const [friendList, setFriendList] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [toggleIndex, setToggleIndex] = useState("");
    const [toggleMessage, setToggleMessage] = useState("");
    const [toggleValues, setToggleValues] = useState([]);
    const [resourceToggle, setresourcetoggle] = useState([]);
    const [handleSatate, setHandleState] = useState("");
    const [chnageState, setChnageState] = useState("");
    const [type, setType] = useState([])
    const sharedataFocus = useRef();
    const friendFocus = useRef();
    const groupFocus = useRef();
    const [error, setError] = useState({
        share: "",
        group: "",
        friend: "",
    })
    // share resource options
    const shareResourceOptions = [
        { value: "local", label: "Local MatchED Community" },
        { value: "friend", label: "Friends" },
        { value: "group", label: "Groups" }
    ];
    // this is handle change
    const hanldeInput = (e) => {
        let shareResArr = [];
        e?.map((val, i) => shareResArr.push(val.value))
        setShareData(shareResArr);
        setShareResourceData(e)
    }
    // this function is use for share resource with local,friend & groups
    const ShareResource = () => {

        if (shareData.length <= 0) {
            setError({
                ...error,
                share: "Please select who you would like to share this with.",
            })
            sharedataFocus.current.focus();
        } else if (shareData?.includes("friend") && shareData?.includes("group") && shareFriend.length <= 0 && groupId.length <= 0) {
            setError({
                friend: "Please select the friends you would like to share this with.",
                group: "Please select the groups you would like to share this with.",
            })
        } else if (shareData?.includes("friend") && shareData?.includes("group") && shareFriend.length > 0 && groupId.length <= 0) {
            setError({
                friend: "",
                group: "Please select the groups you would like to share this with.",
            })
        } else if (shareData?.includes("friend") && shareData?.includes("group") && shareFriend.length <= 0 && groupId.length > 0) {
            setError({
                friend: "Please select the friends you would like to share this with.",
                group: "",
            })
        } else if (shareFriend.length <= 0 && shareData?.includes("friend") && !shareData?.includes("group")) {
            setError({
                friend: "Please select the friends you would like to share this with.",
                group: "",
            })
        } else if (shareData?.includes("group") && groupId.length <= 0 && !shareData?.includes("friend")) {
            setError({
                friend: "",
                group: "Please select the groups you would like to share this with.",
            })
        } else {
            setSpin(true)
            const data = {
                "shared_by": payLoad,
                "resource_id": props?.data?.objectID,
                "shared_with": shareData,
                "shared_id": shareFriend,
                "group_ids": groupId,
                "group_announcement_list": toggleValues ? toggleValues.filter(value => value !== null && value !== undefined) : [],
                "group_with_share_list": resourceToggle ? resourceToggle.filter(value => value !== null && value !== undefined) : [],
                "type": false,
                "resource_type": true
            }
            console.log("data::", data)
            makePostRequestForAll(URLS.shareResource, data)
                .then((res) => {
                    console.log("res::::", res)
                    setSpin(false)
                    if (res.code === 200) {
                        toastifyMessage(res.data, "success")
                        props.handleResourceShareClose()
                    }
                }).catch(error => {
                    setSpin(false)
                    console.log("error", error)
                })
        }
    }
    // this function is use for friend & group list
    useEffect(() => {
        makeGetRequest(URLS.friendList, null, null, null)
            .then((res) => {
                var arr = [];
                res?.data?.length > 0 && arr.push({ label: "All", value: "all" });
                res?.data.map((val) => {
                    return arr.push({ label: val.name, value: val.id })
                })
                setFriendList(arr)
            }).catch((error) => {
                console.log("error", error)
            })

        makePostRequest(URLS.getGroupList, null, {}, null)
            .then((res) => {

                var arr = [];
                res?.data?.myGroups?.data?.length > 0 && arr.push({ label: "All", value: "all" })
                res?.data?.myGroups?.data?.map((val, index) => {
                    return arr.push({ label: val?.group_name + " (" + val?.member_type + ") ", value: val?.id, type: val?.member_type, })
                })
                setGroupList(arr);
            }).catch((error) => {
                console.log("error:::", error)
            })
    }, [])

    // this function is use for input handle chnage
    const handleFriendsId = (shareResourceVal) => {
        let shareArr = [];
        shareResourceVal.length > 0 &&
            shareResourceVal.map((val, i) => shareArr.push(val.value));
        if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
            setShareFriends(["all"]);
            setFriendData([{ label: "All", value: "all" }])
        } else {
            setFriendData(shareResourceVal.filter(item => item.value !== "all"))
            setShareFriends(shareArr.filter(item => item !== "all"));
        }
    };


    const handleGroupId = (shareResourceVal) => {
        let shareArr = [];
        const type = []
        shareResourceVal.length > 0 &&
            shareResourceVal.forEach((val, i) => {
                type.push(val.type)
                shareArr.push(val.value);
            });

        if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
            setGroupData({ label: "All", value: "all" });
            setGroupId(["all"]);
        }
        else {
            setGroupData(shareResourceVal.filter(item => item.value !== "all"))
            setGroupId(shareArr.filter(item => item !== "all"));
            setType(type)
        }
    };

    // these functions are use for hanle toggle
    const handleToggleChange = (index, e) => {
        if (e.target.checked) {
            setIsPopupOpen(true);
            setToggleIndex(index)
            setToggleMessage("Do you want to pin this to your group bulletin board?")
            setHandleState(true);
            setChnageState("announce")
        } else {
            setIsPopupOpen(true);
            setToggleIndex(index)
            setToggleMessage("Do you want to unpin this to your group bulletin board?")
            setHandleState(false)
            setChnageState("announce")
        }

    };
    const handleYes = () => {
        const updatedGroupData = [...groupData];
        updatedGroupData[toggleIndex].check = true;
        setGroupData(updatedGroupData);
        const updatedToggleValues = updatedGroupData
            .filter((item) => item.check)
            .map((item) => item.value);
        setToggleValues(updatedToggleValues)
    }
    const handleNo = () => {
        const updatedGroupData = [...groupData];
        updatedGroupData[toggleIndex].check = false;
        setGroupData(updatedGroupData);
        const updatedToggleValues = updatedGroupData
            .filter((item) => item.check)
            .map((item) => item.value);
        setToggleValues(updatedToggleValues)
    }
    const resourceHandleToggleChange = (index, e) => {
        if (e.target.checked) {
            setIsPopupOpen(true);
            setToggleIndex(index)
            setToggleMessage("Do you want to share this to your group resources?")
            setHandleState(true);
            setChnageState("resource");
        } else {
            setIsPopupOpen(true);
            setToggleIndex(index)
            setToggleMessage("Do you want to remove this from your group resources?")
            setHandleState(false);
            setChnageState("resource")
        }
    };
    const handleResourceYes = () => {
        const updatedGroupData = [...groupData];
        updatedGroupData[toggleIndex].resourceCheck = true;
        setGroupData(updatedGroupData);
        const updatedToggleValues = updatedGroupData
            .filter((item) => item.resourceCheck)
            .map((item) => item.value);
        setresourcetoggle(updatedToggleValues)
    }
    const handleResourceNo = () => {
        const updatedGroupData = [...groupData];
        updatedGroupData[toggleIndex].resourceCheck = false;
        setGroupData(updatedGroupData);
        const updatedToggleValues = updatedGroupData
            .filter((item) => item.resourceCheck)
            .map((item) => item.value);
        setresourcetoggle(updatedToggleValues)
    }
    return (
        <>
            <div className="sharepopupOuter">
                {spin && (
                    <div className="sharepopupOuter backgroundOpacity1">
                        <FourDotsLoader />
                    </div>
                )}
                <div className="pop-up-container saveresourcePopupLarge">
                    <button className="close-btn" onClick={() => props.handleResourceShareClose()}>
                        <i className="fa fa-close closecs"></i>
                    </button>
                    <h3> Share Resource</h3>
                    <div className="sharediv-popups">
                        <div className="input-popup1">

                            <label className="pop-label">
                                Share with<span className="start-color">*</span>{" "}
                                <span className="select-one">(select at least one)</span>
                            </label>
                            <div >
                                <Select
                                    value={shareResourceData ? shareResourceData : null}
                                    options={shareResourceOptions}
                                    isMulti
                                    selectMultiple={true}
                                    onChange={hanldeInput}
                                    ref={sharedataFocus}
                                />
                                {shareData.length <= 0 ? <span className="error-span">
                                    {
                                        error.share && error.share
                                    }
                                </span> : " "}
                            </div>
                            {shareData?.includes("friend") &&
                                <>
                                    {friendList.length > 0 ?
                                        <div className="input-popup2">
                                            <label className="pop-label my-2">
                                                Select Friends<span className="start-color">*</span>{" "}
                                                <span className="select-one">(select at least one)</span>
                                            </label>
                                            <div className='text-capitalize'>
                                                <Select
                                                    value={friendData ? friendData : null}
                                                    options={friendList}
                                                    isMulti
                                                    selectMultiple={true}
                                                    onChange={handleFriendsId}
                                                    ref={friendFocus}
                                                />
                                                <span className="error-span">
                                                </span>
                                            </div>
                                            {shareFriend.length <= 0 ? <span className="error-span">
                                                {
                                                    error.friend && error.friend
                                                }
                                            </span> : " "}
                                        </div> : <>
                                            <p style={{ textAlign: "center", fontSize: "18px", paddingTop: "18px" }}>
                                                {" "}
                                                You need to add at least one friend to share a resource.
                                            </p>

                                        </>}
                                </>}
                            {shareData?.includes("group") && <>
                                {groupList.length > 0 ?
                                    <div className="input-popup2">
                                        <label className="pop-label my-2">
                                            Select Group<span className="start-color">*</span>{" "}
                                            <span className="select-one">(select at least one)</span>
                                        </label>
                                        <div className='text-capitalize'>
                                            <Select
                                                value={groupData ? groupData : null}
                                                options={groupList}
                                                isMulti
                                                selectMultiple={true}
                                                onChange={handleGroupId}
                                                ref={groupFocus}
                                            />
                                        </div>
                                        {groupId.length <= 0 ? <span className="error-span">
                                            {
                                                error.group && error.group
                                            }
                                        </span> : " "}
                                    </div>
                                    : <>
                                        <p style={{ textAlign: "center", fontSize: "18px", paddingTop: "18px" }}>
                                            {" "}
                                            You need to have at least one Group to share a resource.
                                        </p>

                                    </>}
                            </>}
                            {
                                (groupData?.length > 0 && type.includes("Admin")) && <ul className="striptableList striptableListHeading">
                                    <li>Group Name</li>
                                    <li>Add To Bulletin Board</li>
                                    <li>Add To Resource</li>
                                </ul>
                            }
                            <ul className="striptableList striptableListInfo">
                                {
                                    groupData?.length > 0 && <>
                                        {groupData?.length > 0 && groupData?.map((val, i) => {
                                            return (
                                                <>
                                                    {
                                                        val.type === "Admin" &&
                                                        <>
                                                            <li><p className="share-with-group">{val.label}</p></li>
                                                            <li><label className="switch mx-3" >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => handleToggleChange(i, e)}
                                                                    checked={val?.check === true ? true : false}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label></li>
                                                            <li>   <label className="switch mx-3" >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => resourceHandleToggleChange(i, e)}
                                                                    checked={val?.resourceCheck === true ? true : false}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label></li>
                                                        </>
                                                    }
                                                </>);
                                        })}
                                    </>
                                }
                            </ul>
                        </div>
                        <div className='my-2'>
                            <div className="save-cancel-btn">
                                <div className="savee">
                                    <button onClick={ShareResource} >Share</button>
                                </div>{"  "}&nbsp;&nbsp;
                                <div className="cancel-butn">
                                    <button onClick={() => props.handleResourceShareClose()}>
                                        Cancel
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div >
            {
                isPopupOpen && <Publishpopup
                    open={isPopupOpen}
                    handleClose={() => setIsPopupOpen(false)}
                    handleYes={chnageState === "announce" ? handleYes : handleResourceYes}
                    handleNo={chnageState === "announce" ? handleNo : handleResourceNo}
                    msg={toggleMessage}
                    value={handleSatate} />
            }
        </>
    )
}
export default ShareRecoursePopUp;