
import React, { useState } from "react";
import "./Writereview.css";
import './Yourgroup.css';
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import {
  decodeToken,
  toastifyMessage,
} from "../../utilities/CustomFunctions";
import Rating from "@mui/material/Rating";

function WriteReview(props) {
  const [comments1, setComments1] = useState("");
  const [parentRating, setParentRating] = useState(0);
  let userData = decodeToken();

  // this functiom handle to get review
  const getreview = () => {
    if (parentRating === 0) {
      toastifyMessage("Enter rating", "error");
    } else {
      let data = {
        "group_id": props.value,
        "user_id": userData.userAuth.id,
        "message": comments1,
        "rating": parentRating
      };
      makePostRequest(URLS.createReview, null, data)
        .then((res) => {
          console.log("res", res);
          if (res.code === 201) {
            toastifyMessage(res.message, "success");
            props.datar();
            let data = {
              "group_id": props.value,
              "page": 1
            };
            makePostRequest(URLS.getReview, null, data)
              .then((res) => {
                if (res.code === 200) {
                  props.rating(res?.data?.data[0])
                } else if (res.code === 400) {
                }
              })
              .catch((error) => {
                console.log(error);
              });

          } else if (res.code === 400) {
            toastifyMessage(res.message, "error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="inviteGpopup">
        <div className="inviteGpopupinner">
          <h1>Write Review</h1>
          <button className="cls_btn" onClick={props.datar}> X </button>
          <div className="leavfeedbackreviews">
            <h4> Rating<span className="start-color">*</span></h4>
            <div className="innerBoxList">
              <ul className="unstyled centered">
                <li>
                  <label htmlFor="styled-checkbox-0">
                    {userData.userAuth
                      .firstName} {" "}
                    <span className="rankingStar mt-2 px-2 py-1">
                      <Rating name="Rating Label0"
                        value={parentRating}
                        onChange={(event, newValue) => {
                          setParentRating(newValue);
                        }}
                      />
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="about-div">
            <h3>Comments</h3>
            <div>
              <textarea
                name="comments"
                value={comments1}
                onChange={(e) => setComments1(e.target.value)}
              ></textarea>
            </div>
          </div>
          <button onClick={getreview} className="reviewWrite">Create</button>
        </div>
      </div>
    </>
  );
}
export default WriteReview;
