import React, { useEffect, useRef } from "react";
import "../Search.WebAppMate/reportpopup.css";
import { useState } from "react";
import { makePostRequestForAll } from "../../services/api";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import FourDotsLoader from "./FourDoted";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
/**
 * create components is used for friend list
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const filter = createFilterOptions();
function InviteFriendpopup(props) {
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [spin, setSpin] = useState(false);
  const [addError, setAddError] = useState();
  const [emailList, setEmailList] = useState([]);
  let userData = decodeToken().userAuth.id;
  let emailFocus;
  const cmntFocus = useRef();
  const [data, setData] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [keywords, setKeywords] = useState([]);
  useEffect(() => {
    setSelectedOption([{ keyword: "", label: "" }]);
  }, [])
  //  this function is use for send friend request to anyone
  const handleSubmit = () => {
    if (emailList.length <= 0) {
      setEmailError("Please enter your friend's email");
      emailFocus.focus();
    }
    else if (data === "") {
      setMessageError("Please enter comment");
      cmntFocus.current.focus();
    }
    else {
      setSpin(true)
      const list = [];
      for (var i = 0; i < emailList.length; i++) {
        list.push({ email: emailList[i] });
      }
      const payLoad = {
        user_id: userData,
        emailList: list,
        message: data,
      };
      makePostRequestForAll(URLS.createAddInviteFriend, payLoad)
        .then((res) => {
          setSpin(false)
          if (res.code === 201) {
            toastifyMessage(res.message, "success");
            props?.datar();
            props?.funcall()
          } else if (res.code === 400) {
            toastifyMessage(res.message, "warn");
            props?.datar()
          } else {
            props?.datar()
          }
        })
        .catch((error) => {
        });
    }
  };
  useEffect(() => {
    const validEmails = [];
    const invalidEmails = [];
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    for (const email of keywords) {
      const trimmedEmail = email?.keyword?.trim() || email;
      if (emailPattern.test(trimmedEmail)) {
        validEmails.push(trimmedEmail);
      } else {
        invalidEmails.push(trimmedEmail);
      }
    }
    if (invalidEmails.length > 0) {
      setAddError('Invalid email address');
    } else {
      setAddError('');
    }
    setEmailList(validEmails);
  }, [keywords]);
  // ----------end multiple value input for emial
  return (
    <>
      <div className="sharepopupOuter">
        {spin && (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        )}
        <div className="pop-up-container InviteFriendInspiration">
          <button className="close-btn" onClick={props.datar}> X </button>
          <h3>Invite Friends to MatchED</h3>
          <div className="kayword-associatedNew">
            <div className="email-text-option"> <label>Friends Email(s)<span className="start-color">*</span></label></div>
            <Autocomplete
              multiple
              value={emailList}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setKeywords({
                    keyword: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setKeywords({
                    keyword: newValue.inputValue,
                  });
                } else {
                  let filteredArray = newValue.filter(item => {
                    if (typeof item === 'string') {
                      return item.trim() !== '';
                    }
                    if (item.hasOwnProperty('label') && typeof item.label === 'string') {
                      return item.label.trim() !== '';
                    }
                    return true;
                  });

                  setKeywords(filteredArray);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.keyword
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    keyword: inputValue,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={selectedOption}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.keyword;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.keyword}</li>
              )}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Email" inputRef={input => {
                  emailFocus = input;
                }} />
              )}
            />
          </div>
          {emailList.length <= 0 ? <span className="error-span">
            {addError ? addError : emailError && emailError}
          </span> : ""}
          <div className="email-text-option">
            <label> Comments<span className="start-color">*</span></label>
            <textarea
              className="form-control"
              name="message"
              ref={cmntFocus}
              onChange={(e) => setData(e.target.value)}
              value={data}
            ></textarea>
            <span className="error">{data === "" ? messageError : ""}</span>
          </div>
          <div className="create-link-btn">
            <button className="sendinvite" onClick={handleSubmit}>
              Send Invite
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default InviteFriendpopup;
