import { Link } from "react-router-dom";
import "./threedotspopup.css";
import React from "react";
// /*
//      * Notification function is used to do view all the notification
//      *
//      * @author Upendra Kumar <upendra.webappmate@gmail.com>
//      * created on: 07-07-2023
//      * /

function Threedotspopup(props) {
  return (
    <>
      <div className="dotspop" ref={props.forwardedref}>
        <Link
          to="#!"
          onClick={props.val}
          className="border-bottom"
        >
          Share permissions
        </Link>
        <Link to="#!" onClick={props.remove} className="border-bottom">
          Remove permissions
        </Link>
        <Link to={`/user-profile/${props?.id}`}>
          View Profile
        </Link>
      </div>
    </>
  );
}
export default Threedotspopup;
