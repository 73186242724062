import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const[showpopup , setshowpopup] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event)=>{setAnchorEl(event.currentTarget);setshowpopup(true)}}
      >
        <MoreVertIcon />
      </IconButton>
      {showpopup && (
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={()=>{setAnchorEl(null);setshowpopup(true)}}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        onClick={()=>setshowpopup(false)}
      >
      <MenuItem key={"1"} onClick={()=> {
          props.handleResourceShareOpen(props.data)
          } }>Share</MenuItem>
        <MenuItem key={"1"} onClick={()=> {
          props.handleResourceSaveOpen(props.data)
          } }>Save</MenuItem>
        <MenuItem key={"3"} onClick={()=>{
          props.handleResourceReportOpen(props.data)
          }}>Report</MenuItem>
      </Menu>
     )}
    </div>
  );
}
