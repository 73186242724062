import "./saveresourcespopup.css";
import React, { useState, useEffect } from "react";
import { URLS } from "../../constants";
import "./reportpopup.css";
import { makePostRequest, makeGetRequest } from "../../services/api";
import { useSelector } from "react-redux";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { style,toastifyMessage } from "../../utilities/CustomFunctions";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
/**
 * created components is used for save resource for student
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function Saveresourcespopup(props) {
  let navigate = useNavigate();
  let my_token = localStorage.getItem("auth-token");
  const headers = { Authorization: "Bearer " + my_token };
  const [shareWithFriend, setShareWithFriend] = useState("yes");
  const [spin, setSpin] = useState(false);
  const [show, setShow] = useState(false);
  const [grades, setGrades] = useState([]);
  const [selectedChildId, setSelectedChildId] = useState([]);
  const [childResponses, setChildResponses] = useState([]);
  const [commanError, setCommanError] = useState("");
  const [gradeError, setGradeError] = useState(false);
  const [childs, setchilds] = useState([]);
  const [alertMessage, setAlertMessage] = useState("")
  const [open, setOpen] = useState(false)
  let state = useSelector((state) => state?.user?.userChildrens)
  const [error, setError] = useState({
    childIdErr: "",
    saveForErr: "",
    GradeErr: "",
  });

  useEffect(() => {
    const data = state?.filter(obj => obj.isAdmin === 1 || obj.childOwner === 1)
      .map(({ id, firstName, lastName }) => ({ id: id, name: firstName + " " + lastName }))
    setchilds(data)
  }, [state])

  // This function is used to get child and grade
  useEffect(() => {
    const getAllGrades = () => {
      makeGetRequest(URLS.usergetallgradelevel, {}, { headers }).then(
        (response) => {
          setGrades([{ id: "all", name: "All" }, ...response.data]);
        }
      );
    };
    getAllGrades();
    // eslint-disable-next-line
  }, []);

  //  this function use for handle save resource
  const handleSaveResource = () => {
    const updatedArray = childResponses.map((item) => ({
      ...item,
      purchase_id: props?.data?.selectedResource?.resourcesPurchase?.id,
      viewable_by_my_friends: shareWithFriend,
    }));
    var err;
    if (selectedChildId.length <= 0) {
      setError({ ...error, childIdErr: "Please select student." });
    }
    else if (updatedArray) {
      for (var i = 0; i < updatedArray.length; i++) {
        if (updatedArray[i]?.grade <= 0) {
          err = true;
        } else if (updatedArray[i]?.resource_for === "") {
          err = true;
        } else {
          err = false;
        }
      }
      setCommanError(err)
      if (err === false) {
        makeGetRequest(URLS.getUserSubscriptionPlan, null, null, null)
          .then((res) => {
            console.log("res::", res)
            if (res.code === 200) {
              if (res?.data?.data?.tbl_subscription_purchase_history?.space_used >= res?.data?.data?.tbl_subscription_purchase_history?.space_allocated &&
                res?.data?.data?.tbl_subscription_purchase_history
                  ?.tbl_subscription_plan?.search_type === "limited") {
                setOpen(true)
                setAlertMessage("You have used all your space allocation.")
              }
              else if (res?.data?.data?.tbl_subscription_purchase_history?.space_used / parseInt(res?.data?.data?.tbl_subscription_purchase_history?.space_allocated) * 100 >= 90 &&
                res?.data?.data?.tbl_subscription_purchase_history
                  ?.tbl_subscription_plan?.search_type === "limited") {
                setOpen(true)
                setAlertMessage("You have used 90% of your space allocation.")
              } else {
                setSpin(true)
                makePostRequest(URLS.saveResource, null, updatedArray, null).then((res) => {
                  console.log("saveResource", res)
                  toastifyMessage(res.message, "success");
                  props.handleResourceSaveClose();
                  if (props?.data?.id && props?.ps) {
                    navigate(`/create-schedule/?resource=${props?.data?.id}`);
                  } else if (props?.data?.id) {
                    navigate("/save-resources-list");
                  }
                  setSpin(false)
                }).catch((error) => {
                  console.log("error", error)
                });
              }
            } else {
              if (res.code === 400) {
                toastifyMessage(res.message, "error");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  //  this function use for handle inputs
  const handleGradeSelect = (childId, selectedGrades) => {
    const childResponse = childResponses.find((response) => response.child_id === childId);
    if (childResponse) {
      if (selectedGrades.some((item) => item.value === "all")) {
        childResponse.grade_id = ["all"];
      } else {
        childResponse.grade_id = selectedGrades.map((item) => item.value);
      }
    }
    setChildResponses([...childResponses]);
  };

  // this function use for save for input
  const onSaveForSelect = (childId, selectedValue) => {
    const childResponse = childResponses.find((response) => response.child_id === childId);
    if (childResponse) {
      childResponse.resource_for = selectedValue;
    }
    setChildResponses([...childResponses]);
  };

  // this function use for select chils and manage 
  const handleChildSelect = (e) => {
    const selectedChildIds = e.map((item) => item.value);
    setSelectedChildId(selectedChildIds)
    const updatedChildResponses = [];
    selectedChildIds.forEach((childId) => {
      const existingResponseIndex = childResponses.findIndex((response) => response.child_id === childId);
      if (existingResponseIndex !== -1) {
        updatedChildResponses.push(childResponses[existingResponseIndex]);
      } else {
        const newChildResponse = {
          resource_id: props?.data?.selectedResource?.objectID
            ? props?.data?.selectedResource?.objectID
            : props?.data?.selectedResource?.resource_id || props?.data?.selectedResource?.id || props?.data?.id,
          grade_id: [],
          child_id: childId,
          name: e.find((item) => item.value === childId)?.label || "",
          resource_for: ""
        };
        updatedChildResponses.push(newChildResponse);
      }
    });
    setChildResponses(updatedChildResponses);
    if (selectedChildIds.length > 0) {
      setShow(true);
    }
  }

  return (
    <>
      <div className="sharepopupOuter">
        {spin && (
          <div className="sharepopupOuter backgroundOpacity1 fixedOuter">
            <FourDotsLoader />
          </div>
        )}
        <div className="pop-up-container saveresourcePopupLarge">
          <span
            className="close-btn"
            onClick={() => props.handleResourceSaveClose()}
          >
            <i className="fa fa-close closecs"></i>
          </span>
          <h3> Save Resource </h3>
          <div className="savepopup-content">
            {childs.length > 0 ?
              <div className="input-popup1">
                <label className="pop-child">
                  Who is it for? <span className="start-color">*</span>{" "}
                  <span className="select-one">(select at least one)</span>
                </label>
                {childs && (
                  <div className="save-popup-top">
                    <Select
                      options={childs.map((val) => ({ value: val.id, label: val.name }))}
                      isMulti
                      selectMultiple={true}
                      onChange={handleChildSelect}
                    />
                  </div>
                )}
              </div> : <>
                <p style={{ textAlign: "center", fontSize: "23px" }}>
                  {" "}
                  You need to have at least one student's profile to save a resource. Please click <b>continue </b>and add a student's profile.
                </p>
                <div className="popup-btn-div">
                  <button
                    onClick={() => navigate("/parent-registration-form")}
                  >
                    Continue
                  </button>
                </div>
              </>}
            {selectedChildId.length <= 0 && error.childIdErr && (
              <p className="error text-danger" style={{ marginLeft: "15px" }}>
                {error.childIdErr}
              </p>
            )}
            {show === true ?
              <div>
                {childResponses &&
                  childResponses.map((val, index) => {
                    return (
                      <>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-popup3">
                              <label className="pop-grade">
                                {val.name}'s Grade Level<span className="start-color"> *</span>{" "}
                                <span className="select-one">(select at least one)</span>
                              </label>
                              {grades && (
                                <Select
                                  isMulti
                                  value={grades.filter((grade) => val.grade_id.includes(grade.id)).map(grade => ({ value: grade.id, label: grade.name }))}
                                  options={grades.map(grade => ({ value: grade.id, label: grade.name }))}
                                  onChange={(selectedItems) => {
                                    let arr = [];
                                    selectedItems.length > 0 &&
                                      selectedItems.forEach((val, i) => {
                                        arr.push(val.value);
                                      });
                                    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
                                      selectedItems = [{ value: "all", label: "All" }];
                                      handleGradeSelect(val?.child_id, selectedItems);
                                    }
                                    else {
                                      setGradeError(false)
                                      handleGradeSelect(val?.child_id, selectedItems.filter(item => item.value !== "all"));
                                    }
                                  }}
                                  placeholder="Select Grades"
                                />
                              )}
                              {commanError && val?.grade_id.length <= 0 && (
                                <p className="error text-danger">
                                  Please select grade.
                                </p>
                              )}
                              <p className="error text-danger">
                                {gradeError && val?.grade_id.includes("all") && "Note: Now you can not to select any more grade"}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-popup2">
                              <label className="pop-child">
                                {val.name}'s  Save For <span className="start-color">*</span>{" "}
                                <span className="select-one">(select one)</span>
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  onSaveForSelect(val?.child_id, e.target.value);
                                }}
                                value={val?.resource_for}
                              >
                                <option value="">Select Save For</option>
                                <option value="In progress">In Progress</option>
                                <option value="Past">Past</option>
                                <option value="For the future">Future</option>
                              </select>
                              {commanError && val?.resource_for === "" && (
                                <p className="error text-danger">
                                  Please select save for.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })} </div> : ""}
            {childs.length > 0 ? <>
              <div className="viewableFriend pt-2">
                <h5>
                  Viewable by My Friends
                  <label className="switch" htmlFor="viewable-friend">
                    {shareWithFriend ===
                      "no" ? (
                      <input
                        type="checkbox"
                        id="viewable-friend"
                        onClick={(e) => setShareWithFriend("yes")}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        defaultChecked
                        id="viewable-friend"
                        onClick={(e) => setShareWithFriend("no")}
                      />
                    )}
                    <span className="slider round"></span>
                  </label>
                </h5>

              </div>
              <div className="popup-btn-div">
                <button onClick={handleSaveResource}>save</button>
              </div>
            </> : ""}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            {
              alertMessage && alertMessage
            }

          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >

          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Saveresourcespopup;
