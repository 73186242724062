import React from "react";
import "./fourdoted.css";
import { useState } from "react";
const FourDotsLoader = (props) => {
  const [text] = useState("We are currently in the process of searching for relevant resources. Please stand by.")
  return (
    <>
      <div className="overflowloader" id="preload">
        {props.text === true || props.state === true ? <h1 className="loading"
          style={{ fontStyle: "normal", position: "absolute", top: "-60px", width: "100%", textAlign: "center" }}
        >{text}</h1> : ""}
        <div>
          {props.message === true ? <span
            style={{ textAlign: "center", display: "block", width: "100%", position: "absolute", top: "35px", zIndex: "99999", color: "#000", fontSize: "20px", fontWeight: "bold", verticalAlign: "middle" }}
          >{text}</span> : ""}</div>
        <div className="circle-square">
          <div className="red" style={{ background: "#AAC0CE" }}></div>
          <div className="blue" style={{ background: "#265472" }}></div>
          <div className="green" style={{ background: "#F19357" }}></div>
          <div className="yellow" style={{ background: "#F0B285" }}></div>
        </div>
      </div>
    </>
  );
};
export default FourDotsLoader;
