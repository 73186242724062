import { useEffect, useState } from "react";
import "../CreateGroup.WebAppMate/joingrouppopup.css";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { toastifyMessage,Emailvalidation } from "../../utilities/CustomFunctions";
import FourDotsLoader from "./FourDoted";
import SquareArrow from "../../assets/Images/squarearrow.webp";
/**
 * create component use for stripe account
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 31-10-2023
 */
function StripePopup(props) {
    const [value, setValue] = useState("old");
    const [newAccount, setNewAccount] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [data, setData] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [spin, setSpin] = useState(false);
    const [cuurent, setCurrent] = useState("")


    useEffect(() => {
        var currentValue;
        if (props?.current) {
            currentValue = props?.data?.find(item => item?.id === props?.current)
            setCurrent(currentValue)
            setAccountId(currentValue)
        }
        const filteredArray = props?.data.filter(item1 => ![cuurent].some(item2 => item1?.value === item2?.value));
        filteredArray?.length <= 0 && setValue("new")
        if (cuurent) {
            setData([...[cuurent], ...filteredArray])
        }
        else {
            setData(filteredArray)
        }
        // eslint-disable-next-line
    }, [props?.data])

    // this function use for validation
    const validation = () => {
        if (value === "old" && accountId === "") {
            setIsValid(true)
        }
        else if (value === "old" && accountId !== "") {
            saveAccountDetail()
        }
        if (value === "new" && newAccount === "") {
            setIsValid(true)
        } else if (value === "new" && newAccount !== "" && Emailvalidation.test(newAccount) === false) {
            setIsValid(true)
        } else if (value === "new" && newAccount !== "" && Emailvalidation.test(newAccount) === true) {
            saveAccountDetail()
        }
    }

    // this function use for account detail
    const saveAccountDetail = () => {
        if (props?.user) {
            const data = {
                "id": value === "old" ? accountId?.value : "",
                "email": value === "new" ? newAccount : ""
            }
            setSpin(true)
            makePostRequest(URLS.userAccountAdd, null, data, null)
                .then((res) => {
                    setSpin(false)
                    if (res?.code === 200) {
                        setCurrent(accountId)
                        if (res?.data === "stripeAccountID") {
                            toastifyMessage(res.message, "success")
                        } else {
                            setSpin(false)
                            toastifyMessage(res.message, "success")
                            window.open(res?.data?.url, '_blank')
                        }
                    } else {
                        toastifyMessage(res?.message, "error");
                    }
                }).catch((error) => {
                    setSpin(false)
                    console.log("error::", error);
                })

        } else {
            const data = {
                "group_id": props?.groupId,
                "id": value === "old" ? accountId?.value : "",
                "email": value === "new" ? newAccount : ""
            }
            setSpin(true)
            makePostRequest(URLS.groupAccountAdd, null, data, null)
                .then((res) => {
                    console.log("res2::::", res)
                    if (res?.code === 200) {
                        toastifyMessage(res.message, "success")
                        setSpin(false)
                        window.open(res?.data?.url, '_blank')
                    } else {
                        toastifyMessage(res?.message, "error");
                    }
                }).catch((error) => {
                    setSpin(false)
                    console.log("error::", error);
                })
        }

    }

    // this function handle link
    const GotoLink = (id) => {
        setSpin(true)
        const data = {
            "id": id
        }
        makePostRequest(URLS.StripeAccountLink, null, data, null)
            .then((res) => {
                console.log("res:::", res)
                setSpin(false)
                if (res.code === 200) {
                    window.open(res?.data?.url, '_blank')
                } else {
                    toastifyMessage(res.message, "error")
                }

            }).catch((error) => {
                console.log("error::", error);
            })
    }

    return (
        <>
            <div className="Joingroup2_div">
                {spin && (
                    <div className="sharepopupOuter backgroundOpacity1">
                        <FourDotsLoader />
                    </div>
                )}
                <div className="Joingroup2_mid_div">
                    <h1 className="join_grp">Stripe Account Setup</h1>
                    <button className="cross_btn" onClick={props.datar}>
                        X
                    </button>
                    <div className="strip-paragraph">
                        <p>{cuurent?.value && "Payments can take up to 5 days to be received in your account."}</p>
                    </div>
                    <div className="strip-header">
                        <h2>{cuurent?.value && <>You are currently receiving payments to: <span> {cuurent?.label}</span></>}</h2>
                    </div>
                    <div className="radio_btn_div">
                        {data?.length > 0 && <label className="strip-popup">
                            <input type="radio" id="html" name="fav_language" value={value} checked={value === "old" ? true : false} className="radio_button" onChange={(e) => setValue("old")} />
                            <label htmlFor="html" className="subgroup">Switch to an Existing Account</label></label>}
                        <label className="strip-popup">
                            <input type="radio" id="htmls" name="fav_language" value={value} checked={value === "new" ? true : false} className="radio_button" onChange={(e) => setValue("new")} />
                            <label htmlFor="htmls" className="subgroup">Link/Create New Account</label></label>
                    </div>
                    {
                        (value === "old" && data.length > 0) &&
                        <>
                            <div className="stipe-account">
                                <ul className="striptableList">
                                    <li>Email</li>
                                    <li>Payout</li>
                                    <li>Account Status</li>
                                </ul>
                                {console.log("accountId", accountId)}
                                <ul className="striptableList striptableListloop">
                                    {
                                        data.length > 0 && data.map((val, index) => {
                                            return <>
                                                <li key={index}>
                                                    <input className="tableradio" type="radio" value={val.value} checked={accountId.value === val.value ? true : false} name="email" onChange={() => setAccountId(val)} />
                                                    <p className="share-with-group m-0">{val.label}</p>
                                                    <img src={SquareArrow} alt="Square" width="20" className="cursor-pointer" onClick={() => {
                                                        GotoLink(val.value)
                                                    }} />
                                                </li>
                                                <li>
                                                    <p className="share-with-group mx-4" >{val.payouts_enabled ? "Enabled" : "Disabled"}
                                                    </p>
                                                </li>
                                                <li><p className="share-with-group mx-4">{val.charges_enabled ? "Enabled" : "Disabled"}</p></li>
                                            </>
                                        })
                                    }
                                </ul>
                            </div></>
                    }  <span className="error-span">{(isValid && value === "old" && accountId === "") && "Please select email."}</span>
                    {
                        (value === "new" || data.length <= 0) && <div className="share-group py-3">
                            <label className="inviteGpopupinnerlabel">Setup new Account</label ><br />
                            <input
                                type="email"
                                className="form-control"
                                id="inputAddress"
                                name="emailList"
                                onChange={(e) => setNewAccount(e.target.value)}
                                value={newAccount}
                                placeholder="Enter Email Id"
                            />
                            <span className="error-span">{(isValid && value === "new" && !newAccount) && "Please enter email."}</span>
                            <span className="error-span">{((isValid && value === "new") && (newAccount && !Emailvalidation.test(newAccount))) && "please enter valid email"}</span>
                        </div>
                    }
                    <div className="bton_div">
                        <button className="bton2" onClick={validation}>
                            <span className="bton2_texts"  >Save</span>
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
}
export default StripePopup;
