import React, { useEffect } from "react";
import { Link } from "react-router-dom";


const PageNotFound = () => {
  useEffect(() => {
    document.body.classList.add("errorpage");
    return () => {
      document.body.classList.remove("errorpage");
    };
  }, []);
  return (
    <>
      <div className="sks four-o-four col-lg-10">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-lg-6 col-md-8 col-sm-10 col-12 box-pack text-center align-self-center">
            <div className="innernotfoundcontainer">
              <div className="large-text">404</div>
              <h3 className="my-3">Oops! Page not found</h3>
              <p>
                We are sorry but the page you are looking for does not
                exist.
              </p>
              <Link to="/" className="btn btn-primary btn-spl btn-sm mt-4 mb-5" >
                Go to Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
