import React from 'react'
import { Link } from 'react-router-dom'
import { toSeoUrl } from '../../services/common.services';
// /*
//      * Notification function is used to do view all the notification
//      *
//      * @author krishna dobariya <krishna.webappmate@gmail.com>
//      * created on: 1-08-2023
//      * /
const ThreeDotsPopupForGroup = (props) => {

    return (
        <>
            <div className="dotspop" ref={props.forwardedref}>
                <Link
                    to={`/groupjoin/${props?.id.toString()}/${toSeoUrl(props?.name)}`} className="border-bottom">
                    View
                </Link>
                <Link to={`/group-members/${props?.id}`}>
                    Manage Group
                </Link>
            </div>
        </>
    )
}
export default ThreeDotsPopupForGroup;