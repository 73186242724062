import React from "react";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";

function Childsprofilepopup(props) {

  // this function is use for invite friends
  const InviteFriend = () => {
    const data = {
      "friend_id": props?.friendId
    }
    makePostRequestForAll(URLS.friendInvite, data)
      .then((res) => {
        if (res.code === 201) {
          toastifyMessage(res.message, "success");
          props?.funCall();
        } else {
          toastifyMessage(res.message, "warn");
        }
      })
  }

  return (
    <>
      <div className="child-profile-popupBg">
        <div className="child-profile-popup">
          <h3> Request to be friends to view saved resources </h3>
          {props?.pending !== "pending" ? <button onClick={InviteFriend}>Invite as a Friend</button> : ""}
        </div>
      </div>
    </>)
}
export default Childsprofilepopup;