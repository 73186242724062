import React, { useCallback, useEffect, useState } from 'react'
import { URLS } from '../../constants'
import { makePostRequest, makePostRequestForAll } from '../../services/api'
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import DeleteIcon from "@mui/icons-material/Delete";
import { decodeToken, toastifyMessage } from '../../utilities/CustomFunctions';

const CommentsPopup = (props) => {
    let user_id = decodeToken().userAuth.id;
    const [comments, setComments] = useState("");
    const [spin, setSpin] = useState(false)
    const seeMoreComment = useCallback(() => {
        setSpin(true)
        const data = {
            resource_id: props.handleCommets,
        }
        makePostRequestForAll(URLS.getSharedResourceComment, data).then(res => {
            console.log("res::", res)
            setComments(res.data);
            setSpin(false)
        }).catch(e => {
            console.log(e)
        })
    },[props.handleCommets])
    useEffect(() => {
        props.handleCommets && seeMoreComment()
    }, [props.handleCommets,seeMoreComment])

    // this function is use for delete comments
    const deleteComments = (id) => {
        makePostRequest(URLS.deleteCommets, null, { "id": id }, null)
            .then((res) => {
                console.log("res", res)
                toastifyMessage(res.message, "success")
                seeMoreComment()
            }).catch((error) => {
                console.log("error::", error)
            })
    }

    return (
        <div>
            <div className="sharepopupOuter">
                {spin && (
                    <div className="sharepopupOuter backgroundOpacity1">
                        <FourDotsLoader />
                    </div>
                )}
                <div className="pop-up-container " >
                    <button className="close-btn" onClick={() => props.handleResourceCommentsClose()}>
                        <i className="fa fa-close closecs"></i>
                    </button>
                    <h3>Comments</h3>
                    <div className="input-popup1" style={{ maxHeight: " 380px", overflow: "hidden", overflowY: "auto", paddingRight: "10px" }}>
                        {comments.length > 0 ? comments?.map(comment => {
                            return <div className="comment-div-box">
                                <div className="imagejohanadiv">
                                    <img className="img-fluid" src={comment?.photo} alt="MatchED user" />
                                </div>
                                <div className="joana-comments">
                                    <span style={{ fontWeight: "600", color: "black" }} className='text_capital'>{comment?.name}</span>
                                    <span>{comment?.comment?.trim()}</span>
                                    {
                                        comment?.user_id === user_id && <strong className="delete-massages" onClick={() => deleteComments(comment?.id)}>
                                            <DeleteIcon />
                                        </strong>
                                    }
                                </div>
                            </div>
                        }) : <h3>There are no comments found</h3>}
                    </div>
                </div>
            </div></div>
    )
}

export default CommentsPopup