import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { URLS } from "../../constants";
import { toastifyMessage, style } from "../../utilities/CustomFunctions";
import { makePostRequest } from "../../services/api";
import { useState } from "react";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

const DeleteTicket = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  // this function use for delete ticket
  const deleteTicket = () => {
    setIsLoading(true)
    makePostRequest(URLS.deleteTicket, null, { "ticket_id": props?.id }, null)
      .then((res) => {
        setIsLoading(false)
        toastifyMessage(res?.message, "success");
        props?.getTicketList();
        props.handleClose()
      }).catch((error) => {
        console.log("error::", error)
      })
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            isLoading && <div className="sharepopupOuter backgroundOpacity1">
              <FourDotsLoader />
            </div>
          }
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to delete this ticket?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={deleteTicket}>
              Yes
            </button>{" "}
            <button className="child-delete" onClick={props.handleClose}>
              Cancel
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default DeleteTicket;
