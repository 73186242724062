import React, { useRef } from "react";
import { useEffect, useState } from "react";
import './ticketlistpage.css';
import './createticketpopup.css';
import sortImg from "../../assets/Images/sort.png";
import { URLS } from "../../constants";
import moment from 'moment';
import { Link } from "react-router-dom";
import { makePostRequest, makeFormDateRequset } from "../../services/api";
import {
  toastifyMessage,
} from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import DeleteTicket from "./DeleteTicket";
import Head from "../Layout.WebAppMate/head/Head";

/**
 * create components is used for ticketlisting
 *  @author Naresh Kumar <naresh.gulati@webappmate.com>
 * created on: 06-11-2023
 * chnages by
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const TicketListPage = () => {
  const [ticketList, setTicketList] = useState([]);
  const [close, setclose] = useState(false);
  const [subject, setSubject] = useState("");
  const [comment, setComment] = useState("");
  const [thumbnailImgName, setThumbnailImgName] = useState([]);
  const [uploadArray, setUploadArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [ticketId, setTicketId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("")
  const subjectInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const [sort, setSort] = useState(false)
  const [sort1, setSort1] = useState(false)
  const [sort2, setSort2] = useState(false)
  const [dummyData, setDummyData] = useState([])

  useEffect(() => {
    document.body.classList.add("Ticket-page");
    return () => {
      document.body.classList.remove("Ticket-page");
    };
  }, []);

  // this function use for ticket listing
  const getTicketList = () => {
    setIsLoading(true)
    let data = {
      "ticket_type": "",
      "sort_order": "desc",
    }
    makePostRequest(URLS.getTickets, null, data, null).then((res) => {
      setIsLoading(false)
      setTicketList(res.data.data);
      setDummyData(res.data.data)
    }).catch((error) => {
      console.log("error:", error)
    });
  }
  //this function use for validation
  const validatefiled = () => {
    const errors = {};
    if (subject === "") {
      errors.subject = "Please enter subject.";
    }
    if (comment === "") {
      errors.comment = "Please enter comment.";
    }
    if (subject === "") {
      subjectInputRef.current.focus();
    } else if (comment === "") {
      commentInputRef.current.focus();
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };
  // this function use for add ticket
  const handleAddTickets = () => {
    let data = {
      "subject": subject,
      "comment": comment,
      "uploads": uploadArray ? uploadArray : [],
    }
    if (validatefiled()) {
      setIsLoading(true)
      makePostRequest(URLS.addTicket, null, data, null).then((res) => {
        if (res.code === 200) {
          setIsLoading(false)
          toastifyMessage(res.message, "success");
          setclose(false);
          setSubject("")
          setComment("")
          setUploadArray([])
          getTicketList();
        }
        else {
          toastifyMessage(res.message, "error");
        }
      });
    }
  }
  // this function use for handle function
  const handleThumbnail = (e) => {
    const validExtensions = ["png", "jpg", "jpeg"];
    let file = e?.target?.files[0];
    const fileExtension = file?.type?.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      toastifyMessage("Only png, jpg, jpeg are allowed", "error");
      return;
    }
    const MAX_FILE_SIZE = 2048
    const fileSizeKiloBytes = file.size / 1024
    if (fileSizeKiloBytes <= MAX_FILE_SIZE) {
      handleAddAttachment(file);
    }
    else {
      toastifyMessage("Only 2 MB  file size is allowed", "error");
      setThumbnailImgName("");
    }
  };
  // this function use for add doc
  const handleAddAttachment = (file) => {
    setIsLoading(true)
    let data = new FormData();
    data.append("filename", file);
    makeFormDateRequset(URLS.addTicketAttachments, data).then(
      (res) => {
        if (res.code === 200) {
          toastifyMessage(res?.data?.message, "success");
          setIsLoading(false)
          setThumbnailImgName([...thumbnailImgName, res?.data?.data?.upload])
          uploadArray.push(res?.data?.data?.upload?.token);
          setUploadArray(uploadArray);
        }
        else {
          toastifyMessage("Something went wrong", "error");
        }
      }
    );
  }

  useEffect(() => {
    if (type === "new") {
      setTicketList(dummyData.filter(ticket => ticket.status === "new"))
    } else if (type === "open") {
      setTicketList(dummyData.filter(ticket => ticket.status === "open"))
    }
    else if (type === "pending") {
      setTicketList(dummyData.filter(ticket => ticket.status === "pending"))
    }
    else if (type === "solved") {
      setTicketList(dummyData.filter(ticket => ticket.status === "solved"))
    } else {
      getTicketList()
    }
    // eslint-disable-next-line
  }, [type]);

  // this function use for doc delete
  const deleteAttachment = (id) => {
    setIsLoading(true)
    makePostRequest(URLS.deleteAttachment, null, { "token": id }, null)
      .then((res) => {
        setIsLoading(false)
        toastifyMessage(res?.data?.message, "success");
        setThumbnailImgName(thumbnailImgName.filter(obj => obj.token !== id))
        setUploadArray(uploadArray.filter(obj => obj !== id))
      }).catch((error) => {
        console.log("error::", error)
      })
  }
// these funcations use for sorting
  const sortTicketsByStatus = () => {
    if (sort) {
      const sortedTickets = [...ticketList].sort((a, b) => a.status.localeCompare(b.status));
      setTicketList(sortedTickets);
    } else {
      const sortedTickets = [...ticketList].sort((a, b) => b.status.localeCompare(a.status));
      setTicketList(sortedTickets);
    }
  };
  const sortTicketsByidDate = () => {
    if (sort1) {
      const sortedTickets = [...ticketList].sort((a, b) => b.id - a.id);
      setTicketList(sortedTickets);

    } else {
      const sortedTickets = [...ticketList].sort((a, b) => a.id - b.id);
      setTicketList(sortedTickets);

    }

  }
  const sortTicketsBySubject = () => {
    if (sort2) {
      const sortedTickets = [...ticketList].sort((a, b) => a.subject.localeCompare(b.subject));
      setTicketList(sortedTickets);
    } else {
      const sortedTickets = [...ticketList].sort((a, b) => b.subject.localeCompare(a.subject));
      setTicketList(sortedTickets);
    }

  }
  return (<>

    <div className="col-lg-10 col-md-12 col-sm-12">
      <div className="container">
        {
          isLoading && <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        }
        <Head
          title="Ticket List"
          content="Zendesk Ticket"
          name="Zendesk Ticket" ></Head>
        <div className="col-12">
          <div className="ticket-filter-page">
            <div className="ticket-page-inner">
              <div className="ticket-page-head">
                <h3>Ticket List</h3>
              </div>
              <div className="filter-inner">
                <select className="form-select" onChange={(e) => setType(e.target.value)} value={type}>
                  <option value="">Status</option>
                  <option value="solved">Close</option>
                  <option value="new">New</option>
                  <option value="pending">Pending</option>
                  <option value="open">Open</option>
                </select>
                <div className="filter-inner-button">
                  <button onClick={() => setclose(true)}>Create Ticket</button>
                </div>
              </div>
            </div>
            <div className="ticket-filer-content">
              <div className="ticket-filter-inner-content">
                <div className="table-div">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>ID <button className="ticketSortbutton" onClick={() => { sortTicketsByidDate(); setSort1(!sort1) }}>
                          <img width="10px" src={sortImg} alt="sort" /></button></th>
                        <th style={{ width: "23%" }}>Request Date <button className="ticketSortbutton" onClick={() => { sortTicketsByidDate(); setSort1(!sort1) }}>
                          <img width="10px" src={sortImg} alt="sort" /></button></th>
                        <th style={{ width: "22%" }}>Subject <button className="ticketSortbutton" onClick={() => { sortTicketsBySubject(); setSort2(!sort2) }}>
                          <img width="10px" src={sortImg} alt="sort" /></button></th>
                        <th style={{ width: "22%" }}>Ticket Status
                          <button className="ticketSortbutton" onClick={() => { sortTicketsByStatus(); setSort(!sort) }}>
                            <img width="10px" src={sortImg} alt="sort" /></button></th>
                        <th style={{ width: "22%" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketList?.length > 0 ? (
                        ticketList?.map((item, index) => (
                          <tr key={index}>
                            <td style={{ width: "10%" }}>#{item.id}</td>
                            <td style={{ width: "23%" }}>{moment(item.created_at).format('MM-DD-YYYY')}</td>
                            <td style={{ width: "22%" }}>{item.subject}</td>
                            <td style={{ width: "22%" }}>
                              {item.status === "open" ? (
                                <div className="open-div-tacket">
                                  <button>Open</button>
                                </div>
                              ) : item.status === "new" ? (
                                <div className="new-div-tacket">
                                  <button>New</button>
                                </div>
                              ) : item.status === "pending" ? (
                                <div className="pending-div-tacket">
                                  <button>pending</button>
                                </div>
                              ) : (
                                <div className="close-div-tacket">
                                  <button>Close</button>
                                </div>
                              )}
                            </td>
                            <td style={{ width: "22%" }}>
                              <div className="icon-tacket-list">
                                <Link to="/ticket-detail-page" state={{ ticketid: item.id }}>
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                </Link>
                                <i
                                  className="fa fa-trash cursor-pointer"
                                  aria-hidden="true"
                                  onClick={() => {
                                    setOpenModal(true);
                                    setTicketId(item.id);
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            <h1>No data found</h1>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {close &&
      <div className="ticket-popup" id="myticketbox">
        {
          isLoading && <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        }
        <div className="">
          <div className="createticketpopup">
            <div className="pop-up-container">
              <button className="close-btn" id="ticketClose" onClick={() => { setSubject(""); setComment(""); setThumbnailImgName([]); setUploadArray([]); setclose(false) }}>
                <i className="fa fa-close closecs"></i>
              </button>
              <h3> Create Ticket</h3>
            </div>
            <div className="ticket-list-popup">
              <div className="ticket-list-popupScroll">
                <div className="ticket-subject">
                  <label>Subject<span className="start-color">*</span></label>
                  <input type="text" onChange={(e) => setSubject(e.target.value)} value={subject} ref={subjectInputRef} />
                  <span className="error mt-3">{error?.subject && error?.subject}</span>
                </div>

                <div className="ticket-comments">
                  <label>Comments<span className="start-color">*</span></label>
                  <textarea type="text" onChange={(e) => setComment(e.target.value)} value={comment} ref={commentInputRef} />
                  <span className="error mt-3">{error?.comment && error?.comment}</span>
                </div>
                <div className="ticket-upload-page">
                  <label>Upload files</label>
                  <div className="upload-file-flex">
                    <div className="upload-image-ticket">
                      <div className="ticket-thumbuploadImgz">
                        <section className="ticket-upload-scroll">
                          <span className="ticket-u-content">
                            <i className="fa fa-cloud-upload uploadicon"></i>
                            <span> Upload Images</span>
                          </span>
                          <input
                            type="file"
                            placeholder=""
                            multiple
                            className="input-file-upload"
                            onChange={handleThumbnail}
                          />
                        </section>
                      </div>
                      <span className="error mt-3">{error?.upload && error?.upload}</span>
                      {
                        thumbnailImgName?.length > 0 && thumbnailImgName?.map((val, index) => {
                          return <>
                            <div className="duplod1 p-1">
                              <h4 className="dp float-start">
                                <span className="one">{index + 1}</span>
                                {val?.attachments[0]?.content_url?.split("/")?.pop()?.replace(/\?/g, '')}
                              </h4>
                              <div className="float-end">
                                <button
                                  className="btn btn-md px-1 border-0"
                                  onClick={() => {
                                    deleteAttachment(val?.token);
                                  }}
                                >
                                  <span className="ed">Remove</span>
                                </button>
                              </div>
                            </div>
                          </>
                        })

                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="ticket-list-pupup-submit">
                <button onClick={handleAddTickets}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <DeleteTicket open={openModal} handleClose={() => setOpenModal(false)} id={ticketId} getTicketList={getTicketList} />

  </>);
}
export default TicketListPage;
