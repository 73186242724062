import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select'
import { makeGetRequest, makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { getGroupPandingList } from "../../store/reducers/userReducer";
const URL = process.env.REACT_APP_URL;
/**
 * create components is used for share resource
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 27-07-2023
 */
const filter = createFilterOptions();
function InviteGroups(props) {

  const userId = decodeToken().userAuth.id;
  const [value, setValue] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [shareFriend, setShareFriends] = useState([]);
  const [friendData, setFriendData] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [spin, setSpin] = useState(false)
  const [emailList, setEmailList] = useState([]);
  const [error, setError] = useState({});
  const [copied, setCopied] = useState(false);
  const frndFocus = useRef(null);
  const grpFocus = useRef(null);
  const [addError, setAddError] = useState();
  const [keywords, setKeywords] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  let emailFocus;

  //   this function is use for copy text
  const handleCopyClick = (textToCopy) => {
    // const id = btoa(CryptoJS.AES.encrypt(textToCopy.toString(), secretKey).toString())
    navigator.clipboard.writeText(`${URL}/groupjoin/${textToCopy}`)
      .then(() => {
        setCopied(true);
      })
      .catch(error => {
        console.error('Error copying text:', error);
      });
  };

  // this function is use for friend & group list
  useEffect(() => {
    makePostRequest(URLS.getGroupList, null, {}, null)
      .then((res) => {
        var arr = [];
        res?.data?.myGroups?.data?.length > 0 && arr.push({ label: "All", value: "all" })
        res?.data?.myGroups?.data?.forEach((val, index) => {
          if (val.id !== props.value) {
            return arr.push({ label: val?.group_name + " (" + val?.member_type + ") ", value: val?.id })
          }
        })
        setGroupList(arr);
      }).catch((error) => {
        console.log("error:::", error)
      })
    makeGetRequest(URLS.friendList, null, null, null)
      .then((res) => {
        const arr = [];
        res?.data?.length > 0 && arr.push({ label: "All", value: "all" });
        res?.data.map((val) => {
          return arr.push({ label: val.name, value: val.id })

        })
        setFriendList(arr)
      }).catch((error) => {
        console.log("error", error)
      })
    setSelectedOption([{ keyword: "", label: "" }]);
  }, [props.value])

  // this function is use for input handle chnage
  const handleGroupId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setGroupData([{ label: "All", value: "all" }]);
      setGroupId(["all"]);
    }
    else {
      setGroupData(shareResourceVal.filter(item => item.value !== "all"))
      setGroupId(shareArr.filter(item => item !== "all"));
    }
  };
  const handleFriendsId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setShareFriends(["all"]);
      setFriendData([{ label: "All", value: "all" }])
    } else {
      setFriendData(shareResourceVal.filter(item => item.value !== "all"))
      setShareFriends(shareArr.filter(item => item !== "all"));
    }
  };

  // this function is use for invite friend
  const shareInvitationForGroup = () => {
    const errors = {};
    if (value === false) {
      if (friendList.length === 0 && emailList.length <= 0) {
        errors.friendError = "Please add your friend email id.";
      } else if (friendList.length > 0) {
        if (friendData.length === 0 && emailList.length <= 0) {
          errors.friendError = "Please fill up at least one field.";
          frndFocus.current.focus();
        }
      }
    }
    else if (value === true) {
      if (groupData.length === 0) {
        errors.groupError = "Please select any Group";
        grpFocus.current.focus();
      }
    }
    setError(errors);
    const data = {
      "group_id": props.value,
      "shared_with": value ? ["group"] : ["friend"],
      "group_ids": groupId,
      "shared_id": shareFriend,
      "no_matched": shareFriend.length < 1 ? false : true,
      "shared_email_id": emailList,
      "shared_by": userId
    }
    if (shareFriend.length >= 1 || groupId.length !== 0 || emailList.length >= 1) {
      setSpin(true)
      makePostRequest(URLS.invitationGroup, null, data, null)
        .then((res) => {
          setSpin(false)
          toastifyMessage(res.message, "success")
          setTimeout(() => {
            getPendingMember()
          }, 700);
          props.datar()
        }).catch((error) => {
          console.log("error:::", error);
          props.datar();
          setSpin(false)
        })
    }
  }
  // this function use for pending member list
  const getPendingMember = () => {
    setSpin(true)
    const data = {
      "group_id": props.value,
      "page_number": 1,
      "searchKey": "",
      "item_per_page": 4
    }
    makePostRequest(URLS.pendingMemberInvite, null, data, null)
      .then((res) => {
        console.log("res:", res)
        setSpin(false)
        const data = {
          "group_id": props.value,
          "page_number": 1,
          "item_per_page": 4,
          "searchKey": "",
        }
        makePostRequest(URLS.nonMatchedInvite, null, data, null)
          .then((response) => {
            dispatch(getGroupPandingList([...res?.data?.groupMemberList, ...response?.data?.groupMemberList]))
          }).catch((error) => {
            console.log("error:", error)
          })
      }).catch((error) => {
        console.log("error:::", error)
      })
  }
  useEffect(() => {
    setGroupId([])
    setShareFriends([])
  }, [value])

  // ----------end multiple value input for emial

  // this function check email
  useEffect(() => {
    const validEmails = [];
    const invalidEmails = [];
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    for (const email of keywords) {
      const trimmedEmail = email?.keyword?.trim() || email;
      if (emailPattern.test(trimmedEmail)) {
        validEmails.push(trimmedEmail);
      } else {
        invalidEmails.push(trimmedEmail);
      }
    }
    if (invalidEmails.length > 0) {
      setAddError('Invalid email address');
      emailFocus.focus();
    } else {
      setAddError('');
    }
    setEmailList(validEmails);
    // eslint-disable-next-line
  }, [keywords]);

  return (
    <>

      <div className="inviteGpopup">
        {spin && (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        )}
        <div className="inviteGpopupinner">
          <h1>Invitation For Group</h1>
          <button className="closed-popup" onClick={props.datar}> X </button>
          <div className="inviteGpopupinnersub">
            <label className="labelFriend">Friends</label>
            <h5 className="py-1">
              <label className="switch" htmlFor="viewable-friend">
                <input type="checkbox" id="viewable-friend" onChange={(e) => setValue(e.target.checked)} />
                <span className="slider round"></span>
              </label>
            </h5>
            <label className="inviteGpopupinnerlabel">Groups</label>
          </div>
          <div className="selectGroupouter">
            {
              value ? <div className="share-group">
                <label className="inviteGpopupinnerlabel mx-0">Select Group <span className="astrickStar">*</span></label ><label className="oneSelect">(select one)</label><br />
                <Select
                  value={
                    groupData ? groupData : null
                  }
                  options={groupList}
                  isMulti
                  selectMultiple={true}
                  onChange={handleGroupId}
                  ref={grpFocus}
                />
                <span className="error-span">{error.groupError}</span>
              </div> : <>
                {friendList.length > 0 ?
                  <div className="share-group">
                    <label className="inviteGpopupinnerlabel mx-0">Type names of MatchED Members</label > <label className="oneSelect">
                    </label><br />
                    <Select
                      value={friendData ? friendData : null}
                      options={friendList}
                      isMulti
                      selectMultiple={true}
                      onChange={handleFriendsId}
                      ref={frndFocus}
                    />
                  </div> : ""
                }
                <div className="kayword-associatedNew">
                  <div className="email-text-option"> <label className="mx-0">Type email addresses of MatchED/Non-MatchED Members</label></div>
                  <Autocomplete
                    multiple
                    value={emailList}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setKeywords({
                          keyword: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        setKeywords({
                          keyword: newValue.inputValue,
                        });
                      } else {
                        let filteredArray = newValue.filter(item => {
                          if (typeof item === 'string') {
                            return item.trim() !== '';
                          }
                          if (item.hasOwnProperty('label') && typeof item.label === 'string') {
                            return item.label.trim() !== '';
                          }
                          return true;
                        });
                        setKeywords(filteredArray);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) => inputValue === option.keyword
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          keyword: inputValue,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={selectedOption}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.keyword;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.keyword}</li>
                    )}
                    sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Email" inputRef={input => {
                        emailFocus = input;
                      }} />
                    )}
                  />
                </div>
                {emailList.length <= 0 ? <span className="error-span">
                  {
                    addError ? addError : ""
                  }
                </span> : ""}
              </>
            }
          </div>
          <div className="content-text-bsvg py-2">
            <span className="error-span">{(friendData.length === 0 && emailList.length <= 0) && error.friendError}</span>
            <p className="my-1" onClick={() => handleCopyClick(props.value)}>Copy link to share</p>
            <div className="copy-icon" onClick={() => handleCopyClick(props.value)}>
              <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
              <ContentCopyIcon className="mt-2" />
            </div>
          </div>
          <div className="buttonOuter text-center">
            <button className="inviteBtn" onClick={shareInvitationForGroup}>
              <span>Invite</span>
            </button>{"  "}&nbsp;&nbsp;
            <button className="cancleBt" onClick={props.datar} >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default InviteGroups;
