import { useEffect, useRef, useState } from "react";
import "./Singlechat.css";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import EmojiPicker from "emoji-picker-react";
import {
  decodeToken,
  getTime,
  toastifyMessage,
  groupMessagesByDay,
  handleFileDownload,
} from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { getFileIcon } from "./Service";
import {
  makeFormDateRequset,
  makePostRequest,
  makePostRequestForAll,
} from "../../services/api";
import AddGroupMember from "./AddGroupMember";
import { imageUrl } from "../../services/api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import io from "socket.io-client";
import GroupSetting from "./GroupSetting";
import { useDispatch, useSelector } from "react-redux";
import { getChatCount, getOpenGlobalChat } from "../../store/reducers/userReducer";
import TextareaAutosize from "react-textarea-autosize";

const host = process.env.REACT_APP_CONECT_URL;
/**
 *  chat component is used to chat with signle and multi user
 * also delete single chat message
 *  @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 24-9-2023
 */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 6,
};
const SingleChat = (props) => {
  // console.log("singlechat", props)
  let getimage = imageUrl;
  const userStore = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const payLoad = decodeToken().userAuth.id;
  const userDetail = decodeToken()?.userAuth;
  const image = decodeToken().userAuth.photo;
  const [image1, setImage1] = useState("");
  const [userChat, setUserChat] = useState([]);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [openSetting, setopenSetting] = useState(false);
  const [chatDoc, setChatDoc] = useState("");
  const [msgWithUser, setMsgWithUser] = useState("");
  const [toggle2, setToggel2] = useState(false);
  const [fun, setFun] = useState("");
  const [leavePopup, setLeavePopup] = useState(false);
  const [viewText, setViewText] = useState("");
  const [statusCheck, setStatusCheck] = useState("");
  const [chatdocname, setChatdocname] = useState("");
  const [page, setPage] = useState(1);
  const [socket, setSocket] = useState(null);
  const [allChat, setAllChat] = useState([]);
  const [detail, setDetail] = useState("");
  const [userMapId, setUserMapId] = useState("");
  const [group, setGroup] = useState(0); // group 0 single chat , group 1 multichat
  const hiddenFileInput = useRef(null);
  const chatContainerRef = useRef(null);
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const containsText = /[a-zA-Z]/;
  const containsNumber = /\d/;
  const menuRef = useRef(null);
  const menuBtnsRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [openGruop, setOpenGroup] = useState(false);
  const [signle, setSingle] = useState(false);
  const [remainingCount, setRemaingCount] = useState(0);
  const [status, setStatus] = useState(false);
  const [pageStatus] = useState("singleChat");
  const [callback, setCallback] = useState("");
  const [ss, setSs] = useState({ id: "", grp: "" });

  // remove image and close
  async function remove() {
    hiddenFileInput.current.value = null;
    setImage1(null);
    setVisible(false);
  }
  // socket connection
  useEffect(() => {
    const socketConnection = io.connect(host);
    setSocket(socketConnection);
    GetAllChatsMassage();
    return () => {
      socketConnection.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(()=>{
  //   if (userStore?.globalOpenChat?.chat_by_block === "1"){
  //     alert("unblock first")
  //   }
  // }, [userStore?.globalOpenChat])
  // add user in chatroom
  useEffect(() => {
    if (socket) {
      if (group === 0) {
        socket.emit("add-user", payLoad);
      } else {
        socket.emit(
          "mulitplejoin",
          { name: detail?.name, room: userMapId },
          (error) => {
            if (error) {
              console.log("Error joining room:", error);
            } else {
              console.log("Joined room:", detail?.name);
            }
          }
        );
      }
    }
    messageRead();
    // eslint-disable-next-line
  }, [userMapId]);

  // this function is use for send message
  const SendMessages = (e) => {
    setMsgWithUser("");
    const timestamp = Date.now().toString(36); // Convert timestamp to base36 string
    const randomChars = Math.random().toString(36).substring(2, 8); // Add random characters
    e.preventDefault();
    setOpenEmoji(false);
    const formData = new FormData();
    if (msgWithUser.trim() || chatDoc) {
      if (userStore?.globalOpenChat?.chat_by_block === 1 ){
        //this if condition used for if someone blocked then can't send message again to them untill unblock that person
        const blockText = `Unblock ${detail?.name} to send a message`
        setLeavePopup(true);
        setViewText(blockText)
        setStatusCheck("blockOrUnblock");
        setFun({ id: userMapId, status: "block" });
      } else{
      if (group === 0) {
        
        // send message in single chat (one to one)
        const Singledata = {
          send_to: props?.senderId,
          message: msgWithUser ? msgWithUser : "",
          users_map_id: userMapId,
          massageUniqueId: timestamp + randomChars,
        };
        const socketStoreData = {
          send_by: payLoad,
          send_to: props?.senderId,
          message: msgWithUser ? msgWithUser : "",
          chat_doc: chatDoc ? URL.createObjectURL(chatDoc) : "",
          users_map_id: userMapId,
          send_by_user_photo: getimage + image,
          fileExtension: chatDoc ? chatDoc.name.split(".").pop() : "",
          message_date: new Date().toISOString(),
          massageUniqueId: timestamp + randomChars,
          send_by_name: userDetail?.firstName + " " + userDetail?.lastName,
        };
        if (socket && (msgWithUser || chatDoc)) {
          socket.emit("send-msg", socketStoreData);
          setStatus(!status);
        }
        setUserChat((prevUserChat) => [...prevUserChat, socketStoreData]);
        makePostRequestForAll(URLS.addChatMsg, Singledata)
          .then((res) => {
            console.log("addChatMsg", res)
            if (res.code === 200) {
              if (chatDoc) {
                formData.append("chat_id", res.data.id);
                formData.append("chat_doc", chatDoc);
                makeFormDateRequset(URLS.addChatImage, formData)
                  .then((res) => { setImage1(null) })
                  .catch((error) => { });
              }
              setMsgWithUser("");
              setChatDoc("");
            } else {
              toastifyMessage(res?.message, "error")
            }
          })
          .catch((error) => console.log("error::", error));
      } else {
        // send message on multichat
        const Groupdata = {
          chat_multiple_id: userMapId,
          message: msgWithUser ? msgWithUser : "",
          massageUniqueId: timestamp + randomChars,
        };
        const data = {
          send_by: userDetail?.id,
          group_id: userMapId,
          message: msgWithUser ? msgWithUser : "",
          chat_doc: chatDoc ? URL.createObjectURL(chatDoc) : "",
          fileExtension: chatDoc ? chatDoc.name.split(".").pop() : "",
          send_by_user_photo: getimage + image,
          message_date: new Date().toISOString(),
          send_by_name: userDetail?.firstName + " " + userDetail?.lastName,
          massageUniqueId: timestamp + randomChars,
        };
        if (socket && (msgWithUser || chatDoc)) {
          socket.emit("sendMessageMulitple", data);
          setStatus(!status);
        }
        setMsgWithUser("");
        setChatDoc("");
        makePostRequestForAll(URLS.addMultiChat, Groupdata)
          .then((res) => {
            if (res.code === 200) {
              if (chatDoc) {
                formData.append("chat_id", res?.data?.id);
                formData.append("chat_doc", chatDoc);
                makeFormDateRequset(URLS.addDocInMultiChat, formData)
                  .then((res) => {
                    setImage1(null)
                    console.log("res::", res);
                  })
                  .catch((error) => { });
              }
            }else{
              toastifyMessage(res?.message, "error")
            }
          })
          .catch((error) => console.log("error:", error));
      }
      // if(userStore?.globalOpenChat?.block_by_other_person === "1" ||
      //     userStore?.globalOpenChat?.chat_with_block === "1"
      //   ){
      //     toastifyMessage("Message can't be delivered", "error") 
      //   }
    }
    } else {
      alert("you can't send empty message");

    }
  };

  // this function is use for recieve message by socket
  useEffect(() => {
    if (socket) {
      if (group === 0) {
        // single chat message recieve
        socket.on("msg-recieve", (msg) => {
          messageRead();
          setUserChat((prevUserChat) => [...prevUserChat, msg]);
          if (msg.chat_doc) {
            setTimeout(() => {
              GetAllChatsMassage();
            }, 800);
          }
        });
      } else {
        // multi chat message recieve
        socket.on("MessageMulitple", (message) => {
          messageRead();
          if (!message.chat_doc) {
            setUserChat((prevUserChat) => [...prevUserChat, message]);
          } else {
            setTimeout(() => {
              GetAllChatsMassage();
            }, 2000);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [socket, userMapId]);

  // this function use for handle image
  const handleImages = (e) => {
    let document = ["pdf", "png", "PNG", "jpg", "jpeg", "gif"];
    const checkfile = e.target.files;
    if (checkfile[0]) {
      const fileSize = e.target.files[0].size / (1024 * 1024);
      if (fileSize > 2) {
        toastifyMessage(
          "File size exceeded, Please upload file up to 2 mb",
          "error"
        );
      } else if (document.includes(checkfile[0].name.split(".").pop())) {
        setChatDoc(e.target.files[0]);
        let imgname = e.target.files[0].name;
        let imgType = imgname.split(".");
        setChatdocname(e.target.files[0].name);
        setImage1({
          imgurl: URL.createObjectURL(e.target.files[0]),
          imgtype: imgType.pop(),
        });
        const formData = new FormData();
        formData.append("File", image1);
        setVisible(true);
      } else {
        toastifyMessage(
          "you can upload only png,jpg,jpeg,doc and pdf",
          "error"
        );
      }
    }
  };
  // this function use for get all chats
  const GetAllChatsMassage = () => {
    if (userMapId) {
      if (group === 0) {
        // signle chat
        const data = {
          users_map_id: userMapId,
          page_number: page,
          item_per_page: 10,
        };
        makePostRequestForAll(URLS.getChatMsg, data).then((res) => {
          if (page === 1) {
            setUserChat(res?.data?.chatMessages?.reverse());
          } else {
            setUserChat((prevChat) => [
              ...res?.data?.chatMessages?.reverse(),
              ...prevChat,
            ]);
          }
          setRemaingCount(res?.data?.remaining_count);
        });
      } else {
        // multi chat 
        const data = {
          chat_multiple_id: userMapId,
          page_number: page,
          item_per_page: 10,
        };
        makePostRequestForAll(URLS.getMultiChat, data).then((res) => {
          if (page === 1) {
            setUserChat(res?.data?.messagelist?.reverse());
          } else {
            setUserChat((prevChat) => [
              ...res?.data?.messagelist?.reverse(),
              ...prevChat,
            ]);
          }
          setRemaingCount(res?.data?.remaining_count);
        });
      }
    }
  };

  // this function use for handle scroll
  const handleScroll = () => {
    const { scrollTop } = chatContainerRef.current;
    if (scrollTop === 0 && remainingCount === 1) {
      setPage(page + 1);
      setTimeout(() => {
        chatContainerRef.current.scrollTop = 100;
      }, 100);
    }
  };

  // call get message API when userMapId,group & page state update
  useEffect(() => {
    GetAllChatsMassage();
    // eslint-disable-next-line
  }, [userMapId, group, page]);

  useEffect(() => {
    if (userChat) {
      const groupedMessages = groupMessagesByDay(userChat);
      setAllChat(groupedMessages);
    }
  }, [userChat]);

  // this function is use for scrolll bottom
  useEffect(() => {
    if (page === 1) {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo(
          0,
          chatContainerRef.current.scrollHeight
        );
      }
    }
    // eslint-disable-next-line
  }, [allChat]);

  // this is comman function for block/delete chat/remove
  const CommamFuncation = (id, status) => {
    console.log("id, status", id, status)
    if (statusCheck === "blockOrUnblock") {
      const data = {
        block_by_user_id: payLoad,
        users_map_id: id,
        status: status,
      };
      makePostRequestForAll(URLS.blockUnblockChat, data)
        .then((res) => {
          console.log("unblock", res)
          if (res.code === 200) {
            console.log("res:::", res)
            setLeavePopup(false);
            toastifyMessage(res.message, "success");
            dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat,   chat_by_block : 
              res?.data?.block , block_by_other_person : res?.data?.chat_by_block,
              chat_with_block: res?.data?.chat_with_block
               }))
          
          }
          props?.getAllUser();
        })
        .catch((error) => {
          // toastifyMessage("something went wrong.", "error");
        });
    } else if (statusCheck === "leaveOrBlock") {
      const data = {
        id: userMapId,
      };
      makePostRequestForAll(URLS.leaveMultiChat, data)
        .then((res) => {
          setLeavePopup(false);
          if (res.code === 200) {
            toastifyMessage(res.message, "success");
            props.chatClose();
            props?.getAllUser();
            setToggel2(false);
          } else {
            toastifyMessage(res.message, "warn");
          }
        })
        .catch((error) => { });
    } else if (statusCheck === "deleteGroup") {
      const data = {
        chat_multiple_id: userMapId,
      };
      makePostRequestForAll(URLS.chatGroupDelete, data) // this api used for delete group by owner only
        .then((res) => {
          setLeavePopup(false);
          if (res.code === 200) {
            toastifyMessage(res.message, "success");
            props.chatClose();
            props?.getAllUser();
            setToggel2(false);
            console.log("group delete", res);
          } else {
            toastifyMessage(res.message, "warn");
          }
        })
        .catch((error) => { });
    } else if (statusCheck === "deleteChat") {
      let data;
      if (group === 0) {
        data = {
          user_id: payLoad,
          message_id: id,
          users_map_id: userMapId,
          group_id: "",
        };
        makePostRequestForAll(URLS.deleteSingleChat, data)
          .then((res) => {
            toastifyMessage("Message has been deleted", "success");
            setLeavePopup(false);
            GetAllChatsMassage();
          })
          .catch((error) => {
            console.log("error:::", error);
            toastifyMessage("something went wrong.", "error");
          });
      } else {
        data = {
          message_id: id,
          chat_multiple_id: "",
        };

        makePostRequestForAll(URLS.singleChatDeleteInMultiChat, data)
          .then((res) => {
            toastifyMessage("Message has been deleted", "success");
            setLeavePopup(false);
            GetAllChatsMassage();
          })
          .catch((error) => {
            console.log("error:::", error);
            // toastifyMessage("something went wrong.", "error");
          });
      }
    } else if (statusCheck === "deleteAllChat") {
      let data;
      if (group === 0) {
        data = {
          user_id: payLoad,
          users_map_id: id,
          group_id: "",
        };

        makePostRequestForAll(URLS.deleteBulkChat, data)
          .then((res) => {
            setLeavePopup(false);
            if (res.code === 200) {
              toastifyMessage("Message has been deleted", "success");
              GetAllChatsMassage();
            }
          })
          .catch((error) => {
            toastifyMessage("something went wrong.", "error");
          });
      } else {
        const data = {
          chat_multiple_id: id,
          message_id: "",
        };
        makePostRequestForAll(URLS.singleChatDeleteInMultiChat, data)
          .then((res) => {
            console.log("deleteInMultiChat::", res);
            setLeavePopup(false);
            if (res.code === 200) {
              toastifyMessage("Message has been deleted", "success");
              GetAllChatsMassage();
            }
          })
          .catch((error) => { });
      }
    }
  };
  const handleClickOutsides = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnsRef.current.contains(e.target)
    ) {
      setToggel2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsides, true);
    };
  }, []);

  // this function handle read messages bases on condition
  const messageRead = () => {
    if (userMapId && group === 0) {
      const data = {
        users_map_id: userMapId,
        group_id: "",
      };
      makePostRequestForAll(URLS.markAllAsRead, data)
        .then((res) => {
          makePostRequest(
            URLS.getChatWithGroupUserSidebar,
            null,
            {},
            null
          ).then((res) => {
            if (res?.code === 200) {
              dispatch(getChatCount(res?.data?.unread_message));
            }
          });
        })
        .catch((error) => {
          // toastifyMessage("something went wrong.", "error");
        });
    } else if (group === 1) {
      const data = {
        chat_multiple_id: userMapId,
      };
      makePostRequestForAll(URLS.multiChatReadAll, data)
        .then((res) => {
          makePostRequest(
            URLS.getChatWithGroupUserSidebar,
            null,
            {},
            null
          ).then((res) => {
            if (res?.code === 200) {
              dispatch(getChatCount(res?.data?.unread_message));
            }
          });
        })
        .catch((error) => {
          toastifyMessage("something went wrong.", "error");
        });
    }
  };

  //auto scroll down when chat up on chat window
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [status]);

  // manage page route based on props
  useEffect(() => {
    if (props?.groupName?.callback === "callback" && callback !== "") {
      setDetail(callback);
      setUserMapId(ss?.id);
      setGroup(ss?.grp);
    } else if (
      (callback === "" &&
        (props?.statuspage === "chatlist" ||
          props?.statuspage === "friend" ||
          props?.statuspage === "session" ||
          props?.statuspage === "description" ||
          props?.statuspage === "notification" ||
          props?.statuspage === "memberlist" ||
          props?.statuspage === "rightsideGroup" ||
          props?.statuspage === "loginheader" ||
          props?.statuspage === "sidebar" ||
          props?.statuspage === "salehistory" ||
          props?.statuspage === "serverPaymenthistory")) ||
      props?.groupName?.callback === "ss"
    ) {
      setDetail(props?.groupName);
      setUserMapId(props?.userMapId);
      setGroup(props?.group);
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (
      (callback && props?.statuspage === "friend") ||
      props.statuspage === "memberlist"
    ) {
      props?.callback({ callback: callback?.callback, data: callback, id: ss });
    }
    if (callback?.callback) {
      setDetail(callback);
      setUserMapId(ss?.id);
      setGroup(ss?.grp);
    }
    // eslint-disable-next-line
  }, [callback]);

  return (
    <>
      <div className="chat-popup chatpopupauto">
        <div className=" cssanimation sequence fadeInBottom" id="myForm">
          <div className="messanger-inner">
            <div className="messanger-inner-div">
              <div className="d-flex gap-1">
                <div className="chat-user-profile">
                  <img
                    className="img-fluid"
                    src={detail?.image}
                    alt="detail"
                    loading="lazy"
                  />
                  <span></span>
                </div>
                <div className="user-name">
                  <h3>{detail?.name}</h3>
                </div>
              </div>
              <div className="edit-button">
                <span ref={menuBtnsRef}>
                  <i
                    className="fa fa-ellipsis-h"
                    onClick={() => setToggel2(!toggle2)}
                  ></i>
                </span>
                <div
                  className="position-relative"
                  style={{ marginTop: "50px" }}
                >
                  <div
                    ref={menuRef}
                    className="position-absolute messagethreedostpopup"
                    style={{ width: "150px" }}
                  >
                    {toggle2 && (
                      <>
                        {group === 1 ? (
                          <>
                            <div className="bg-white font-weight-normal toggle-of-chat shadow-sm rounded px-1">
                              <p
                                className="border-bottom d-flex justify-content-start px-2 courser-point"
                                onClick={() => {
                                  setOpenGroup(true);
                                  setSingle(false);
                                }}
                              >
                                <span>View Members</span>
                              </p>
                              <p
                                className="border-bottom d-flex justify-content-start px-2 courser-point"
                                onClick={() => {
                                  setLeavePopup(true);
                                  setViewText(
                                    "Are you sure you want to leave this chat?"
                                  );
                                  setStatusCheck("leaveOrBlock");
                                  setFun({ id: userMapId, status: "" });
                                }}
                              >
                                <span>Leave Chat</span>
                              </p>
                              <p
                                className="border-bottom d-flex justify-content-start px-2 courser-point"
                                onClick={() => {
                                  setLeavePopup(true);
                                  setViewText(
                                    "Are you sure you want to delete this chat?"
                                  );
                                  setStatusCheck("deleteAllChat");
                                  setFun({ id: userMapId, status: "" });
                                }}
                              >
                                <span>Delete All Chats </span>
                              </p>

                              {detail?.admin === true ||
                                props?.groupName?.admin === true ? (
                                <p
                                  className="border-bottom d-flex justify-content-start px-2 courser-point"
                                  onClick={() => {
                                    setLeavePopup(true);
                                    setViewText(
                                      "Are you sure you want to delete this group?"
                                    );
                                    setStatusCheck("deleteGroup");
                                    setFun({ id: userMapId, status: "" });
                                  }}
                                >
                                  <span>Delete Group</span>
                                </p>
                              ) : (
                                ""
                              )}
                              <p
                                className="border-bottom d-flex justify-content-start px-2 courser-point"
                                onClick={() => {
                                  setopenSetting(true);
                                }}
                              >
                                <span>Setting</span>
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className="bg-white font-weight-normal toggle-of-chat shadow-sm rounded px-1">
                            <p
                              className="border-bottom d-flex justify-content-start px-2 courser-point"
                              onClick={() => {
                                setOpenGroup(true);
                                setSingle(true);
                              }}
                            >
                              <span>Add member</span>
                            </p>
                            <p
                              className="border-bottom d-flex justify-content-start px-2 courser-point"
                              onClick={() => {
                                setLeavePopup(true);
                                setViewText(
                                  "Are you sure you want to delete this chat?"
                                );
                                setStatusCheck("deleteAllChat");
                                setFun({ id: userMapId, status: "" });
                              }}
                            >
                              <span>Delete All Chats </span>
                            </p>
                            
                            <p
                              className="border-bottom d-flex justify-content-start px-2 courser-point"
                              onClick={() => {
                                setLeavePopup(true);
                                setViewText(
                                  userStore?.globalOpenChat?.chat_by_block === 1 ? 
                                    "Are you sure you want to unblock this member?" :
                                  "Are you sure you want to block this member?"
                                  
                                );
                                setStatusCheck("blockOrUnblock");
                                setFun({ id: userMapId, status: "block" });
                              }}
                            >
                              <span> {userStore?.globalOpenChat?.chat_by_block === 1  ? 
                             "Unblock Member" : "Block Member"  } </span>
                            </p>

                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="toprigntschatmiddle">
                  <ul>
                    <li>
                      <button
                        onClick={() => {
                          props?.chatClose();
                          props?.getAllUser();
                          setToggel2(false);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="middle-chat-part ">
              <div className="messages chat-content">
                <ul className="chatList1 chatlistautotext">
                  <div
                    ref={chatContainerRef}
                    style={{
                      overflowY: "scroll",
                      height: "400px",
                      overflowX: "hidden",
                    }}
                    onScroll={handleScroll}
                  >
                    {Object.keys(allChat)?.map((date) => {
                      return (
                        <div>
                          <div className="date-separator">{date}</div>
                          {allChat[date] &&
                            allChat[date]?.map((val) => {
                              return (
                                <>
                                  {val.send_by_id === payLoad ||
                                    val.send_by === payLoad ? (
                                    <li className="replies">
                                      <img
                                        className="img-fluid user-profile "
                                        src={val.send_by_user_photo}
                                        alt="profile"
                                      />
                                      <div className="flex-column-own">
                                        {val.fileExtension === "png" ||
                                          val.fileExtension === "jpg" ||
                                          val.fileExtension === "jpeg" ||
                                          val.fileExtension === "PNG" ? (
                                          <>
                                            {val.chat_doc ? (
                                              <div className="doc_div_r">
                                                {group === 1 && (
                                                  <div className="sender-name">
                                                    <span>You</span>
                                                  </div>
                                                )}
                                                <img
                                                  src={val.chat_doc}
                                                  onClick={() =>
                                                    handleFileDownload(
                                                      val.chat_doc,
                                                      "MatchEd_doc"
                                                    )
                                                  }
                                                  className="pdf-image"
                                                  style={{
                                                    width: "80px",
                                                    height: "80px",
                                                    margin: "0 auto",
                                                  }}
                                                  alt="doc"
                                                />
                                                <div>
                                                  <span className="message-time">
                                                    {" "}
                                                    {getTime(
                                                      val?.message_date,
                                                      "chat"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </>

                                        ) : (
                                          <>
                                            {val.chat_doc && (
                                              <div
                                                className="doc_div_r"
                                                onClick={() =>
                                                  handleFileDownload(
                                                    val.chat_doc,
                                                    "MatchEd_doc"
                                                  )
                                                }
                                              >
                                                {group === 1 && (
                                                  <div className="sender-name">
                                                    <span>You</span>
                                                  </div>
                                                )}
                                                {val.fileExtension
                                                  ? getFileIcon(
                                                    val.fileExtension
                                                  )
                                                  : ""}
                                                <div>
                                                  <span className="message-time">
                                                    {" "}
                                                    {getTime(
                                                      val?.message_date,
                                                      "chat"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                        {val.message ? (
                                          <>
                                            {urlRegex.test(val.message) ? (
                                              <p>
                                                {!val.chat_doc &&
                                                  group === 1 && (
                                                    <div className="sender-name">
                                                      <span>You</span>
                                                    </div>
                                                  )}
                                                <span>
                                                  <a
                                                    href={val.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-white"
                                                  >
                                                    {val.message}
                                                  </a>
                                                </span>
                                                <span className="message-time-r">
                                                  {" "}
                                                  {getTime(
                                                    val?.message_date,
                                                    "chat"
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                {!val.chat_doc &&
                                                  group === 1 && (
                                                    <div className="sender-name">
                                                      <span>You</span>
                                                    </div>
                                                  )}
                                                {containsText.test(
                                                  val.message
                                                ) ||
                                                  containsNumber.test(
                                                    val.message
                                                  ) ? (
                                                  <span dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}></span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      fontSize: "21px", lineHeight: "27px",
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}
                                                  >

                                                  </span>
                                                )}
                                                <span className="message-time-r">
                                                  {" "}
                                                  {getTime(
                                                    val?.message_date,
                                                    "chat"
                                                  )}
                                                </span>
                                              </p>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <strong className="delete-massage">
                                        <DeleteIcon
                                          onClick={() => {
                                            setLeavePopup(true);
                                            setViewText(
                                              "Are you sure you want to delete this ?"
                                            );
                                            setStatusCheck("deleteChat");
                                            setFun({
                                              id: val.massageUniqueId,
                                              status: "",
                                            });
                                          }}
                                        />
                                      </strong>
                                    </li>
                                  ) : (
                                    <li className="sent">
                                      <img
                                        className="img-fluid user-profile "
                                        src={val.send_by_user_photo}
                                        alt="profile"
                                      />
                                      <div className="d-flex flex-column">
                                        {val.fileExtension === "png" ||
                                          val.fileExtension === "jpg" ||
                                          val.fileExtension === "jpeg" ? (
                                          <>
                                            {val.chat_doc ? (
                                              <div className="doc_div">
                                                {group === 1 && (
                                                  <div className="sender-name">
                                                    <span>
                                                      {val.send_by_name}
                                                    </span>
                                                  </div>
                                                )}
                                                <img
                                                  src={val.chat_doc}
                                                  onClick={() =>
                                                    handleFileDownload(
                                                      val.chat_doc,
                                                      "MatchEd_doc"
                                                    )
                                                  }
                                                  className="pdf-image"
                                                  style={{
                                                    width: "80px",
                                                    height: "80px",
                                                  }}
                                                  alt="doc"
                                                />
                                                <div>
                                                  <span className="message-time">
                                                    {" "}
                                                    {getTime(
                                                      val?.message_date,
                                                      "chat"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {val.chat_doc && (
                                              <div
                                                className="doc_div"
                                                onClick={() =>
                                                  handleFileDownload(
                                                    val.chat_doc,
                                                    "MatchEd_doc"
                                                  )
                                                }
                                              >
                                                {group === 1 && (
                                                  <div className="sender-name">
                                                    <span>
                                                      {val.send_by_name}
                                                    </span>
                                                  </div>
                                                )}
                                                {val.fileExtension
                                                  ? getFileIcon(
                                                    val.fileExtension
                                                  )
                                                  : ""}
                                                <div>
                                                  <span className="message-time">
                                                    {" "}
                                                    {getTime(
                                                      val?.message_date,
                                                      "chat"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                        {val.message ? (
                                          <>
                                            {urlRegex.test(val.message) ? (
                                              <p>
                                                {!val.chat_doc &&
                                                  group === 1 && (
                                                    <div className="sender-name">
                                                      <span>
                                                        {val.send_by_name}
                                                      </span>
                                                    </div>
                                                  )}
                                                <span>
                                                  <a
                                                    href={val.message}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {val.message}
                                                  </a>
                                                </span>
                                                <span className="message-time">
                                                  {" "}
                                                  {getTime(
                                                    val?.message_date,
                                                    "chat"
                                                  )}
                                                </span>
                                              </p>
                                            ) : (
                                              <p>
                                                {!val.chat_doc &&
                                                  group === 1 && (
                                                    <div className="sender-name">
                                                      <span>
                                                        {val.send_by_name}
                                                      </span>
                                                    </div>
                                                  )}
                                                {containsText.test(
                                                  val?.message
                                                ) ||
                                                  containsNumber.test(
                                                    val?.message
                                                  ) ? (
                                                  <span dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}></span>
                                                ) : (
                                                  <span
                                                    style={{ fontSize: "21px", lineHeight: "27px", }}
                                                    dangerouslySetInnerHTML={{ __html: val?.message?.replace(/\n/g, '<br>') }}
                                                  >
                                                  </span>
                                                )}
                                                <span className="message-time">
                                                  {" "}
                                                  {getTime(
                                                    val?.message_date,
                                                    "chat"
                                                  )}
                                                </span>
                                              </p>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                        </div>
                      );
                    })}
                    <div
                      ref={messagesEndRef}
                      style={{ marginBottom: "50px" }}
                    ></div>
                  </div>
                </ul>
                <form onSubmit={SendMessages}>
                  {isVisible && (
                    <div className="chat-messages-upload">
                      <div>
                        {" "}
                        {image1 &&
                          (image1.imgtype === "png" ||
                            image1.imgtype === "jpg" ||
                            image1.imgtype === "jpeg" ||
                            image1.imgtype === "jpeg") ? (
                          <img
                            src={image1.imgurl}
                            width={100}
                            heigth={200}
                            alt="send"
                          />
                        ) : (
                          <p>
                            <PictureAsPdfOutlinedIcon /> {chatdocname}
                          </p>
                        )}
                      </div>
                      <button className="cros_btn" onClick={remove}>
                        X
                      </button>
                    </div>
                  )}
                  <div className="chat-messages-textarea chatautotextarea">
                    <div className="chat-messages-textarea-rt">
                      <TextareaAutosize
                        className="form-control textareaautosize  px-2"
                        name="msgWithUser"
                        placeholder="Write Something..."
                        onChange={(e) => setMsgWithUser(e.target.value)}
                        value={msgWithUser}
                        onKeyPress={(e) => {
                          if (e && e.key === "Enter" && !e.shiftKey) {
                            SendMessages(e);
                          }
                        }}
                      />
                      <div className="button-area d-flex areabtnauto">
                        <div className="attech">
                          <i
                            className="fa fa-paperclip attech-icon"
                            aria-hidden="true"
                            style={{ cursor: "pointer" }}
                          ></i>
                          <input
                            type="file"
                            onChange={handleImages}
                            ref={hiddenFileInput}
                            multiple
                          />
                        </div>
                        <i
                          className="fa fa-smile-o"
                          aria-hidden="true"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenEmoji(!openEmoji)}
                        ></i>
                      </div>
                    </div>
                    <div className="chat-messages-textarea-rtt chatmessageautotext">
                      <button type="submit">
                        <i
                          className="fa fa-paper-plane"
                          aria-hidden="true"
                          onClick={remove}
                        ></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {openEmoji && (
                <div className="chatManuiocns">
                  <EmojiPicker
                    onEmojiClick={(e) => setMsgWithUser(msgWithUser + e.emoji)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*----- start comman delte----- */}
      <Modal
        open={leavePopup}
        onClose={() => setLeavePopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            {viewText}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button
              className="child-cancel"
              onClick={() => CommamFuncation(fun.id, fun.status)}
            >
              {userStore?.globalOpenChat?.chat_by_block === 1 ? "Unblock" : "Yes"}
            </button>
            <button
              className="child-delete"
              onClick={() => setLeavePopup(false)}
            >
              {userStore?.globalOpenChat?.chat_by_block === 1 ? "Cancel" : "No"}
            </button>
          </Typography>
        </Box>
      </Modal>
      {/* ---------start add group-------- */}
      {openGruop && (
        <AddGroupMember
          open={openGruop}
          onClose={() => {
            setOpenGroup(false);
          }}
          id={signle ? "" : userMapId}
          detail={detail}
          pageStatus={pageStatus}
          callback={(id, data, status) => {
            setDetail(data);
            setCallback(data);
            setSs({ id: id, grp: status });
            setUserMapId(id);
            setGroup(status);
          }}
          user={true}
          st={props?.statuspage}
        />
      )}
      {openSetting && (
        <GroupSetting
          open={openSetting}
          pageStatus={pageStatus}
          onClose={() => {
            setopenSetting(false);
          }}
          userMapId={userMapId}
          detail={detail}
          getAllUser={props?.getAllUser}
          callback={(data) => {setDetail(data)
            dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, userDetail: data }))
          }
          }
        />
      )}
    </>
  );
};

export default SingleChat;
