/**
 * Serve Resources List Page
 *
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 16-05-2023
 */

import { useState, useEffect } from "react";
import "./serve_yourresources.css";
import Cardcomponent1 from "./Cardcomponent1";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { useNavigate, Link } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { Multiselect } from "multiselect-react-dropdown";

function Serve_yourresources() {
  let navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [resourcesData, setResourcesData] = useState([]);
  const [spinStatus, setSpinStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ordered, setOrdered] = useState("");
  const [deleteApiRes, setDeleteApiRes] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(0);
  const [itemPerPage, setItemPerPage] = useState("9");
  const [Keywords, setKeywords] = useState("");
  const orderByOptions = [
    { value: "DESCDATE", name: "Date (Newest to Oldest)" }, // deascending order
    { value: "ASCDATE", name: "Date (Oldest to Newest)" }, // ascending order
    { value: "ASCTITLE", name: "Title (A to Z)" },
    { value: "DESCTITLE", name: "Title (Z to A)" }
  ];

  const handleOrderSelection = (selectedList) => {
    selectedList?.forEach((val, i) => {
      setOrdered(val.value);
    });
  };

  // search based on condition 
  const searchKeywords = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    getAllResources();
  };
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  //api to get all serve resources data with filter and pagination
  const getAllResources = () => {
    setLoading(true);
    const data = {
      resource_mode: "serve",
      available: "",
      page_number: currentPage,
      item_per_page: parseInt(itemPerPage),
      sortby: ordered,
      search_title: Keywords,
    };
    makePostRequest(URLS.getAllResourcesServeData, null, data, null)
      .then((res) => {
        if (res?.data?.remaining_count === 1) {
          setSpinStatus(true)
        } else {
          setSpinStatus(false)
        }
        setSpinStatus(true);
        const share = res?.data?.share?.share?.map((val) => val.resource_id) || [];
        if (res.code === 200) {
          const updatedData = res?.data?.data?.map((resource) => ({
            ...resource,
            share: share.includes(resource.id) ? "yes" : "no"
          })) || [];
          if (currentPage === 1) {
            setResourcesData(updatedData);
          } else {
            setResourcesData((prevData) => [...prevData, ...updatedData]);
          }
          setHasMore(res?.data?.remaining_count);
        }
        setLoading(false);

      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (isMounted) {
      getAllResources();
    }
    // eslint-disable-next-line
  }, [currentPage, isMounted]);

  useEffect(() => {
    if (isMounted) {
      setCurrentPage(1);
      getAllResources();
    }
    // eslint-disable-next-line
  }, [ordered, itemPerPage, deleteApiRes, isMounted]);

  useEffect(() => {
    document.body.classList.add("serveyour-resource");
    return () => {
      document.body.classList.remove("serveyour-resource");
    };
  }, []);


  useEffect(() => {
    if (resourcesData?.length === 0 && spinStatus === false) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [spinStatus, resourcesData?.length]);

  //increase current page +1 every click on show more results
  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };


  return (
    <>
      {loading &&

        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      }
      <div className="col-lg-10">
        <div className="serveResourceInner">
          <div className="mar-bttom">
            <h5 className="dash-h5">
              <Link to="/serve-dashboard" className="link-style">
                <span>
                  <i
                    className="fa fa-angle-left lefticon"
                    aria-hidden="true"
                  ></i>
                </span>
                Dashboard
              </Link>
            </h5>
            <div className="serve-div-22">
              <div className="your_resorce">
                <h5>Your Resources</h5>
              </div>
              <div className="select-boxes-serv">
                <div className="select-op-serve">
                  <button
                    className="btn btn-md float-end addresource"
                    onClick={() => { navigate("/serve") }}
                  >
                    <i className="fa fa-plus" ></i>&nbsp; Add Resource{" "}
                  </button>
                </div>
                <div className="select-op-serve">
                  <Multiselect className="form-select w-100 float-start saveResourceSelectss"
                    options={orderByOptions}
                    onSelect={handleOrderSelection}
                    displayValue="name"
                    placeholder="Order By"
                    showArrow={true}
                    showCheckbox
                    selectedValues={ordered ? [orderByOptions.find(item => item.value === ordered)] : []}
                  />
                </div>
                <div className="input-btn-contain">
                  <div className="input-btn-contains">
                    <input
                      type="text"
                      className="input-btn-icon-icon"
                      placeholder="Title Search"
                      onChange={(e) => setKeywords(e.target.value)}
                      value={Keywords}
                      name="keywords"
                    />
                  </div>{" "}
                </div>
                <form
                  className="savebtnsearch"
                  onSubmit={(e) => {
                    searchKeywords(e);
                  }}
                >
                  <button> <i className="fa fa-search iconsearch" /></button>
                </form>
              </div>
            </div>
          </div>
          <div className="clearnone"></div>
          <div className="main-card-container">
            {loading ?
              <ul>
                <li>
                  <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                  </div>
                </li>
              </ul> :
              <>
                <Cardcomponent1
                  resources={resourcesData}
                  setDeleteApiRes={setDeleteApiRes}
                  setItemPerPage={setItemPerPage}
                  itemPerPage={itemPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  group={false}
                  getresources={getAllResources}
                />
                {hasMore > 0 && spinStatus && (
                  <div className="show-more">
                    <button
                      className="border-0 btn-md btn"
                      onClick={() => {
                        handleLoadMore();
                        if (spinStatus) {
                          setLoading(true)
                        } else {
                          setLoading(false)
                        }
                      }
                      }
                    >
                      Show more results{" "}
                      <span>
                        <i
                          className="fa fa-angle-down downicon"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </button>
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default Serve_yourresources;
