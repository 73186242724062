/**
     * Subscription Buy for subscription and redirect to stripe payment getway
     * @author Gaurang Ghadiya <ghadiya.gaurang@webappmate.com>
     * created on: 22-05-2023
     */
import React, { useState, useEffect } from "react";
import "./SubscriptionBuy.css";
import { useLocation, useNavigate } from "react-router-dom";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { makeGetRequest, makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

function SubscriptionBuy() {
  const [isLoading, setIsLoading] = useState(false);
  let user_id = decodeToken().userAuth.id;
  const location = useLocation();
  const navigate = useNavigate()
  const state = location.state;

  //Payment request
  const paynow = async () => {
    setIsLoading(true)
    const body = {
      subscription_list: state?.subscription_list,
      user_id: +user_id,
    }
    const upgrade = {
      subscription_list: state?.subscription_list,
    }
    if (state?.isUpgrade) {
      await makePostRequest(URLS.subscriptionUpgrade, null, upgrade, null)
        .then((response) => {
          if (response?.data?.payment_url != null) {
            localStorage.setItem("unique_id", response?.data?.payment_intent);
            localStorage.setItem("zeroPrice", "no");
            window.open(response?.data?.payment_url, "_Self");
          } else {
            makeGetRequest(URLS.token, null, null).then((re) => {
              toastifyMessage("Subscription purchase successful.", "success");
              localStorage.setItem("auth-token", re?.data?.authorization);
              navigate("/search-page")
            });
          }
        })
        .catch((e) => {
          setIsLoading(false)
        });
    } else {
      await makePostRequest(URLS.freeBuySubscription, null, body, null)
        .then((response) => {
          if (response?.data?.payment_url != null) {
            localStorage.setItem("unique_id", response?.data?.payment_intent);
            localStorage.setItem("zeroPrice", "no");
            window.open(response?.data?.payment_url, "_Self");
          } else {
            makeGetRequest(URLS.token, null, null).then((re) => {
              toastifyMessage("Subscription purchase successful.", "success");
              localStorage.setItem("auth-token", re?.data?.authorization);
              navigate("/search-page")
            });
          }
        })
        .catch((e) => {
          setIsLoading(false)
        });
    }
  };

  useEffect(() => {
    document.body.classList.add("purchase-page");
    return () => {
      document.body.classList.remove("purchase-page");
    };
  }, []);


  return (
    <>
      <div className="col-lg-10">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            {isLoading ? <div className="sharepopupOuter backgroundOpacity1">
              <FourDotsLoader />
            </div> :
              <div className="purcahse-information">
                <div className="purchase-inner-information">
                  <div className="icontext">
                    <h4 className="pinformation">Purchase Information</h4>
                  </div>
                  <div className="sprice">
                    <label className="mentor-session">Title</label>
                    <span className="pricetext">Price</span>
                  </div>
                  <hr></hr>
                  {state?.subscription_list?.map(list => {
                    return <><div className="sprice">
                      <label className="text-capitalize">{list?.title} ({list?.payment_frequency_type})</label>
                      <span>${list?.price?.toFixed(2)}</span>
                    </div>
                      <br></br>
                    </>
                  })}
                  {state?.coupen_amount !== 0 && state?.payment_for === "subscription" &&
                    <div className="sprice mb-2">
                      <label className="text-capitalize">Coupon Amount{" "}:</label>
                      <span className="text-success">&nbsp; -${state?.coupen_amount?.toFixed(2)}</span>
                    </div>
                  }
                  {console.log(state, "state")}
                  <div className="sprice">
                    <label>Tax Amount{" "}:</label>
                    <span>&nbsp;${state?.tax_amount?.toFixed(2)}</span>
                  </div>
                  <hr></hr>
                  <div className="totalamount">
                    <label>Total{" "}:</label>
                    <label>&nbsp;
                      {state?.payment_for === "subscription" ?
                        <> ${(parseFloat(state?.amount) - parseFloat(state?.coupen_amount)).toFixed(2)} </> :
                        <>${state?.total_amount?.toFixed(2)}</>}

                    </label>
                  </div>
                </div>
                <div>
                  {parseInt(state?.total_amount) === 0 ?
                    <button className="dinvoice1" onClick={paynow}>
                      You have signed up for a MatchED free subscription.
                    </button>
                    :
                    <button className="dinvoice1" onClick={paynow}>
                      Pay Now
                    </button>}
                </div>
              </div>
            }
          </div>
          <div className="col-md-4 col-lg-4">
          </div>
        </div>
      </div>
    </>
  );
}
export default SubscriptionBuy;