// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failed{
    color: red;
    display: grid;
    place-items: center;
    height: 65vh;
    font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PurchaseResources.WebAppMate/repayment.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".failed{\n    color: red;\n    display: grid;\n    place-items: center;\n    height: 65vh;\n    font-size: 22px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
