import './notificationmodal.css';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import { useState, } from "react";
import NotificationThreedotspopup from "../Layout.WebAppMate/NotificationThreedotspopup";
import { toastifyMessage } from '../../utilities/CustomFunctions';
import { makePostRequest } from '../../services/api';
import { URLS } from '../../constants';
import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;

const Notificationmodal = (props) => {
    let cdate = new Date();
    const navigate = useNavigate()
    const [show, setshow] = useState(false);
    //  this function to handle notification status
    const notificationRead = (id) => {
        makePostRequest(URLS.markSingleNotification, null, { "notification_id": id }, null)
        .then((res) => {
            if (res.code === 200) {
                console.log("res", res)
                props.funCall()
            }
        }).catch((error) => {
            console.log("error::::", error)
        })
    }
    const notificationStatus = (id, status) => {
        const data = {
            "notification_id": id,
            "status": status
        }
        makePostRequest(URLS.notificationStatus, null, data, null)
            .then((res) => {
                console.log("res notification", res)
                if (res.code === 200) {
                    toastifyMessage(res.message, "success")
                } else {
                    toastifyMessage(res.message, "warn")
                }
                props.funCall()
                notificationRead(id);
            }).catch((error) => {
                console.log("error::::", error)
            })
    }
    // this function create to handle readmore 
    const ReadMore = ({ children }) => {
        const text = children[0];
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        const notificationClick = (date) => {
            const data = {
                "notification_id": children[2]
            }
            console.log("payload",data)
            makePostRequest(URLS.getViewNotification, null, data)
                .then((res) => {
                    notificationRead(children[2]);
                    console.log("res onClick+++++++++++", res)
                    if (res?.data?.notifications?.redriect_type === "no_action") {
                        return;
                    } else if (res?.data?.notifications?.redriect_type === "group") {
                        if (res?.data?.notifications?.action_status === "1") {
                            navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}?go=${res?.data?.notifications?.id}`)
                        } else {
                            navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}`)
                        }
                    }
                    else if (res?.data?.notifications?.redriect_type === "chat_group") {
                        navigate(`/groupjoin/${res?.data?.notifications?.action_id}?passKey=chat`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "zendesk") {
                        navigate(`/ticket-detail-page`, { state: { ticketid: res?.data?.notifications?.action_id } })
                    }
                    else if (res?.data?.notifications?.redriect_type === "subscriptions") {
                        navigate(`/my-subscriptions`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "friend") {
                        navigate(`/user-profile/${res?.data?.notifications?.save_id}`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "resource_details") {
                        if (res?.data?.notifications?.notification_type === "RESOURCE_REVIEW") {
                            navigate(`/full-description/${res?.data?.notifications?.save_id}?passKey=pass`)
                        } else {
                            navigate(`/full-description/${res?.data?.notifications?.save_id}`)
                        }
                    }
                    else if (res?.data?.notifications?.redriect_type === "friend_list" && res?.data?.notifications?.notification_type !== "NEW_CHAT_MESSAGE") {
                        navigate(`/user-profile/${res?.data?.notifications?.save_id}`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "child") {
                        if (res?.data?.notifications?.action_status === "1") {
                            navigate(`/child-profile-page/${res?.data?.notifications?.save_id}?go=${res?.data?.notifications?.id}`)
                        } else {
                            navigate(`/child-profile-page/${res?.data?.notifications?.save_id}`)
                        }
                    }
                    else if (res?.data?.notifications?.redriect_type === "invoice_resource" || res?.data?.notifications?.redriect_type === "user_payment" ) {
                        navigate(`/server-payment-history`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "mentor_payment") {
                        navigate(`/sale-history?go=${res?.data?.notifications?.save_id}&resource=${res?.data?.notifications?.save_id}&date=${res?.data?.notifications?.tbl_user_mentoring_session_datetim?.user_session_date}`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "mentoring_booking_session") {
                        navigate(`/session-history`, {state : {status : "mentoring_booking_session", create_date : res?.data?.notifications?.notification_type === "MENTORING_SESSION_RESCHEDULED" ?  res?.data?.notifications?.updated_at : date, res_id : 
                            res?.data?.notifications?.save_id , child_id : res?.data?.notifications?.child_id,
                        }})
                    }
                    else if (res?.data?.notifications?.redriect_type === "user_mentoring_payment") {
                        navigate(`/server-payment-history?go=${res?.data?.notifications?.id}`)
                    }
                    else if (res?.data?.notifications?.redriect_type === "resource_share") {
                        navigate(`/share-inspiration`)
                    }
                    props.setmodal(false)
                }).catch(e => {
                    console.log(e)
                })
        }
        return (
            <p className="text" style={{ cursor: "pointer" }}>
                <span onClick={() => notificationClick(children[4])}> {isReadMore ? text.slice(0, 170) : text}</span>
                {
                    text.length > 170 ? <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: "pointer" }}>
                        {isReadMore && <span>...read more</span>}
                    </span> : ""
                }
            </p>
        );
    };
    
    return (<>
        <div className="modal-container" ref={props.forwardedref}>
            <div className='modal-div-1'>
                <div className='row'>
                    <div className='col-6'>
                        <h4 className='notification-text'>Notifications<span className='all' onClick={props.datar}><Link to="/Notification">All</Link></span></h4>
                    </div>
                    <div className='col-5'>
                    </div>
                    <div className='col-1 notificatonThreedostsInnerbox'>
                        <span onClick={() => { setshow(!show); }} className="three-dots"><i className="fa fa-ellipsis-v"></i>
                            {show && show === true ?
                                <NotificationThreedotspopup funCall={props.funCall} /> : null}
                        </span>
                    </div>
                </div>
            </div>
            <div className='modal-div-inner'>
                {props.notice.map((val, index) => {
                    return (
                        <div className='modal-div-2'>
                            <div className='text-container-noti'>
                                {val.status === "read" ? "" : <span className='circle'></span>}
                                <img src={val.photo} className='image-noti' alt='notification' loading="lazy" />
                                <div className="noti-para">
                                    <ReadMore>{val.message} {val.id} {val?.created_at}</ReadMore>
                                </div>
                            </div>
                            <div className='modal-btn-div'>
                                {
                                    val.action_status === "1" && <div className="acceptReject">
                                        <button className='Accept-btn' onClick={() => notificationStatus(val.id, "accepted")}>Accept</button>
                                        <button className='reject-btn' onClick={() => notificationStatus(val.id, "rejected")}>Reject</button>
                                    </div>
                                }
                                {
                                    val.notification_type === "NEW_CHAT_MESSAGE" &&
                                    <div className="acceptReject">
                                        <button className="accept-btn" onClick={() => {
                                            props.add(val?.message_from_user, { "name": val?.name, "image": val?.photo })
                                            notificationRead(val?.id)
                                        }}>Chat</button>
                                    </div>

                                }
                            </div>
                            <h5 className='time'>{new Date(val.created_at).getDate() === cdate.getDate() && new Date(val.created_at).getMonth() === cdate.getMonth() ? "today" : new Date(val.created_at).getDate() === cdate.getDate() - 1 && new Date(val.created_at).getMonth() === cdate.getMonth() ? "yesterday" : dayjs(val.created_at).format("MM/DD/YYYY HH:MM:ss")}</h5>
                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}
export default Notificationmodal;