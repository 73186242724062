import React, { useEffect, useState } from "react";
import { toastifyMessage } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { makeFormDateRequset, makePostRequestForAll } from "../../services/api";
import AddGroupMember from "./AddGroupMember";
import Trigonometry from "../../assets/Images/groupdefult.jpeg";

/**
 * GroupSetting component is used to manage group settings.
 * It allows users to create a new group or update existing group settings.
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const GroupSetting = (props) => {
    const [groupName, setGroupName] = useState("");
    const [file, setFile] = useState("");
    const [openGruop, setOpenGroup] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [image, setImage] = useState(null);
    const [error, setError] = useState("");
    const [admin, setAdmin] = useState("");
    const [grpData, setGrpData] = useState({
        image: "",
        name: "",
        admin: ""
    });

    // Function to save group data
    const SaveData = () => {
        if (groupName === "") {
            setError(true);
        } else {
            // If userMapId exists, update group settings
            if (props.userMapId) {
                const data = {
                    "id": props.userMapId,
                    "userList": [],
                    "group_name": groupName,
                    "name_status": "yes"
                };
                makePostRequestForAll(URLS.updateMultiplechatUser, data)
                    .then((res) => {
                        if (res.code === 200) {
                            toastifyMessage(res.message, "success");
                            setOpenGroup(true);
                            props?.onClose();
                            if (props.pageStatus && props?.admin === true) {
                                props?.callback({ name: groupName, image: file, admin: true });
                            } else {
                                props?.callback({ name: groupName, image: file });
                            }
                        } else if (res.code === 400) {
                            toastifyMessage(res.message, "warn");
                        }
                    })
                    .catch((e) => console.log("error", e));
            } else {
                // If userMapId does not exist, create a new group
                const data = {
                    "userList": [],
                    "group_name": groupName
                };
                makePostRequestForAll(URLS.addMultiplechatUser, data)
                    .then((res) => {
                        toastifyMessage(res.message, "success");
                        setGroupId(res?.data.id);
                        setAdmin(true);
                        props?.funCall();
                        if (image) { // If image is selected, upload it
                            const formData = new FormData();
                            formData.append("id", res?.data?.id);
                            formData.append("image", image);
                            makeFormDateRequset(URLS.UploadMultiChatImage, formData)
                                .then(res => {
                                    toastifyMessage(res.message, "success");
                                    setGrpData({ name: groupName, image: URL.createObjectURL(image), admin: true });
                                    setFile("");
                                });
                        }
                    })
                    .catch((e) => console.log("error", e));
            }
        }
    };

    // Effect to set initial values for groupName, file, and image
    useEffect(() => {
        props?.groupName && setGroupName(props?.groupName);
        props?.detail?.name && setGroupName(props?.detail?.name);
        props?.detail?.image && setFile(props?.detail?.image);
    }, [props?.detail, props?.groupName]);

    // Function to handle file input change
    const handleInput = (e, id) => {
        const checkfile = e.target.files;
        if (checkfile[0]) {
            const fileSize = checkfile.size / (1024 * 1024);
            if (fileSize > 2) {
                toastifyMessage("File size exceeded, Please upload file up to 2 mb", "error");
            } else if (
                checkfile[0].name.includes("png") ||
                checkfile[0].name.includes("jpg") ||
                checkfile[0].name.includes("jpeg")
            ) {
                setFile(URL.createObjectURL(e.target.files[0]));
                const formData = new FormData();
                if (props?.userMapId) {
                    formData.append("id", props?.userMapId);
                    formData.append("image", checkfile[0]);
                    makeFormDateRequset(URLS.UploadMultiChatImage, formData)
                        .then(res => {
                            if (res.code === 200) {
                                toastifyMessage(res.message, "success");
                                if (props?.pageStatus && props.admin === true) {
                                    props?.callback({ name: groupName, image: URL.createObjectURL(e.target.files[0]), admin: true });
                                } else {
                                    props?.callback({ name: groupName, image: URL.createObjectURL(e.target.files[0]) });
                                }
                            }
                        });
                } else {
                    setImage(checkfile[0]);
                }
            } else {
                toastifyMessage("Please upload only png,jpg,jpeg file", "error");
            }
        }
    };

    return (
        <>
            <div>
                <div className="viewMemberchatpopup sharepopupOuter">
                    <div className="chatboxview chatboxviewNew">
                        <button className="close-btn" onClick={props.onClose}>
                            <i className="fa fa-close closecs"></i>
                        </button>
                        <h1 className="add-title">Chat Setting</h1>
                        <div>
                            <div className="chat-content-setting">
                                <div className="group_image">
                                    {file ?
                                        <img src={file} alt="groupImage" loading="lazy" /> : <img src={Trigonometry} alt="groupImage" loading="lazy" />
                                    }
                                </div>
                                <div className="r-title-div chatsettingfront">
                                    <div className="inner-chat">
                                        <span className="u-content">
                                            {" "}
                                            <i className="fa fa-cloud-upload uploadicon"></i>
                                            <span>
                                                {"Upload Image"}
                                            </span>
                                        </span>
                                        <input
                                            type="file"
                                            placeholder=""
                                            className="input-file-upload"
                                            onChange={handleInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group my-3">
                            <label className="form-label"> Group Name <span className="start-color">*</span> </label>
                            <input
                                type=""
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Name"
                                onChange={(e) => setGroupName(e.target.value.trimStart())}
                                value={groupName}
                            />
                            {error && groupName === "" ? <span className="error-span"> Please add group name
                            </span> : ""}
                        </div>
                        <div className="grouplist text-center mt-5 pb-4">
                            <button className="add-group"
                                onClick={() => {
                                    SaveData();
                                    if (props?.pageStatus) { // Add member popup will not open if group setting is opened from single chat
                                        setOpenGroup(false);
                                    } else if (groupName !== "") {
                                        setOpenGroup(true);
                                    } else {
                                        setOpenGroup(false);
                                    }
                                }}  >Save</button>
                        </div>
                    </div>
                </div>
                {openGruop && <AddGroupMember open={openGruop} id={groupId}
                    onClose={() => {
                        setOpenGroup(false);
                        props?.onClose();
                        props?.callback(groupId, { name: groupName, admin: true, image: image ? URL.createObjectURL(image) : Trigonometry }, 1)
                        // This callback function is used to open chat screen after creating group instantly
                    }} funCall={() => { props?.funCall(); }} groupName={groupName} grpData={grpData} admin={admin}
                />
                }
            </div>
        </>
    );
};

export default GroupSetting;
