import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { URLS } from '../../constants';
import { toastifyMessage } from '../../utilities/CustomFunctions';
import { makePostRequest } from '../../services/api';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "16px",
    boxShadow: 24,
    p: 6,
};

// this function use for announcement chat or resource  confirmation 
const Announce = (props) => { 
    const announcementChat = (status) => {
        const data = {
            "massageUniqueId": props.messageId,
            "group_announcement": status
        }
        makePostRequest(URLS.Announcement, null, data, null)
            .then((res) => {
                toastifyMessage(res.message, "success")
                props?.type === "no" && props?.getShreResource()
                props.handleClose();
            }).catch((error) => {
                props.handleClose()
                console.log("error", error)
            })
    }
    return (
        <div>  <Modal
            open={props.announcement}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className="text-center"
                    style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                >
                    {
                        props?.type === "yes" ? <h3>Are you sure you want to announce this message?</h3> : <h3>Are you sure you want to remove this announcement?</h3>
                    }
                </Typography>
                <Typography
                    id="modal-modal-description"
                    sx={{ mt: 3 }}
                    className="d-flex justify-content-center gap-4">
                    {
                        props?.type === "yes" ? <button className="child-delete" onClick={() => announcementChat("yes")}>
                            Yes
                        </button> : <button className="child-delete" onClick={() => announcementChat("no")}>
                            Yes
                        </button>
                    }
                    <button
                        className="child-cancel"
                        onClick={() => props.handleClose()}
                    >
                        No
                    </button>
                </Typography>
            </Box>
        </Modal></div >
    )
}

export default Announce