import React, { useEffect, useState, useRef } from "react";
import Cardcomponent from "../ViewProfile.WebAppMate/Cardcomponent";
import Multiselect from "multiselect-react-dropdown";
import { Tooltip } from "@mui/material";
import { Pie, PieChart } from "recharts";
import { useNavigate, useParams } from "react-router-dom";
import { decodeToken ,toastifyMessage} from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { makeGetRequest, makePostRequest } from "../../services/api";
import { imageUrl } from "../Common.WebAppMate/FullDescription";
import Childsprofilepopup from "../ViewProfile.WebAppMate/Childsprofilepopup";
import Permissionpopup from "./PermissionPopup";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { statusData, viewResult } from "../ViewProfile.WebAppMate/Child_profile_page";

const UserProfile = () => {
  const parmas = useParams();
  let userData = decodeToken();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [childId, setChildId] = useState("")
  const [resourceData, setresourcesData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showspin, setshowspin] = useState(false);
  const [status, setstatus] = useState("");
  const [keyword, setkeyword] = useState("");
  const [limit, setlimit] = useState(9);
  const [gradeLevelData, setGradeLevelData] = useState([]);
  const [gradeRange, setGradeRange] = useState([]);
  const [UserProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [openIndex, setOpenIndex] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [remaining, setRemaining] = useState(true);
  let my_token = localStorage.getItem("auth-token");
  let useractive = localStorage.getItem("useractive");
  let uimage = imageUrl;
  const headers = { Authorization: "Bearer " + my_token };
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);
  const [three, setthree] = useState(false);

  const showdosts = (i) => {
    if (three === false) {
      setthree(true);
    } else {
      setthree(false);
    }
  };

  // featch the grade
  const getGradeLevelData = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setGradeLevelData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //api to fetch Parent Saved Resources
  const getchildprofile = () => {
    setLoading(true);
    const body = {
      storing_type: "",
      grade: gradeRange,
      status: status,
      searchKey: keyword,
      page_number: pageNum,
      user_id: id,
      item_per_page: parseInt(limit)
    };
    console.log("body", body)
    makePostRequest(URLS.getUserProfile, null, body, null)
      .then((response) => {
        console.log("response", response)
        setUserProfile(response?.data);
        setshow(response?.data?.is_friend === "no" ? true : false);
        if (response.code === 400) {
          setshow(true)
        }
        const save = [];
        const dump = [];
        response?.data?.save?.forEach((val) => {
          return save.push(val.resource_id)
        })
        const arr = pageNum === 1 ? response?.data?.resourceData : [...resourceData, ...response?.data?.resourceData]
        for (var i = 0; i < arr.length; i++) {
          if (save.includes(arr[i].id)) {
            const updatedObject = {
              ...arr[i],
              save: "yes"
            };
            dump.push(updatedObject)
          } else {
            const updatedObject = {
              ...arr[i],
              save: "no"
            };
            dump.push(updatedObject)
          }
        }
        setresourcesData(dump);
        setLoading(false);
        if (response?.data?.resourceCount?.remaining_count === 0) {
          setRemaining(false);
        } else {
          setRemaining(true)
        }
      })
      .catch((r) => {
        setLoading(false);
      });
  };

  // this function use for get Parent Saved Resources
  const searchData = () => {
    if (pageNum === 1) {
      getchildprofile()
    } else {
      setPageNum(1)
    }

  }
  //functions to make learning pillars chart
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            {`${payload[0].name} : ${payload[0].value}`} %
          </p>
        </div>
      );
    }

    return null;
  };

  // handle grade 
  const handleGrade = (selectedList) => {
    let arr = [];
    selectedList?.forEach((val, i) => {
      arr.push(val.id);
    });
    setGradeRange(arr);
  };

  useEffect(() => {
    id && getchildprofile()
    // eslint-disable-next-line
  }, [pageNum, limit])

  useEffect(() => {
    getGradeLevelData();
    document.body.classList.add("profilepage");
    return () => {
      document.body.classList.remove("profilepage");
    };
  }, []);

  // useEffects calls
  useEffect(() => {
    if (parmas.id) {
      setId(parmas.id);
    }
  }, [parmas]);

  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in");
    }
    if (id) {
      getchildprofile();
    }
    // eslint-disable-next-line
  }, [id]);

  // this function is use for invite the friend
  const onInvite = (email) => {
    setshowspin(true);
    let data = {
      user_id: userData.userAuth?.id,
      emailList: [{ email }],
    };
    makePostRequest(URLS.createAddInviteFriend, null, data, { headers }).then(
      (response) => {
        toastifyMessage(response?.message, "success");
        setshowspin(false);
      }
    );
  };

  // this function use for Accept or reject the request
  const AcceptRejectRequest = (status, id) => {
    setshowspin(true);
    let data = {
      //id: id,
      id: UserProfile?.friend?.id,
      status,
      last_updated: Date.now(),
    };
    makePostRequest(URLS.acceptOrRejectRequestFriend, null, data, {
      headers,
    }).then((response) => {
      getchildprofile();
      toastifyMessage(response?.message, "success");
      setshowspin(false);
    });
  };
  const handleClickOutside = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnRef.current.contains(e.target)
    ) {
      setthree(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);
  return (
    <>
      {loading ? (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      ) : (
        <div className="col-lg-10">
          <div className="row  margint">
            <div className=" col-lg-9 ">
              <div className="profile-page-card user-profileinnerBox">
                <div className="graph-img showpiePercentage">
                  <PieChart width={200} height={200}>
                    <Pie dataKey="students" outerRadius={90} />
                    <Tooltip
                      position={{ x: 0, y: 0, width: 400, height: 200 }}
                      content={<CustomTooltip />}
                    />
                  </PieChart>
                  {showspin && (
                    <div className="sharepopupOuter backgroundOpacity1">
                      <FourDotsLoader />
                    </div>
                  )}
                  <img
                    src={uimage + UserProfile?.userDetails?.photo}
                    alt=""
                    className="image-c-p"
                  />
                </div>
                <div className="child-profile-content">
                  <h3 className="text-capitalize">
                    {UserProfile?.userDetails?.firstName}{" "}
                    {UserProfile?.userDetails?.lastName}
                  </h3>
                  <p className="mb-3">{UserProfile?.userDetails?.email}</p>
                  <div className="userprofileDescBox square scrollbar-dusty-grass square thin">
                    <div className="card-body">
                      <p>
                        {UserProfile?.userDetails?.bio_about}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
              <div className="chlid-pro-butttnss parentrightchildlitBox">
                {UserProfile?.friend?.status === "accepted" ? (
                  <>
                    <button className="childListButton">
                      Student List
                    </button>
                    <div className="userprofilerightscroll square scrollbar-dusty-grass square thin">
                      <div className="card-body">
                        {UserProfile?.childList?.map((value, index) => {
                          return (
                            <div className="icon-handle img-icon">
                              <div className="d-flex align-items-center">
                                <img
                                  src={uimage + value?.photo}
                                  className="img-jackey"
                                  alt="user"
                                />
                                <p className="mt-1 ms-2 text-capitalize">
                                  {value?.firstName} {value?.lastName}
                                </p>
                              </div>
                              <span
                                ref={menuBtnRef}
                                onClick={() => {
                                  showdosts();
                                  setOpenIndex(index);
                                }}
                                className="profilethreedots"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                                {three === true && index === openIndex ? (
                                  <div className="remove-res" ref={menuRef}>
                                    {value?.is_permission === "no" ? (
                                      <h5 onClick={() => { setOpen(true); setChildId(value.id) }}>
                                        Request Permission
                                      </h5>
                                    ) :
                                      <h5
                                        onClick={() =>
                                          navigate(
                                            `/child-profile-update-view/${value?.id}`
                                          )
                                        }
                                      >
                                        View Resource
                                      </h5>
                                    }
                                  </div>
                                ) : null}
                              </span>
                            </div>
                          );
                        })}

                        {open === true ? (
                          <Permissionpopup
                            data={() => setOpen(false)}
                            userId={childId}
                            perantId={UserProfile?.userDetails?.id}
                          />
                        ) : null}
                      </div>
                    </div>

                  </>
                ) : (UserProfile?.friend?.status === "pending" && UserProfile?.is_login_user === "no") ? (
                  <>
                    <button
                      className="accpectbutton"
                      style={{ width: "100%", background: "#265472" }}
                      onClick={() =>
                        AcceptRejectRequest(
                          "accepted",
                          UserProfile?.userDetails?.id
                        )
                      }
                    >
                      Accept
                    </button>
                    <button
                      style={{ width: "100%", background: "#e8f0f4", color: "#265472" }}
                      className="declinebutton"
                      onClick={() =>
                        AcceptRejectRequest(
                          "rejected",
                          UserProfile?.userDetails?.email
                        )
                      }
                    >
                      Decline
                    </button>
                  </>
                ) : (UserProfile?.friend?.status === "pending" && UserProfile?.is_login_user === "yes") ?
                  <>
                    <button
                      className="accpectbutton"
                      style={{ width: "100%", background: "#ffffff", color: "#65676b" }}
                    >
                      Pending
                    </button>
                  </>
                  : UserProfile?.friend?.status === "rejected" ? (
                    <button
                      onClick={() =>
                        onInvite(UserProfile?.userDetails?.email)
                      }
                    >
                      Invite
                    </button>
                  ) : (
                    ""
                  )}
              </div>
            </div>
          </div>
          <div className="handle-heading-text-child">
            <h4><span className="text-capitalize-custome">{UserProfile?.userDetails?.firstName ?
              <span ><span className="text-capitalize">{UserProfile?.userDetails?.firstName}</span>&#39;s Saved Resources</span>
              : <span> Saved Resources</span>}</span></h4>
          </div>
          <div className="searchres-div11">
            <div className="search-exp-sec-div1">
              <h3>Refine search results</h3>
              <div className={`childprofilegradeRange ${(gradeLevelData && gradeRange.length > 2) ? "gradeLevelDataSelect" : "gradeLevelDataMultipleSelect"}`}>
                <div className="form-select">
                  <Multiselect
                    disable={show === true ? true : false}
                    options={gradeLevelData}
                    onSelect={handleGrade}
                    onRemove={handleGrade}
                    showCheckbox
                    displayValue="name"
                    placeholder="Grade Range"
                    showArrow={true}
                    selectedValues={gradeRange && gradeLevelData.filter((grade) => gradeRange.includes(grade.id))}
                  />
                </div>
              </div>
              <div className="saveResourceListgradeRange">
                <div className="form-select">
                  <Multiselect
                    options={statusData}
                    onSelect={(e) => {
                      e?.forEach((val, i) => {
                        setstatus(val.value);
                      });
                    }}
                    onRemove={() => setstatus("")}
                    displayValue="name"
                    placeholder="Status"
                    disable={show === true ? true : false}
                    showArrow={true}
                    showCheckbox
                    selectedValues={status ? [statusData.find(item => item.value === status)] : []}
                  /></div></div>
              <div className="input-btn-con">
                <input
                  type="text"
                  placeholder="Keyword Search"
                  onChange={(event) => setkeyword(event.target.value)}
                  disabled={show === true ? true : false}
                />
              </div>
              <button onClick={searchData} disabled={show === true ? true : false}>
                <i className="fa fa-search iconsearch" />
              </button>
            </div>
          </div>
          <div className="saveResourceListgradeRange">
            <div className="form-select">
              <Multiselect
                options={viewResult}
                onSelect={(e) => {
                  e?.forEach((val, i) => {
                    setlimit(val.value);
                  });
                }}
                displayValue="name"
                showCheckbox
                showArrow={true}
                selectedValues={limit ? [viewResult.find(item => parseInt(item.value) === parseInt(limit))] : []}
              /></div></div>
          <div className="card-container-handle profileCardContainer">
            <div className="row">
              {show === true ? (
                <div className="user-request"> <Childsprofilepopup pending={UserProfile?.friend?.status}
                  friendId={UserProfile?.userDetails?.id} funCall={getchildprofile} /></div>
              ) : resourceData?.length > 0 ? (
                resourceData?.map((temp) => (
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <Cardcomponent
                      res_saved_id={temp?.id}
                      resource_id={temp?.id}
                      res_title={temp?.resource_title}
                      res_desc={temp?.description}
                      res_fund={temp?.public_funding}
                      res_type={temp?.resource_type}
                      res_piller={temp?.tbl_pillar_resource_mappings}
                      res_rating={temp?.rating}
                      res_review={temp?.total_reviews}
                      res_viewable={temp.viewable_by_my_friends}
                      res_len={UserProfile?.resourceData?.length}
                      childId={id}
                      getresources={getchildprofile}
                      shared_by={temp.shared_by_user}
                      save={temp.save}
                      resource_thumbnail_image={temp.tbl_user_resource?.resource_thumbnail_image}
                      imageLink={temp?.tbl_user_resource?.imageLink}
                    />
                  </div>
                ))
              ) : (
                <div>No Data Found</div>
              )}
            </div>
          </div>
          <div className="show-more my-3" onclick={window.scrollTo({ bottom: 0, behavior: 'smooth' })}>
            {
              show === false && remaining && <p className="text-center" onClick={() => setPageNum(pageNum + 1)}>Show more</p>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
