import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { toastifyMessage, style } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
/**
 * create components is used to delete child profile
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

const DeleteMemberAdmin = (props) => {

    const [spin, setSpin] = useState(false)

    // this function is used for delete child 
    const deleteMember = () => {
        setSpin(true)
        var payload = {
            "id": props.id,
            "status": "",
            "remove_type": "admin"
        }
        console.log("payload::", payload)
        makePostRequestForAll(URLS.removeMember, payload)
            .then((res) => {
                console.log("res::", res)
                setSpin(false)
                if (res.code === 200) {
                    toastifyMessage(res.message, "success")
                    props.adminList(props.funCall)
                }
                props.handleClose();
            })
            .catch((error) => {
                console.log("error", error);
                setSpin(false)
            });
    };

    return (
        <>
            {spin && (
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            )}
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                    >
                        {
                            props?.AdminBox ? <>
                                Are you sure you want to leave this group ?


                            </> : <>Are you sure you want to remove this Admin ?</>
                        }
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button className="child-delete" onClick={deleteMember}>
                            {
                                props?.AdminBox ? <>
                                    Leave
                                </> : <>Remove</>
                            }
                        </button>{" "}
                        <button className="child-cancel" onClick={props.handleClose}>
                            Cancel
                        </button>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default DeleteMemberAdmin;
