import React from "react";
import './explore.css';
import "../Search.WebAppMate/rating.css";
import { useEffect, useState } from "react";
import { makePostRequest, imageUrl } from "../../services/api";
import { URLS } from "../../constants";
import "../Search.WebAppMate/rating.css";
import img1 from "../../assets/Images/sr-img1.png";
import ph22 from "../../assets/Images/ph22.png";
import srimg4 from "../../assets/Images/sr-img4.png";
import srimg3 from "../../assets/Images/sr-img3.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import ph5 from "../../assets/Images/ph5.png";
import { Link } from "react-router-dom";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { renderRatingStars } from "../Search.WebAppMate/RenderCardInfo";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useNavigate } from "react-router-dom";
import { openRouteInNewTab } from "../Search.WebAppMate/RenderCardInfo";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { renderDescription, toSeoUrl } from "../../services/common.services"
const Explore = () => {
  let uimage = imageUrl;
  const navigate = useNavigate();
  let activeuser = localStorage.getItem('useractive');
  const [resources, setResources] = useState([])
  const [sliderRef, setSliderRef] = useState(null)
  const [spin, setSpin] = useState([])
  const sliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
        },
      },
    ],
  }
  useEffect(() => {
    let data = {
      "page_number": 1
    }
    setSpin(true)
    makePostRequest(URLS.getallresourceshomepage, null, data, null)
      .then((res) => {
        setSpin(false)
        if (res.code === 200) {
          setResources(res.data.data);
        }
      }).catch((error) => {
        setSpin(false)
      })
  }, [])
  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1 fixedOuter">
          <FourDotsLoader />
        </div>
      )}
      <div className="explore-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="explore-resources">Explore resources</h3>
              <button className="explore-all"><Link to="/search-page"> Explore all</Link></button>
            </div>
          </div>
          <div className="main-card-container">
            <div className="row">
              <button onClick={sliderRef?.slickPrev}>
                <ArrowBackIosNewOutlinedIcon onClick={sliderRef?.slickPrev} />
              </button>
              <button onClick={sliderRef?.slickNext}>
                <ArrowForwardIosOutlinedIcon onClick={sliderRef?.slickNext} />
              </button>
              <Slider ref={setSliderRef} {...sliderSettings}>
                {resources?.map((val, i) => {
                  return (
                    <div className="col-lg-3">
                      <div className="card-container px-2">

                        <div className="img-icon" style={{ cursor: "pointer" }} onClick={() => {
                          !activeuser ? navigate("/sign-in") : openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                        }}>

                          {
                            val?.resource_thumbnail_image ?
                              <div className="resource-img" onClick={() =>
                                openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                              }><img src={imageUrl + val?.resource_thumbnail_image} className="resource-image2" alt="matched resource" />
                              </div>
                              : <>
                                {
                                  (val?.imageLink && !val?.imageLink.includes("booksamillion.com")) ? <div className="resource-img" onClick={() =>
                                    openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                  }><img src={val?.imageLink} className="resource-image2" alt="matched " />
                                  </div> : <>
                                    {val?.resource_type === 7 && (
                                      <div className="img-Facilities" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={ph5} className="curriculum-image2" alt="Facility " />
                                      </div>
                                    )}
                                    {val?.resource_type === 6 && (
                                      <div className="img-funding" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={srimg5} className="curriculum-image2" alt="funding " />
                                      </div>
                                    )}
                                    {val?.resource_type === 3 && (
                                      <div className="img-div1" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={ph22} className="curriculum-image2" alt="Experiences" />
                                      </div>
                                    )}
                                    {val?.resource_type === 4 && (
                                      <div className="img-div4" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={srimg3} className="student-image1" alt="Student Support " />
                                      </div>
                                    )}
                                    {val?.resource_type === 2 && (
                                      <div className="img-div2" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={srimg4} className="curriculum-image1" alt="Curriculum Content" />
                                      </div>
                                    )}
                                    {val?.resource_type === 1 && (
                                      <div className="images-organization" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={img1} className="student-image1" alt="Organization" />
                                      </div>
                                    )}
                                    {val?.resource_type === 5 && (
                                      <div className="img-div3" onClick={() =>
                                        openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                                      }>
                                        <img src={vector3} className="curriculum-image3" alt="Teacher Parent Support" />
                                      </div>
                                    )}
                                  </>
                                }
                              </>
                          }
                        </div>
                        <h3 className="curriculum-h5" onClick={() => {
                          !activeuser ? navigate("/sign-in") : openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                        }} style={{ cursor: "pointer" }}> {val.resource_title}</h3>
                        <p className="curriculum-paragraph description" onClick={() => {
                          !activeuser ? navigate("/sign-in") : openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}`)
                        }} style={{ cursor: "pointer" }}>{renderDescription(val.description)}</p>
                        <div className="eligible-public-funding"></div>
                        <div className="text-curriculum11">
                          {val.tbl_pillar_resource_mappings &&
                            val.tbl_pillar_resource_mappings.length > 0 &&
                            val.tbl_pillar_resource_mappings.map((item, index) => {
                              return (
                                <div>
                                  <img
                                    src={uimage + item.tbl_learning_pillar_master.image}
                                    alt="user"
                                  />
                                  <span
                                    className={` ${item.tbl_learning_pillar_master.name === "Heart"
                                      ? "heart"
                                      : item.tbl_learning_pillar_master.name === "Mind"
                                        ? "mind"
                                        : item.tbl_learning_pillar_master.name === "Strength"
                                          ? "strength"
                                          : item.tbl_learning_pillar_master.name === "Soul"
                                            ? "soul"
                                            : "soul"
                                      }`}
                                  >
                                    {item.tbl_learning_pillar_master.name}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <p className="handle-star">

                          {" "}
                          <span className="starDigitProfile">{val.rating > 0 ? Number(val.rating).toFixed(1) : "0.0"}</span>{" "}
                          {renderRatingStars(val?.rating)}{" "}
                          <span className="reviews"
                            onClick={() => {
                              !activeuser ? navigate("/sign-in") : openRouteInNewTab(`/full-description/${val.id}/${toSeoUrl(val?.resource_title)}?passKey=pass`)
                            }}><span> ({val?.total_reviews !== 0 ? val?.total_reviews : "0"})</span>{" "}See all reviews</span></p>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>)
}
export default Explore;