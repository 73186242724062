import React from "react";
import moment from "moment";
import { renderDescription, toSeoUrl } from "../../services/common.services";
import "./rating.css";
import { Learningpillar } from "../Common.WebAppMate/Learningpillar";

/**
 * create components is used for render the card on search page
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */


export const openRouteInNewTab = (routePath) => {
  const url = window.location.origin + routePath;
  window.open(url, "_blank");
};

export const renderRatingStars = (num) => {
  const maxStars = 5;
  const starPercentage = (num / maxStars) * 100;
  const starPercentageRounded = Math.round(starPercentage);
  
  const starStyles = {
    width: starPercentageRounded + "%"
  };
  
  return (
    <div className="stars-gray">
      <div className="stars-yellow" style={starStyles}></div>
    </div>
  );
};


const RenderCardInfo = ({ data }) => {
  const { objectID, resource_title, author_name, is_chat_gpt, description, define_address, start_date, end_date, public_funding, learning_pillars, rating, reviews, save } = data;

  const formattedStartDate = start_date ? moment(start_date).format("MM-DD-YYYY") : null;
  const formattedEndDate = end_date ? moment(end_date).format("MM-DD-YYYY") : null;

  return (
    <div className="">
      <h3 className="text_capital" onClick={() => openRouteInNewTab(`/full-description/${objectID}/${toSeoUrl(resource_title)}`)}>
        {resource_title || data.title}
      </h3>
      <p className="mb-1 publish-name">{author_name && is_chat_gpt === "yes" && <>Publisher: {author_name}</>}</p>
      <div className="searchdata-withaddressDate">
        <p onClick={() => openRouteInNewTab(`/full-description/${objectID}/${toSeoUrl(resource_title)}`)} className="description text_capital">
          {renderDescription(description)}
        </p>
        <p className="mb-1 descriptionaddress">{define_address && <><b>Address:</b> {define_address}</>}</p>
        <p className="mb-2">{start_date && <><b>Date: </b>{formattedStartDate}{end_date && <> to {formattedEndDate}</>}</>}</p>
        <div className="eligible-public-funding">
          {public_funding === "yes" && (
            <h5>
              <span><i className="fa fa-check-circle checkc"></i></span>
              Eligible for Public Funding
            </h5>
          )}
        </div>
      </div>
      <div className="text-curriculum11" onClick={() => openRouteInNewTab(`/full-description/${objectID}/${toSeoUrl(resource_title)}`)}>
        {Array.isArray(learning_pillars) && learning_pillars.map((item, i) => (Learningpillar(item, i)))}
      </div>
      <div onClick={() => openRouteInNewTab(`/full-description/${objectID}/${toSeoUrl(resource_title)}`)}>
        <span>{rating !== 0 ? Number(rating).toFixed(1) : "0.0"} </span>
        {renderRatingStars(rating)}
        <span>({reviews !== 0 ? reviews : "0"})</span>
        <span className="reviews" onClick={() => openRouteInNewTab(`/full-description/${objectID}${toSeoUrl(resource_title)}?passKey=pass`)}>
          See all reviews
        </span>
      </div>
      <div className="bookmark">
        <span>{save === "yes" ? <i className="fa fa-bookmark"></i> : <i className="fa fa-bookmark-o"></i>}</span>
      </div>
    </div>
  );
};

export default RenderCardInfo;
