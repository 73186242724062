import React, { useRef, useState } from "react";
import './ticketdetail.css';
import { useEffect } from "react";
import whiteimg from "../../assets/Images/whiteimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import { makePostRequest, makeFormDateRequset } from "../../services/api";
import { URLS } from "../../constants";
import moment from "moment";
import {
  toastifyMessage,
} from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Head from "../Layout.WebAppMate/head/Head";
/**
 * create components is used for tickedetail
 *  @author Naresh Kumar <naresh.gulati@webappmate.com>
 * created on: 06-11-2023
 * chnages by
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const Ticketdetailpage = () => {
  const ticket = useLocation();
  const naviget = useNavigate()
  let ticket_id = (ticket.state?.ticketid)
  const [ticketComment, setTicketComment] = useState();
  const [thumbnailImgName, setThumbnailImgName] = useState("");
  const [uploadArray, setUploadArray] = useState([]);
  const [error, setError] = useState({});
  const commentInputRef = useRef(null);
  const [addcomment, setAddcomment] = useState("");
  const [spin, setSpin] = useState(false)
  const chatContainerRef = useRef(null);

  useEffect(() => {
    document.body.classList.add("ticket-detail");
    return () => {
      document.body.classList.remove("ticket-detail");
    };
  }, []);

  //this function use for validation
  const validatefiled = () => {
    const errors = {};
    if (addcomment === "") {
      errors.comment = "Please enter comment.";
    }
    if (addcomment === "") {
      commentInputRef.current.focus();
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  // this function use for add comments
  const handleAddTicketsComment = () => {
    setSpin(true)
    if (validatefiled()) {
      let data = {
        "ticket_id": ticket_id,
        "comment": addcomment,
        "uploads": (uploadArray.length > 0 && uploadArray) || ""
      }
      makePostRequest(URLS.addTicketComment, null, data, null).then((res) => {
        if (res.code === 200) {
          setSpin(false)
          toastifyMessage(res?.data?.message, "success");
          setAddcomment("");
          getTicketComment();
          setThumbnailImgName([])
        }
        else {
          toastifyMessage("Something went wrong", "error");
        }
      });
    }
    scrollToBottom();
  }
  // this function is use for doc upload
  const handleAddAttachment = (file) => {
    setSpin(true)
    let data = new FormData();
    data.append("filename", file);
    makeFormDateRequset(URLS.addTicketAttachments, data).then(
      (res) => {
        if (res.code === 200) {
          toastifyMessage(res?.data?.message, "success");
          setSpin(false)
          // showAttachment(res.data.data.upload.attachment?.id)
          setUploadArray([...uploadArray, res.data.data.upload.token]);
          setThumbnailImgName([...thumbnailImgName, res.data.data.upload])
        }
        else {
          toastifyMessage("Something went wrong", "error");
        }
      }
    );
  }
  useEffect(() => {
    getTicketComment();
    // eslint-disable-next-line
  }, []);
  // this function use for get ticket
  const getTicketComment = () => {
    setSpin(true)
    let data = {
      "ticket_id": ticket_id
    }
    makePostRequest(URLS.getTicketComment, null, data, null).then((res) => {
      console.log("Comment details", res)
      setSpin(false)
      setTicketComment(res.data.data);
    });
  }
  // this function use for hanle doc
  const handleThumbnail = (e) => {
    const validExtensions = ["png", "jpg", "jpeg"];
    let file = e?.target?.files[0];
    const fileExtension = file?.type?.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      toastifyMessage("Only png, jpg, jpeg are allowed", "error");
      return;
    }
    const MAX_FILE_SIZE = 2048
    const fileSizeKiloBytes = file.size / 1024
    if (fileSizeKiloBytes <= MAX_FILE_SIZE) {
      handleAddAttachment(file);
    }
    else {
      toastifyMessage("Only 500 KB file size is allowed", "error");
      setThumbnailImgName("");
    }
  };
  // this function use for doc delete
  const deleteAttachment = (id) => {
    setSpin(true)
    makePostRequest(URLS.deleteAttachment, null, { "token": id }, null)
      .then((res) => {
        setSpin(false)
        toastifyMessage(res?.data?.message, "success");
        setThumbnailImgName(thumbnailImgName.filter(obj => obj.token !== id))
        setUploadArray(uploadArray.filter(obj => obj !== id))
      }).catch((error) => {
        console.log("error::", error)
      })
  }
  // this function is use for scrolll bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [ticketComment])

  return (<>
    <div className="col-lg-10 col-md-12 col-sm-12">
      <div className="container">
        {
          spin && <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        }
        <Head title="Ticket Detail"
          content="Zendesk Ticket"
          name="Zendesk Ticket" ></Head>
        <div className="col-12">
          <div className="ticket-detail-page pt-3">
            <button className="deshbord my-0 pt-0 pb-3">
              <span onClick={() => naviget(-1)}>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
                {" "}  Go Back
              </span>
            </button>
            <div className="ticket-detail-page-content ">
              <h3> Ticket Detail View </h3>
            </div>
            <div className="ticket-detail-inner-page">
              <div className="ticket-detail-inner-pageScroll" ref={chatContainerRef}>
                {ticketComment?.map((item, index) => {
                  return <div className="ticket-detail-inner-page-content">
                    {
                      item?.public && <div className="row">
                        <div className="col-3 col-sm-2 col-md-2 col-lg-1">
                          <div className="ticket-detail-image">
                            <img src={whiteimg} className="img-fluid" alt="user" />
                          </div>
                        </div>
                        <div className="col-9 col-sm-10 col-md-10 col-lg-8">
                          <div className="ticket-detail-text">
                            <h4>{item?.name != null ? item?.name : "--"}</h4>
                            <h5>{item?.body}</h5>
                            {item.attachments?.map((it, index) => {
                              return (
                                <img src={it.content_url} alt="ticket" width="50px" height="50px" onClick={() => window.open(it.content_url, '_blank')}
                                  style={{ cursor: 'pointer' }} key={index} />
                              )
                            })}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="ticket-detail-date">
                            <h6>{moment(item?.created_at).format("MM-DD-YYYY hh:mm:ss a")}</h6>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                })}
              </div>
              <div className="row">
                <div className="col-lg-1">
                </div>
                <div className="col-lg-10">
                  <div className="ticket-detail-textarea">
                    <textarea value={addcomment} onChange={(e) => setAddcomment(e.target.value)} placeholder="Comments here...." ref={commentInputRef}></textarea>
                    <span className="error mt-3">{error?.comment && error?.comment}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-1">
                </div>
                <div className="col-lg-10">
                  <div className="upload-image-ticket">
                    <div className="ticket-thumbuploadImgz">
                      <section className="ticket-upload-scroll">
                        <span className="ticket-u-content">
                          {" "}
                          <i className="fa fa-cloud-upload uploadicon"></i>
                          <span>
                            {"Upload Image"}
                          </span>
                        </span>
                        <input
                          type="file"
                          placeholder=""
                          multiple
                          className="input-file-upload"
                          onChange={handleThumbnail}
                        />
                      </section>
                    </div>
                    <div className="ticket-detail-scroll-image">
                      <span className="error ">{error?.upload && error?.upload}</span>
                      {
                        thumbnailImgName && thumbnailImgName.map((val, index) => {
                          return <>
                            <div className="duplod1333">
                              <div className="duplod1 p-1">
                                <h4 className="dp float-start">
                                  <span className="one">{index + 1}</span>
                                  {val?.attachments[0]?.content_url?.split("/")?.pop()?.replace(/\?/g, '')}
                                </h4>
                                <div className="float-end">
                                  <button
                                    className="btn btn-md px-0 border-0"
                                    onClick={() => {
                                      deleteAttachment(val?.token);
                                    }}
                                  >
                                    <span className="ed">
                                      Remove
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        })
                      }
                    </div>
                  </div>
                  <div className="ticket-submit-button">
                    <button onClick={handleAddTicketsComment}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
export default Ticketdetailpage;